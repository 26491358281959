import * as React from "react";
import styles from "./Link.module.scss";

const Link = (props: {
  children: React.ReactElement | string;
  className?: string;
  styleName?: string;
  onClick: () => void;
}) => {
  return (
    <div className={props.className || (props.styleName && styles[props.styleName]) || styles.link} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Link;
