import * as React from "react";
import styles from "./NumberUnitField.module.scss";
import Container from "../../molecules/Container";
import NumberField from "../../atoms/NumberField";

export default (props: {
  value?: string | number | null;
  placeholder?: string;
  autoFocus: boolean;
  onChange: (v: number | null) => void;
  unit: string;
  step?: number;
  onFocusChange?: (focused: boolean) => void;
}) => {
  const [value, setValue] = React.useState(props.value != null ? String(props.value) : "");
  const [error, setError] = React.useState("");
  const step = props.step || 1;
  return (
      <>
      <div className={styles.number}>
        <div className={styles.numberField}>
          <NumberField
            autoFocus={props.autoFocus}
            value={value}
            step={step}
            onChange={(v) => {
              setValue(v);
              if (v.length > 0) {
                const n = parseFloat(v);
                if (isNaN(n)) {
                  setError("数字を入力してください");
                  props.onChange(null);
                } else {
                  // 浮動小数点の誤差を丸める
                  const fixed = parseFloat(n.toFixed(-Math.log10(step)));
                  setError("");
                  props.onChange(fixed);
                }
              } else {
                props.onChange(null);
              }
            }}
            onFocusChange={props.onFocusChange}
            placeholder={props.placeholder}
          />
        </div>
        <div className={styles.unit}>{props.unit}</div>
      </div>
      { error &&
        (
          <div className={styles.error}>
            {error}
          </div>
        )
      }
      </>
  );
};
