import * as React from "react";
import { Context, changePage, finishedQuestions, setAttributes } from "../../Store";
import styles from "./Start.module.scss";
import Container from "../../molecules/Container";
import robotIcon from "../questions/robot.svg";
import HalfDivideArea from "../../molecules/HalfDivideArea";
import { finishPath, questionIndexPath } from "../../path";
import Button from "../../atoms/Button";
import { StudentQuestions } from "../questions";

export default (props: { mode: "default" | "student" }) => {
  const { dispatch, state } = React.useContext(Context);
  if (finishedQuestions(state.blueprint)) {
    window.history.pushState(true, "", finishPath);
    dispatch(changePage(finishPath));
    return (<></>);
  } else {
    return (
      <>
        <Container height={150}>
          <div />
        </Container>
        <Container>
          <img src={robotIcon} />
        </Container>
        { props.mode === "default" &&
        <><Container width="90%">
            <p>
            初めまして、あなた専用の家計改善ロボです。<br />
              一緒に改善ポイントを探していきましょう。
            </p>
          </Container><Container width="90%">
              <p>
                まずは、今のあなたの状況を教えてください。
              </p>
            </Container>
            <Container height={200}>
              <HalfDivideArea>
                <span />
                <Button onClick={() => {
                  dispatch(setAttributes({ isStudentMode: false }));
                  window.history.pushState(true, "", questionIndexPath);
                  dispatch(changePage(questionIndexPath, false));
                } }>次へ</Button>
              </HalfDivideArea>
            </Container></>
        }
        { props.mode === "student" &&
        <><Container width="90%">
            <p>
          初めまして、あなた専用の家計改善ロボです。<br />
          これから社会人になる皆さんの「お金の準備」を全力でサポートします。
            </p>
          </Container><Container width="90%">
            <p>中断しても入力した内容は自動的に保存するので、ご安心ください。</p>
          </Container><Container width="90%">
              <p>
                まずは、今のあなたの状況を教えてください。
              </p>
            </Container>
            <Container height={200}>
              <HalfDivideArea>
                <span />
                <Button onClick={() => {
                  const pages = StudentQuestions.createPages(state);
                  const path = pages[0].path;
                  window.history.pushState(true, "", path);
                  dispatch(setAttributes({
                    isStudentMode: true,
                    houseOwnership: "rental",
                    monthlyLivingCost: state.blueprint.defaultLivingCost.value,
                    livingCostIncreaseRateText: "1.5",
                    annualBasicExpense: 500000,
                    own: {
                      savings: 200000,
                    }
                  }));
                  dispatch(changePage(path, false));
                } }>次へ</Button>
              </HalfDivideArea>
            </Container></>
        }
        </>
    );
  }
};
