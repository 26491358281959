import * as React from "react";
import styles from "./Title.module.scss";

const Title = (props: { message: string }) => {
  return (
    <div className={styles.title}>
      <div>{props.message}</div>
    </div>
  );
};

export default Title;
