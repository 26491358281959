import * as React from "react";
import _, { isUndefined } from "lodash";
import { reducer as functionsReducer, fetchUserSuccess, UserDetail, EstimatedExpenseType } from './functions';
import { reducer as serviceReducer, saveState, fetchStateSuccess, fetchUserDetailSuccess } from './service';
import { getAge, getAgeInYearEnd, isSameMonth, yearsSince } from "../simulator";
import { AUTH_DISABLED, PRODUCTION } from "../firebase";
import deepFreezeStrict from "deep-freeze-strict";
import debug from "../debug";
import { averageExpense } from "./genres";
import { Memoize } from "typescript-memoize";
import { getJobSalaryTable } from "./jobs";

export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const RESET_BLUEPRINT = "RESET_BLUEPRINT";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const STOP_ACTION = "STOP_ACTION";
export const SET_IS_SWIPE = "SET_IS_SWIPE";
export const SET_SWIPE_TIMER = "SET_SWIPE_TIMER";
export const FETCH_BLUEPRINT = "FETCH_BLUEPRINT";
export const FETCH_BLUEPRINT_SUCCESS = "FETCH_BLUEPRINT_SUCCESS";
export const FETCH_BLUEPRINT_FAILURE = "FETCH_BLUEPRINT_FAILURE";
export const SET_REQUIRED_REVIEWS = "SET_REQUIRED_REVIEWS";
export const SET_REVIEWS = "SET_REVIEWS";
export const ACQUIRE_BADGE = "ACQUIRE_BADGE";

export { savePlan } from './database';
export { fetchState, saveState } from "./service";

const BETWEEN_CHILD_YEAR = 2;

export type money = number;
export type age = number;

let _currentDate: Date | null = null;

export const sessionStartDate = () => {
  if (!_currentDate) {
    if (!PRODUCTION) {
      const params = (new URL(window.document.location.toString())).searchParams;
      if (params.get("date")) {
        _currentDate = new Date(params.get("date") as string);
      }
      console.log("date", _currentDate);
    }
    if (!_currentDate) {
      _currentDate = new Date();
    }
  }
  return _currentDate;
}

export const isPremiumUser = (state: AppState) => {
  if (AUTH_DISABLED) {
    return true;
  } else {
    return state.user && state.user.charge && state.user.charge.length > 0;
  }
}

const monthsSince = (a: Date, b: Date) => {
  return (b.getFullYear() * 12 + b.getMonth()) - (a.getFullYear() * 12 + a.getMonth());
}

export type Profession = keyof typeof ProfessionNames;

export const ProfessionNames = {
  regular_employee: "正社員",
  contract_worker: "契約社員・派遣社員",
  public_employee: "公務員",
  self_employed: "自営業",
  //    farmer: "農林漁業",
  part_time_job: "アルバイト・パート",
  housemaker: "主婦・主夫",
  student: "学生",
  unemployed: "雇用なし"
}

export const professionHasSalary = (p: Profession | undefined) => !_.includes(["housemaker", "unemployed", "student"], p);

export const hasWork = (job: Job) => _.includes(["regular_employee", "contract_worker", "public_employee", "self_employed", "farmer", "part_time_job"], job.profession);

export class Properties implements Job {
  constructor(props?: { [P in keyof Properties]?: any }) {
    if (props) {
      Object.assign(this, _.cloneDeep(props));
    }
    this.lifespan = _.clamp(this.lifespan, 60, 120);
    this.pensionBenefitAge = _.clamp(this.pensionBenefitAge, 60, 70);
  }

  gender?: keyof typeof Gender;
  birthday: Date = new Date(sessionStartDate().getFullYear() - 22, 1, 1);
  startWorkingAge?: age;
  profession?: Profession;
  monthlySalary?: number;
  useJobName?: boolean;
  jobCategory?: string;
  jobName?: string;
  bonus?: number;
  salaryIncreaseRate: number = 1;
  get salaryHardIncreaseRatio() { return 1 + this.salaryIncreaseRate * 0.01; }
  get salarySoftIncreaseRatio() { return 1 + this.salaryIncreaseRate * 0.01 * 0.25; }
  savings?: number;
  workAfterBirth: keyof typeof WorkAfterBirthNames = "soft"; // 出産後の働き方
  pensionBenefitAge: number = 65;
  retirementAge: number = 65;
  reemploymentAge: number | null = 60;
  lifespan: age = 90;
  hasCorporatePension?: boolean;
  corporatePensionStartAge?: number;
  corporatePensionEndAge?: number;
  corporatePensionBenefit?: number;
  hasSeverancePay?: boolean;
  severancePayAge?: number;
  severancePay?: number;
  maternityLeaveMonths?: number; // 産休期間
  childcareLeaveMonths?: number; // 育休日数
  reduceWorkAfterBirth?: boolean;
  reducedWorkYears?: number | null = 1;
  reducedWorkSalaryPercent?: number | null = 75;
  customMonthlyPension?: number;
  basicPensionNumber?: string;

  hasStudentLoanRepayment?: boolean;
  studentLoanMonthlyAmount?: money;
  studentLoanReceivingMonths?: number;
  studentLoanRepayment?: money;
  studentLoanFullPayment?: Date;

  wishJobChange?: "yes" | "no" | "unknown";
  jobChanges: JobChange[] = [];

  sideJobs: SideJob[] = [];

  periodicInvestments: PeriodicInvestment[] = [];
  ideco: PeriodicInvestment[] = [];
  nisa: PeriodicInvestment[] = [];
  tsumitateNisa: PeriodicInvestment[] = [];
  dc: PeriodicInvestment[] = [];
  investments: Investment[] = [];
  inheritance: Inheritance[] = [];
};

export const WorkAfterBirthNames = {
  hard: "フルタイムで仕事を継続",
  soft: "働き続けるが仕事をセーブ",
  retire: "退職し子育てに専念"
};



export const Gender = {
  male: "男性",
  female: "女性",
  other: "その他"
};

export type PeriodicInvestment = {
  started: boolean;
  startAge?: age;
  endAge?: age;
  sellAge?: age;
  monthlyInvestment?: money;
  yield?: number;
}

export const InitialPeriodicInvestment: Partial<PeriodicInvestment> = {
  endAge: 60,
  sellAge: 60,
  yield: 3,
}

export type Investment = {
  purchased: boolean;
  purchaseAge?: age;
  sellAge?: age;
  purchasePrice?: money;
  yield?: number;
}

export const InitialInvestment: Partial<Investment> = {
  sellAge: 60,
  yield: 3,
}

export interface Job {
  profession?: Profession;
  monthlySalary?: money;
  bonus?: money;
  useJobName?: boolean;
  jobCategory?: string;
  jobName?: string;
}

export interface JobChange extends Job {
  age?: number;
}

export type SideJob = {
  started: boolean;
  startAge?: number;
  endAge?: number;
  monthlyIncome?: money;
}

export type Child = {
  birthday: Date;
};

export type Housemate = {
  name?: string;
  fromYear?: number;
  toYear?: number;
}

export type User = {
  id: number;
  name?: string;
  gender_id?: number;
  birth_date?: string;
  house_type?: string;
  charge?: string;
}

export type ExaminationTypes = {
  es: keyof typeof ExaminationTypeNames;
  jhs: keyof typeof ExaminationTypeNames;
  hs: keyof typeof ExaminationTypeNames;
  university: keyof typeof ExaminationTypeNames;
}

export const ExaminationTypeNames = {
  yes: "受験する予定",
  no: "受験しない予定",
  unknown: "分からない",
}

export type LessonType = keyof typeof LessonTypeNames;

export const LessonTypeNames = {
  average: "他の世帯と同等にお金をかける",
  more: "他の世帯より多めにかける",
  less: "他の世帯より少なめにする"
}

const InitialExaminationTypes: ExaminationTypes = {
  es: "no",
  jhs: "no",
  hs: "yes",
  university: "yes",
} as const;


export type SchoolTypes = {
  preschool: keyof typeof PreschoolTypeNames;
  es: keyof typeof SchoolTypeNames;
  jhs: keyof typeof SchoolTypeNames;
  hs: keyof typeof HighschoolTypeNames;
  university: keyof typeof UniversityTypeNames;
  graduateSchool: keyof typeof GraduateSchoolTypeNames;
}

const InitialSchoolTypes: SchoolTypes = {
  preschool: "nursery",
  es: "public",
  jhs: "public",
  hs: "public",
  university: "public",
  graduateSchool: "employment",
} as const;

export const PreschoolTypeNames = {
  nursery: "保育園",
  public: "公立幼稚園",
  private: "私立幼稚園",
}

export const SchoolTypeNames = {
  public: "公立",
  private: "私立"
}

export const HighschoolTypeNames = {
  public: "公立",
  private: "私立",
  kosen: "高専",
  employment: "就職",
}

export const UniversityTypeNames = {
  public: "公立",
  private_humanities: "私立文系",
  private_science: "私立理系",
  tandai: "短大",
  employment: "就職",
}

export const GraduateSchoolTypeNames = {
  public: "国立",
  private_humanities: "私立文系",
  private_science: "私立理系",
  overseas: "海外",
  employment: "就職",
}

export interface Insurance {
  name?: string;
  insured?: string;
  company?: string;
  monthlyPayment?: money;
  startAge?: age;
  endAge?: age;
  maturityRepaymentAge?: age;
  maturityRepayment?: money;
  pensionRepaymentStartAge?: age;
  pensionRepaymentEndAge?: age;
  monthlyPensionRepayment?: money;
}

export type ChildcarePolicy = {
  birthCost: number; // 出産費用
  maternityGift?: money; // 出産祝い

  liveAloneAge?: number; // 一人暮らしを始める年齢
  schoolTypes: SchoolTypes;
  examinationTypes: ExaminationTypes;
  lessonType: LessonType;
  sendRemittance?: boolean;
  remittanceYears: number;
  monthlyRemittance: money;

  adultCeremonyCost: money;

  // 学資保険
  studentInsurance?: Insurance;

  useScholarship?: boolean;
  scholarshipMonthlyAllowance: number;
}

export const InitialChildcarePolicy: ChildcarePolicy = {
  birthCost: 510000,
  schoolTypes: InitialSchoolTypes,
  examinationTypes: InitialExaminationTypes,
  maternityGift: 230000,
  scholarshipMonthlyAllowance: 45000,
  remittanceYears: 4,
  monthlyRemittance: 70000,
  adultCeremonyCost: 200000,
  lessonType: "average"
};

export const InitialStudentInsurance: Insurance = {
  monthlyPayment: 15000,
  startAge: 0,
  endAge: 18,
}

// 相続
export type Inheritance = {
  age?: number;
  amount?: number;
}

// 単発の収入・支出
export type SpecialIncomeOrExpense = {
  name?: string;
  age?: age;
  amount?: money;
}

// 毎月の収入・支出
export type MonthlyIncomeOrExpense = {
  name?: string;
  startAge?: age;
  endAge?: age;
  amount?: money;
}

// 繰り返しの臨時収入・支出
export type RepeatedIncomeOrExpense = {
  name?: string;
  startAge?: age;
  endAge?: age;
  spanYears?: number;
  amount?: money;
}

export type Car = {
  purchaseAge?: age;
  price?: money;
  useLoan?: boolean;
  loanRepayment?: money;
  loanFullPayment?: Date;
  tax: money;
  inspectionFee: money;
  nextInspectionYear?: number;
  insuranceRepayment: money;
  newOrUsed?: keyof typeof NewOrUsedCarNames;
  sellPlan: keyof typeof CarSellPlanNames;
  sellAge?: age;
  sellPrice?: money;
}

export const defaultCarSellPrice = (car: Car) => {
  if (car.price && car.purchaseAge && car.sellAge &&
    car.purchaseAge <= car.sellAge) {
    return car.price * Math.max(0, 1 - (car.sellAge - car.purchaseAge) / 10)
  } else {
    return null;
  }
}

export const InitialCar = {
  tax: 40000,
  inspectionFee: 100000,
  insuranceRepayment: 84000,
  sellPlan: "periodic" as const,
}

export const CarSellPlanNames = {
  sell: "あり",
  none: "なし",
  periodic: "定期的に同様の車種に買い替える",
}

export const NewOrUsedCarNames = {
  new: "新車",
  used: "中古車",
}
export type Debt = {
  monthlyPayment?: money;
  balance?: money;
  interest?: number;
  asOf?: Date;
}

export type House = {
  newOrUsed?: keyof typeof NewOrUsedHouseNames;
  houseType?: keyof typeof HouseTypeNames;
  monthlyManagementCost: money;
  price?: money;
  downPayment?: money; // 頭金
  propertyTax?: money;
  reformCost?: money;
}

export const DEFAULT_HOUSE_REFORM_COST = 1500000;

export type MovingPlan = {
  auto: boolean;
  age?: age;
  movingCost?: money;
  rent?: money;
  rentRenewalFee?: money;
}

export type HousePurchasePlan = House & {
  age?: age;
  movingCost: money;
  initialCost?: money;
}

export const InitialHouse: House = {
  monthlyManagementCost: 10000,
}

export const InitialHousePurchasePlan: HousePurchasePlan = {
  movingCost: 300000,
  monthlyManagementCost: 10000,
  price: 40000000,
  propertyTax: 100000
}

const DefaultQuestionCategoryNames = ["family", "money", "home", "car", "other"] as const;
const QuestionCategoryNames = [...DefaultQuestionCategoryNames, "student"] as const;
export type QuestionCategoryName = typeof QuestionCategoryNames[number];

const LIVING_COST_THRESHOLD = 100000;

export class Blueprint {
  constructor(props?: { [P in keyof Blueprint]?: any }) {
    if (props) {
      props = _.cloneDeep(props);
      if (props.own) props.own = new Properties(props.own);
      if (props.partner) props.partner = new Properties(props.partner);
      Object.assign(this, props);
    }
    if (props?.estimate) {
      const estimate = props!.estimate as UserDetail;
      if (estimate.savings && this.own.savings == null) {
        this.own.savings = Math.round(estimate.savings / 1000) * 1000;
      }
    }
    this.debt.forEach(d => {
      if (d.asOf && d.balance != null && d.monthlyPayment != null) {
        const months = monthsSince(d.asOf, this.currentDate());
        if (months > 0) {
          d.asOf = this.currentDate();
          d.balance -= d.monthlyPayment * months;
          if (d.balance < 0) d.balance = 0;
        }
      }
    });
  }

  currentDate() {
    if (this.isStudentMode && this.own.startWorkingAge) {
      const currentYear = sessionStartDate().getFullYear();
      const years = this.own.startWorkingAge - yearsSince(this.own.birthday, new Date(currentYear, 3, 2));
      const date = new Date(currentYear + years, 3, 2);
      // 年齢が startWorkingAge で 4/1 になる日付をシミュレーションの開始日とする
      if (date < sessionStartDate()) {
        return sessionStartDate();
      } else {
        return date;
      }
    } else {
      return sessionStartDate();
    }
  }

  validTypesForExpense(type: "living" | "annual") {
    return  _.compact([
      type,
      type === "living" && this.hasOrWishChild && "child",
      type === "living" && (!this.hasPartner && this.own.gender === "female" || this.hasPartner) && "women",
      type === "living" && (this.hasCar || this.carPurchasePlans.length > 0) && "car",
    ]) as EstimatedExpenseType[];
  }

  estimateExpense(type: "living" | "annual") {
    if (this.estimate.expense) {
      const validTypes = this.validTypesForExpense(type);
      const total = this.estimate.expense
        .filter(genre => validTypes.includes(genre.expenseType))
        .reduce(((sum, genre) => sum + genre.amount), 0);
      return {
        value: Math.round(total * (type === "annual" ? 12 : 1) / 1000) * 1000,
        isAverage: false,
        fromExpense: this.estimate.expense,
        filter: validTypes };
    } else {
      return null;
    }
  }

  @Memoize()
  get defaultAnnualBasicExpense() {
    const living = this.estimateExpense("living");
    if (living != null && living.value >= LIVING_COST_THRESHOLD) {
      return this.estimateExpense("annual")!;
    } else {
      return averageExpense("annual",
        this.hasChild ? "child" :
        this.wishPartner === "married" ? "couple" :
        "single",
        this.validTypesForExpense("annual"));
    }
  }

  @Memoize()
  get defaultLivingCost() {
    const expense = this.estimateExpense("living");
    if (expense != null && expense.value >= LIVING_COST_THRESHOLD) {
      return expense;
    } else {
      return averageExpense("living",
        this.hasChild ? "child" :
        this.wishPartner === "married" ? "couple" :
        "single",
        this.validTypesForExpense("living"));
    }
  }

  //id: number;
  own: Properties = new Properties();
  wishPartner?: keyof typeof WishPartnerNames;
  partner: Properties | null = null;
  get hasPartner() {
    return _.includes(["married", "yes"], this.wishPartner);
  }

  // 子供
  hasChild?: boolean;
  childNum?: number;
  wishChild?: keyof typeof WishChildNames;
  get doesWishChild() { return this.wishChild === "yes"; }
  get hasOrWishChild() { return this.hasChild || this.doesWishChild }
  wishChildNum?: number;
  currentChildren: Child[] = [];
  desiredChildren: Child[] = [];

  // 同居人
  hasHousemate?: boolean;
  housemates: Housemate[] = [];

  // 生活費
  monthlyLivingCost?: number;
  livingCostIncreaseRateText: string = "0.5";
  get livingCostIncreaseRatio() { return 1 + (parseFloat(this.livingCostIncreaseRateText) / 100) }

  // 大型出費
  annualBasicExpense?: number;

  // 住宅
  houseOwnership?: keyof typeof HouseOwnershipNames;
  rent?: money; // 家賃
  rentRenewalFee: money = 100000; // 更新料デフォルト 10 万にする
  parkingRent?: money;
  nextRentRenewalYear?: number; // 次回の更新時期
  independentHousePlan?: keyof typeof IndependentHousePlanNames;

  // 住宅購入
  postCode?: string;
  planningToBuyHouse?: "yes" | "bought" | "no" | "unknown";
  housePurchasePlan?: HousePurchasePlan;
  currentHouse?: House;
  housingLoanRepayment?: money;
  housingLoanFullPayment?: Date;
  housingLoanAdvanceRepayment: boolean = false;
  mortgageDeductionEndDate?: Date;
  mortgageDeductionAmount: money = 400000;
  movingPlans: MovingPlan[] = [];

  // 車
  hasCar?: boolean;
  numCars?: number;

  planningToBuyCar?: keyof typeof PlanningToBuyCarNames;
  cars: Car[] = [];
  carPurchasePlans: Car[] = [];
  maxDrivingAge: number = 75;

  // その他
  hasDebt?: boolean;
  debt: Debt[] = [];
  hasInsurance?: keyof typeof HasInsuranceNames;
  insurances: Insurance[] = [];
  hasSavingsTypeInsurance?: "yes" | "planned" | "no";
  savingsTypeInsurance: Insurance[] = [];

  childcarePolicy: ChildcarePolicy = InitialChildcarePolicy;
  completed: QuestionCategoryName[] = [];
  marriageAge?: number;
  marriageRingCost: number = 200000;
  weddingCost: number = 1637000;
  honeymoonCost: number = 500000;
  //initialAge?: number;
  planningToSellHouse?: boolean;
  sellHouseAge?: number;
  sellHousePrice?: number;
  funeralCost: number = 2500000;

  specialIncome: SpecialIncomeOrExpense[] = [];
  monthlyIncome: MonthlyIncomeOrExpense[] = [];
  repeatedIncome: RepeatedIncomeOrExpense[] = [];
  specialExpense: SpecialIncomeOrExpense[] = [];
  monthlyExpense: MonthlyIncomeOrExpense[] = [];
  repeatedExpense: RepeatedIncomeOrExpense[] = [];

  createdDate: Date = sessionStartDate();
  inflationRateText: string = "0.5";
  get inflationRatio() {
    return 1 + parseFloat(this.inflationRateText) * 0.01;
  }

  reviewedDate?: Date;
  requiredReviews: number[] = [];
  reviews: number[] = [];
  badges: Partial<Record<BadgeName, { count: number, lastDate?: Date }>> = { first: { count: 1 } };

  estimate: Partial<UserDetail> & {
    updatedDate?: Date,
  } = {};

  // 学生版
  isStudentMode: boolean = false;

  get independentHouseAge(): number | null {
    if (this.independentHousePlan && this.houseOwnership === "parent") {
      switch (this.independentHousePlan) {
        case "marriage":
          return (this.wishPartner === "yes" && this.marriageAge) || null;
        case "moving":
          return this.movingPlans[0]?.age || null;
        case "purchase":
          return (this.housePurchasePlan && this.housePurchasePlan.age) || null;
        case "none":
          return null;
      }
    } else {
      return null;
    }
  }
};

export type BlueprintAttributes = Omit<Partial<Blueprint>, "own" | "partner"> & {
  own?: Partial<Properties>,
  partner?: Partial<Properties>
};

export const finishedQuestions = (blueprint: Blueprint) => {
  return blueprint.isStudentMode ?
    blueprint.completed.includes("student") :
    DefaultQuestionCategoryNames.every(cat => blueprint.completed.includes(cat));
}

export type BadgeName = "first" | "surplus" |
  "work" | "asset" | "retirement" | "otherIncome" |
  "house" | "car" | "child" | "otherExpense" | "living" |
  "monthly" | "monthly10" | "monthly100";

export const WishPartnerNames = {
  married: "すでに結婚している",
  yes: "結婚したい・する予定がある",
  no: "結婚するつもりはない",
  unknown: "分からない",
};

export const WishChildNames = {
  yes: "はい",
  no: "いいえ",
  unknown: "分からない",
}

export const HouseOwnershipNames = {
  owned: "持ち家",
  rental: "賃貸",
  parent: "実家"
}

export const IndependentHousePlanNames = {
  marriage: "結婚と同時",
  moving: "次回の引っ越しと同時",
  purchase: "住宅購入と同時",
  none: "今のところ独立する予定はない"
}

export const PlanningToBuyCarNames = {
  yes: "あり",
  no: "なし",
  unknown: "決まっていない",
}

export const PlanningToBuyHouseNames: Record<NonNullable<Exclude<Blueprint["planningToBuyHouse"], "bought">>, string> = {
  yes: "いつか購入したい",
  no: "購入しないつもり",
  unknown: "分からない",
}

export const NewOrUsedHouseNames = {
  new: "新築",
  used: "中古",
}

export const HouseTypeNames = {
  detached: "一戸建て",
  apartment: "マンション",
}

export const HasInsuranceNames = {
  yes: "加入あり",
  planned: "加入なしだが検討中",
  no: "加入なし"
}

type RequiredArray<T> = T extends any[] ? [Required<T[0]>] : Required<T>;
export type FilteredKeys<T, U> = NonNullable<{ [P in keyof T]: NonNullable<RequiredArray<T[P]>> extends RequiredArray<U> ? P : never }[keyof T]>;
type BlueprintNestedKeyPath<T> = [FilteredKeys<Blueprint, T>, keyof T];
type BlueprintNestedKeyPath2<T0, T1> = [FilteredKeys<Blueprint, T0>, FilteredKeys<T0, T1>, keyof T1];
type BlueprintNestedIndexPath<T> = [FilteredKeys<Blueprint, T[]>, number, (keyof T)?];
type BlueprintNestedKeyIndexPath<T0, T1> = [FilteredKeys<Blueprint, T0>, FilteredKeys<T0, T1[]>, number, (keyof T1)?];

export type BlueprintKeyPath = [keyof Blueprint]
  | BlueprintNestedIndexPath<Child>
  | BlueprintNestedIndexPath<Housemate>
  | BlueprintNestedIndexPath<Car>
  | BlueprintNestedIndexPath<Debt>
  | BlueprintNestedIndexPath<Insurance>
  | BlueprintNestedIndexPath<SpecialIncomeOrExpense>
  | BlueprintNestedIndexPath<MonthlyIncomeOrExpense>
  | BlueprintNestedIndexPath<RepeatedIncomeOrExpense>
  | BlueprintNestedIndexPath<MovingPlan>
  | BlueprintNestedKeyPath<ChildcarePolicy>
  | BlueprintNestedKeyPath<House>
  | BlueprintNestedKeyPath<HousePurchasePlan>
  | BlueprintNestedKeyPath2<ChildcarePolicy, Insurance>
  | BlueprintNestedKeyPath2<ChildcarePolicy, SchoolTypes>
  | BlueprintNestedKeyPath2<ChildcarePolicy, ExaminationTypes>
  | BlueprintNestedKeyPath2<ChildcarePolicy, LessonType>
  | BlueprintNestedKeyPath<Properties>
  | BlueprintNestedKeyIndexPath<Properties, JobChange>
  | BlueprintNestedKeyIndexPath<Properties, SideJob>
  | BlueprintNestedKeyIndexPath<Properties, Investment>
  | BlueprintNestedKeyIndexPath<Properties, PeriodicInvestment>
  | BlueprintNestedKeyIndexPath<Properties, Inheritance>
  ;

export type AppState = {
  user: User;
  blueprint: Blueprint;
  savedBlueprint: boolean;
  beforePage: string;
  currentPage: string;
  isSwipe: boolean;
  swipeTimer: number;
  nextPage: string;
  pages: string[];
  inAction: boolean;
};

export const InitialAppState: AppState = {
  blueprint: new Blueprint(),
  savedBlueprint: false,
  user: {
    id: 0
  },
  isSwipe: false,
  swipeTimer: 0,
  inAction: false,
  beforePage: "",
  currentPage: "/",
  nextPage: "",
  pages: ["/"],
};

//const stateStored = sessionStorage.getItem("lifetime-state");
export const appState = InitialAppState;

export const getValueFromPath = <T>(blueprint: Blueprint, path: BlueprintKeyPath): T | null => {
  let obj = blueprint as any;
  for (const k of path) {
    obj = obj[k as string | number];
    if (obj == null) {
      //console.log(`object at key ${k} is null: ${JSON.stringify(path)}`)
      break;
    }
  }
  return obj as unknown as T | null;
}

export const generateAttributesFromPath = (blueprint: Blueprint, path: BlueprintKeyPath, value: any): any => {
  let attr = value as any;
  let obj = blueprint as any;
  let n = path.findIndex((k: any) => typeof k === "number");
  // ["children", 1, "birthday"] のような場合は n = 1 となる。
  // key[n + 1] までプロパティ名が入っている場合のみ対応する。
  if (n >= 0) {
    for (let i = 0; i < n; i++) {
      obj = obj[path[i] as string];
    }
    const array = [...obj as Array<any>];
    const index = path[n] as number;
    const object = array[index] || {};
    let newObject = {} as any;
    if (n + 1 < path.length) {
      // path[n + 1] にプロパティ名が入っている
      const key = path[n + 1] as string;
      Object.assign(newObject, object, { [key]: value });
    } else {
      // 配列の中のオブジェクトを更新する
      if (value === null) { // index にあるオブジェクトを削除する
        newObject = null;
      } else {
        Object.assign(newObject, value);
      }
    }
    array[index] = newObject;
    attr = array;
    for (const k of path.slice(0, n).reverse()) {
      attr = { [k as string]: attr };
    }
  } else {
    for (const k of [...path].reverse()) {
      attr = { [k as string]: attr };
    }
  }
  return attr;
}

export const setIsSwipe = (isSwipe: boolean) => ({
  types: SET_IS_SWIPE as typeof SET_IS_SWIPE,
  payload: {
    data: { isSwipe },
  },
});
export const setSwipeTimer = (timer: number) => ({
  types: SET_SWIPE_TIMER as typeof SET_SWIPE_TIMER,
  payload: {
    data: { timer },
  },
});

export const setAttributes = (attributes: BlueprintAttributes) => ({
  types: SET_ATTRIBUTES as typeof SET_ATTRIBUTES,
  payload: {
    data: { attributes },
  },
});

export const resetBlueprint = () => ({
  types: RESET_BLUEPRINT as typeof RESET_BLUEPRINT,
  payload: {
  },
});

export const setRequiredReviews = (requiredReviews: number[], clearReviews: boolean) => ({
  types: SET_REQUIRED_REVIEWS as typeof SET_REQUIRED_REVIEWS,
  payload: {
    data: { requiredReviews, clearReviews },
  },
});

export const setReviews = (reviews: number[]) => ({
  types: SET_REVIEWS as typeof SET_REVIEWS,
  payload: {
    data: { reviews },
  },
});

export const acquireBadge = (badge: BadgeName, monthly?: boolean) => ({
  types: ACQUIRE_BADGE as typeof ACQUIRE_BADGE,
  payload: {
    data: { badge, monthly },
  },
});

export const stopAction = () => ({
  types: STOP_ACTION as typeof STOP_ACTION,
  payload: {
    data: {},
  },
});

export const changePage = (page: string, isBack?: boolean, inAction: boolean = true) => ({
  types: CHANGE_PAGE as typeof CHANGE_PAGE,
  payload: {
    data: { page, isBack, inAction },
  },
});

const kanjiNum = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
export const nthChildName = (n: number) => `第${kanjiNum[n]}子`;

type fetchBlueprintSuccess = {
  types: typeof FETCH_BLUEPRINT_SUCCESS;
  payload: {
    data: Blueprint;
  };
};

export type Actions =
  | ReturnType<typeof setIsSwipe>
  | ReturnType<typeof setSwipeTimer>
  | ReturnType<typeof setAttributes>
  | ReturnType<typeof changePage>
  | ReturnType<typeof stopAction>
  | ReturnType<typeof setRequiredReviews>
  | ReturnType<typeof setReviews>
  | ReturnType<typeof acquireBadge>
  | ReturnType<typeof resetBlueprint>
  | fetchBlueprintSuccess
  | fetchUserSuccess
  | fetchStateSuccess
  | fetchUserDetailSuccess;

export const reducer = (state: AppState, action: Actions): AppState => {
  debug(`reducer: type=${action.types}`);
  state = serviceReducer(state, action);
  state = functionsReducer(state, action);
  switch (action.types) {
    case SET_ATTRIBUTES: {
      //let blueprint = state.blueprint;
      let attributes = { ...action.payload.data.attributes };
      const blueprint = updateAttributes(state.blueprint, attributes);
      const newState = { ...state, blueprint };
      //deepFreezeStrict(blueprint);
      return newState;
    }
    case RESET_BLUEPRINT: {
      return {
        ...state,
        blueprint: new Blueprint(),
      };
    }
    case SET_REQUIRED_REVIEWS: {
      const newBlueprint = new Blueprint({
        ...state.blueprint,
        requiredReviews: action.payload.data.requiredReviews,
        reviewedDate: sessionStartDate(),
      });
      if (action.payload.data.clearReviews) {
        newBlueprint.reviews = [];
      }
      return { ...state, blueprint: newBlueprint };
    }
    case SET_REVIEWS: {
      return {
        ...state, blueprint: new Blueprint({
          ...state.blueprint, reviews: action.payload.data.reviews
        })
      };
    }
    case ACQUIRE_BADGE: {
      const { badge, monthly } = action.payload.data;
      let badgeState = state.blueprint.badges[badge];
      if (badgeState) {
        if (monthly && badgeState.lastDate &&
          !isSameMonth(sessionStartDate(), badgeState.lastDate)) {
          const newCount = badgeState.count + 1;
          const newBadges = {
            ...state.blueprint.badges,
            [badge]: { count: newCount, lastDate: sessionStartDate() }
          }
          if (newCount % 10 == 0) {
            const monthly10Count = state.blueprint.badges.monthly10?.count || 0;
            newBadges.monthly10 = { count: monthly10Count + 1 };
          }
          if (newCount % 100 == 0) {
            const monthly100Count = state.blueprint.badges.monthly100?.count || 0;
            newBadges.monthly100 = { count: monthly100Count + 1 };
          }
          return {
            ...state, blueprint: new Blueprint({
              ...state.blueprint, badges: newBadges
            })
          };
        } else {
          return state;
        }
      } else {
        badgeState = { count: 1 };
        if (monthly) badgeState.lastDate = sessionStartDate();
        return {
          ...state, blueprint: new Blueprint({
            ...state.blueprint, badges: {
              ...state.blueprint.badges,
              [badge]: badgeState
            }
          })
        };
      }
    }
    case SET_IS_SWIPE: {
      return { ...state, isSwipe: action.payload.data.isSwipe, swipeTimer: 0 };
    }
    case SET_SWIPE_TIMER: {
      return { ...state, swipeTimer: action.payload.data.timer };
    }
    case CHANGE_PAGE: {
      if (state.currentPage === action.payload.data.page) {
        return state;
      }
      if (action.payload.data.isBack) {
        if (
          state.pages.length > 1 &&
          action.payload.data.page === state.pages[state.pages.length - 2]
        ) {
          return {
            ...state,
            isSwipe: false,
            inAction: state.isSwipe ? false : true,
            beforePage: "",
            currentPage: action.payload.data.page,
            nextPage: state.currentPage,
            pages: state.pages.slice(0, state.pages.length - 1),
          };
        } else {
          return {
            ...state,
            isSwipe: false,
            inAction: state.isSwipe ? false : true,
            beforePage: state.currentPage,
            currentPage: action.payload.data.page,
            nextPage: "",
            pages: [...state.pages, action.payload.data.page],
          };
        }
      }
      return {
        ...state,
        isSwipe: false,
        inAction: action.payload.data.inAction,
        beforePage: state.currentPage,
        currentPage: action.payload.data.page,
        nextPage: "",
        pages: [...state.pages, action.payload.data.page],
      };
    }
    case STOP_ACTION: {
      return { ...state, inAction: false };
    }
    default: {
      return state;
    }
  }
};

const adjustBlueprintAttributes = (prevBlueprint: Blueprint, attributes: BlueprintAttributes) => {
  if (attributes.hasChild !== undefined) {
    // 子どもの人数が未入力の場合は１人とみなす
    if (attributes.hasChild) {
      if ((prevBlueprint.childNum || 0) <= 0) {
        attributes.childNum = 1;
      }
    } else {
      attributes.childNum = 0;
    }
  }
  if (attributes.wishChild !== undefined) {
    if (attributes.wishChild === "yes") {
      if ((prevBlueprint.wishChildNum || 0) <= 0) {
        if (prevBlueprint.isStudentMode) {
          attributes.wishChildNum = 2;
        } else {
          attributes.wishChildNum = 1;
        }
      }
    } else {
      attributes.wishChildNum = 0;
    }
  }
  [[prevBlueprint.own, attributes.own], [prevBlueprint.partner, attributes.partner]].forEach(([b, a]) => {
    if (a && a.workAfterBirth) {
      if (a.workAfterBirth !== "retire") {
        if (b && b.childcareLeaveMonths == null) {
          a.childcareLeaveMonths = b.gender === "female" ? 10 : 1;
        }
        if (b && b.gender === "female" && b.maternityLeaveMonths == null) {
          a.maternityLeaveMonths = 3;
        }
      }
    }
  })

  // 住まいの種類に応じて購入希望かどうかを更新する
  if (attributes.houseOwnership === "owned") {
    if (!prevBlueprint.currentHouse) {
      attributes.currentHouse = _.clone(InitialHouse);
    }
    attributes.planningToBuyHouse = "bought";
  } else if (attributes.houseOwnership != null) {
    if (prevBlueprint.planningToBuyHouse === "bought") {
      attributes.planningToBuyHouse = "unknown";
    }
  }
  if (attributes.houseOwnership === "rental" && !finishedQuestions(prevBlueprint)) {
    attributes.movingPlans = [{ auto: true }];
  }

  if (attributes.planningToBuyHouse !== undefined) {
    if (attributes.planningToBuyHouse === "yes") {
      const age = getAge(prevBlueprint.own.birthday);
      attributes.housePurchasePlan = {
        ...InitialHousePurchasePlan,
        age: age <= 33 ? 35 : age + 2,
      };
      if (prevBlueprint.housePurchasePlan) {
        Object.assign(attributes.housePurchasePlan, prevBlueprint.housePurchasePlan);
      }
    }
  }
  if (attributes.hasCar !== undefined) {
    if (attributes.hasCar) {
      if ((prevBlueprint.numCars || 0) <= 0) {
        attributes.numCars = 1;
      }
    } else {
      attributes.numCars = 0;
    }
  }
  // デフォルトで7年サイクルで買い替える
  if (attributes.cars) {
    attributes.cars.forEach((car, i) => {
      if (car.purchaseAge != null && car.price != null) {   
        if (car.sellAge == null &&
          prevBlueprint.cars[i]?.sellAge == null) {
          car.sellAge = car.purchaseAge + 7;
        }
        if (car.nextInspectionYear == null &&
          prevBlueprint.cars[i]?.nextInspectionYear == null) {
          car.nextInspectionYear = prevBlueprint.currentDate().getFullYear() + 1;
        }
      }
    });
  }
  if (attributes.carPurchasePlans) {
    attributes.carPurchasePlans.forEach((car, i) => {
      if (car.purchaseAge != null && car.sellAge == null &&
        car.price != null &&
        prevBlueprint.carPurchasePlans[i]?.sellAge == null) {
        car.sellAge = car.purchaseAge + 7;
      }
    });
  }
  /*
  if (attributes.postCode != null) {
    attributes.postCode = attributes.postCode.replaceAll(/[^0-9]/g, '');
  }
  */
  if (prevBlueprint.isStudentMode && prevBlueprint.completed.length === 0) {
    // 学生版で質問の回答をもとにデフォルトの値を設定する
    if (attributes.own?.jobName) {
      attributes.own = {
        ...attributes.own,
        useJobName: true,
      };
    }
    const job = {
      ...prevBlueprint.own,
      ...attributes.own
    };
    if (job.jobCategory && job.jobName && job.startWorkingAge) {
      const salaryTable = getJobSalaryTable(job.jobCategory, job.jobName);
      if (salaryTable) {
        attributes.rent = Math.round(salaryTable[0] * 10 * 0.8 * 0.27 / 12) * 1000; // 初任給の手取りの 27%
      }
    }
  }
  [attributes.own, attributes.partner].forEach(person => {
    if (person) {
      if (person.profession && 
        (person.profession !== "regular_employee" && person.profession !== "public_employee")) {
        // 最初の職業は正社員・公務員のみ給与テーブルに対応する
        person.useJobName = false;
      }
      if (person.jobChanges) {
        person.jobChanges.forEach(job => {
          if (job && job.profession && job.profession !== "regular_employee") {
            job.useJobName = false;
          }
        });
      }
      if (person.jobCategory) {
        if (person.jobCategory === "国家公務員") {
          Object.assign(person, {
            hasSeverancePay: true,
            severancePayAge: 60,
            severancePay: 20505955, // 税金計算済
          });
        } else if (person.jobCategory === "地方公務員") {
          Object.assign(person, {
            hasSeverancePay: true,
            severancePayAge: 60,
            severancePay: 21158838, // 税金計算済
          });
        } else {
          person.hasSeverancePay = false;
        }
      }
    }
  });
}

const updateAttributes = (prevBlueprint: Blueprint, attributes: BlueprintAttributes): Blueprint => {
  // 入力された attributes をもとに必要な属性を追加する
  adjustBlueprintAttributes(prevBlueprint, attributes);

  // blueprint にマージする
  let blueprint = new Blueprint(prevBlueprint);
  //Object.assign(blueprint, attributes);
  _.merge(blueprint, attributes);
  Object.freeze(attributes);
  // 配列を減らすような処理は _.merge の後に行う

  if (attributes.wishPartner) {
    if (_.includes(["yes", "married"], attributes.wishPartner)) {
      const defaultPartner = {
        gender: blueprint.own.gender == "male" ? "female" : "male",
        birthday: blueprint.own.birthday,
      } as Partial<Properties>;
      if ("yes" === attributes.wishPartner) {
        defaultPartner.profession = "regular_employee";
        defaultPartner.monthlySalary = 270000;
        if (blueprint.marriageAge === undefined) {
          if (blueprint.isStudentMode) {
            blueprint.marriageAge = 29;
          } else {
            blueprint.marriageAge = getAgeInYearEnd(blueprint.own.birthday, sessionStartDate()) + 2;
          }
        }
      }
      blueprint.partner = new Properties({
        ...defaultPartner,
        ...blueprint.partner,
      });
    }
  }
  if (attributes.childNum) {
    const num = attributes.childNum;
    const children = [...blueprint.currentChildren].slice(0, num);
    for (let i = children.length; i < num; i++) {
      children.push({ birthday: prevBlueprint.currentDate() });
    }
    blueprint.currentChildren = children;
  }
  if (attributes.wishChildNum) {
    const num = attributes.wishChildNum;
    const children = [...blueprint.desiredChildren].slice(0, num);
    const baseYear = ("yes" === blueprint.wishPartner && blueprint.marriageAge) ?
      sessionStartDate().getFullYear() + blueprint.marriageAge - getAgeInYearEnd(blueprint.own.birthday, sessionStartDate()) :
      sessionStartDate().getFullYear();
    for (let i = children.length; i < num; i++) {
      const birthday = new Date(baseYear + (i + 1) * 2, sessionStartDate().getMonth());
      children.push({ birthday: birthday });
    }
    blueprint.desiredChildren = children;
  }
  if (attributes.own?.wishJobChange === "yes" && !attributes.own.jobChanges) {
    if (blueprint.own.jobChanges.length === 0) {
      blueprint.own.jobChanges = [{}];
    }
  }
  if (attributes.own?.wishJobChange === "no" || attributes.own?.wishJobChange === "unknown") {
    blueprint.own.jobChanges = [];
  }
  if (attributes.hasHousemate) {
    if (blueprint.housemates.length === 0) {
      blueprint.housemates = [{}];
    }
  }

  // 配列の中で null になっているオブジェクトは削除しつつコピーする
  // 例えば attributes.own.jobChanges = [{age: 30, ...}, null] のように値が入るので
  // compact することで削除する
  // 
  // own / partner 配下の配列
  ["own", "partner"].forEach((p) => {
    const person = p as "own" | "partner";
    ["jobChanges", "sideJobs", "ideco", "nisa", "tsumitateNisa", "dc", "investments", "periodicInvestments", "inheritance"].forEach(key => {
      if (attributes[person] && (attributes[person] as any)[key] && blueprint[person]) {
        (blueprint[person] as any)[key] = _.cloneDeep(_.compact((attributes[person] as any)[key]));
      }
    });
  });
  // blueprint 配下の配列
  [
    "cars",
    "carPurchasePlans",
    "housemates",
    "insurances",
    "specialIncome", "monthlyIncome", "repeatedIncome",
    "specialExpense", "monthlyExpense", "repeatedExpense",
    "movingPlans",
  ].forEach(key => {
    if ((attributes as any)[key]) {
      (blueprint as any)[key] = _.cloneDeep(_.compact((attributes as any)[key]));
    }
  });

  if (attributes.planningToBuyCar) {
    if (attributes.planningToBuyCar === "yes") {
      if (blueprint.carPurchasePlans.length === 0) {
        blueprint.carPurchasePlans = [_.clone(InitialCar)];
      }
    } else {
      blueprint.carPurchasePlans = [];
    }
  }
  if (attributes.numCars !== undefined) {
    if (attributes.numCars < blueprint.cars.length) {
      blueprint.cars.splice(attributes.numCars, blueprint.cars.length - attributes.numCars);
    } else if (blueprint.cars.length < attributes.numCars) {
      for (let i = 0; i < attributes.numCars - blueprint.cars.length; i++) {
        blueprint.cars.push({ ...InitialCar });
      }
    }
  }
  if (_.includes(["yes", "planned"], attributes.hasInsurance)) {
    if (blueprint.insurances.length === 0) {
      blueprint.insurances = [{}];
    }
  }
  if (_.includes(["yes", "planned"], attributes.hasSavingsTypeInsurance)) {
    if (blueprint.savingsTypeInsurance.length === 0) {
      blueprint.savingsTypeInsurance = [{}];
    }
  }
  if (attributes.hasDebt) {
    if (blueprint.debt.length === 0) {
      blueprint.debt = [];
    }
  }
  if (!_.isEmpty(attributes.debt)) {
    blueprint.debt.forEach(d => d.asOf = prevBlueprint.currentDate());
  }
  if (attributes.childcarePolicy?.studentInsurance === null) {
    delete blueprint.childcarePolicy.studentInsurance;
  }
  if (attributes.currentHouse?.price && blueprint.currentHouse &&
    !blueprint.currentHouse.propertyTax) {
    blueprint.currentHouse.propertyTax = attributes.currentHouse.price * 0.7 * 0.014;
  }
  if (attributes.housePurchasePlan?.price && blueprint.housePurchasePlan &&
    !blueprint.housePurchasePlan?.propertyTax) {
    blueprint.housePurchasePlan.propertyTax = attributes.housePurchasePlan.price * 0.7 * 0.014;
  }
  if (attributes.rent !== undefined) {
    if (blueprint.rentRenewalFee == null) {
      blueprint.rentRenewalFee = attributes.rent;
    }
    if (blueprint.nextRentRenewalYear == null) {
      blueprint.nextRentRenewalYear = blueprint.createdDate.getFullYear() + 1;
    }
  }
  return blueprint;
}

type ContextState = {
  state: AppState;
  dispatch(action: Actions): void;
};

export const Context = React.createContext<ContextState>({
  state: InitialAppState,
  dispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
