import * as React from "react";
import styles from "./Timeline.module.scss";
import { AgeEvent, Simulator } from "../../simulator";
import singleIcon from "./single.svg";
import { nthChildName } from "../../Store";
import { ReactComponent as BabyIcon } from "./baby.svg";
import { ReactComponent as CarIcon } from "./car.svg";
import { ReactComponent as HouseIcon } from "./house.svg";
import { ReactComponent as IncomeIcon } from "./income.svg";
import { ReactComponent as PaymentIcon } from "./payment.svg";
import { ReactComponent as WeddingIcon } from "./wedding.svg";
import { ReactComponent as WorkIcon } from "./work.svg";

const Icon = (props: {name: AgeEvent["icon"], color?: string}) => (
  {
    baby: <BabyIcon />,
    car: <CarIcon />,
    house: <HouseIcon />,
    income: <IncomeIcon />,
    payment: <PaymentIcon />,
    wedding: <WeddingIcon />,
    work: <WorkIcon />,
    person: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white"/>
      <ellipse cx="9.64286" cy="6.61539" rx="4.64286" ry="4.61539" fill={props.color}/>
      <path d="M5 17.0001L9.64286 6.61548L14.8661 17.0001H5Z" fill={props.color}/>
    </svg>,
  }[props.name] || <></>
);

export default (props: {plan: Simulator}) => {
  const eventsByAge = props.plan.events();
  let index = 1;
  const getIconColor = (event: AgeEvent) => {
    switch (event.target) {
      case "own":
        switch (props.plan.blueprint.own.gender) {
          case "male":
            return "#4789E6";
          case "female":
            return "#ED8BD1";
          default:
            return "#888888";
        }
      case "partner":
        switch (props.plan.blueprint.own.gender) {
          case "female":
            return "#4789E6";
          case "male":
            return "#ED8BD1";
          default:
            return "#888888";
        }
      case "child":
        return "#58BA7D";
      default:
        return "#3FB445";
    }
  }
  return (
    <>
    <div className={styles.container}>
      {
        eventsByAge.map(({age, events}) => {
          return (
            <div className={styles.row} key={age}>
              <div className={styles.age}>
                {age} 歳
                <div className={styles.dot}>
                </div>
              </div>
              <div className={styles.description}>
                {
                  events.map((event, i) => {
                    return (
                      <div className={styles.event} key={i}>
                        <div>
                          <Icon name={event.icon} color={getIconColor(event)} />
                        </div>
                        <div className={styles.eventTitle}>
                          {{
                            own: "あなた ",
                            partner: "配偶者 ",
                            family: "",
                            child: `${nthChildName(event.childIndex || 0)} `
                          }[event.target]}
                          {event.title}
                        </div>
                      </div>
                      );
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
    </>
  );
};
