import React from "react";
import styles from "./EstimatedExpense.module.scss";
import { formatSmallMoneyWithUnit } from "../../simulator";
import { EstimatedExpenseType, UserDetail } from "../../Store/functions";
import { Blueprint } from "../../Store";
import iconArrowDown from "./arrow-down.svg";
import iconArrowTop from "./arrow-top.svg";

const EstimatedExpenseTable = (props: { title: string, rows: NonNullable<UserDetail["expense"]> }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  if (props.rows.length === 0) {
    return <></>;
  }

  return <table className={styles.table}>
    <thead>
      <tr onClick={() => setCollapsed(!collapsed)}>
        <th colSpan={3}>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <div className={styles.title}>
                {props.title}
              </div>
            </div>
            <div className={styles.total}>
              合計　{formatSmallMoneyWithUnit(props.rows.reduce((sum, e) => sum + e.amount, 0))}
            </div>
            <div>
              {collapsed ? <img src={iconArrowDown} /> : <img src={iconArrowTop} />}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody style={collapsed ? { display: "none" } : {}}>
      {props.rows.map(e =>
        <tr>
          <td className={styles.category}>
            {e.categoryName}
          </td>
          <td className={styles.genre}>
            {e.genreName}
          </td>
          <td className={styles.amount}>
            {formatSmallMoneyWithUnit(e.amount)}
          </td>
        </tr>
      )}
    </tbody>
  </table>
}

const EstimatedExpense = (props: { title: string, expense: Blueprint["defaultLivingCost"] }) => {
  return <>
    <div>
      {props.title}
    </div>
    { props.expense.filter.length > 0 &&
      <>
        <EstimatedExpenseTable title="推定に含んでいるカテゴリ" rows={props.expense.fromExpense.filter(e => props.expense.filter.includes(e.expenseType))} />
        <EstimatedExpenseTable title="推定に含んでいないカテゴリ" rows={props.expense.fromExpense.filter(e => !props.expense.filter.includes(e.expenseType))} />
      </>
    }
    { props.expense.filter.length === 0 &&
      <>
        <EstimatedExpenseTable title="推定に含んでいるカテゴリ" rows={props.expense.fromExpense} />
      </>
    }
  </>
}

export default EstimatedExpense;
