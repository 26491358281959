import React, { useCallback, useReducer } from "react";
import { CSSTransition } from "react-transition-group";
import firebase, { functions, PRODUCTION } from './firebase';
import {
  rootPath,
  finishPath,
  questionIndexPath,
  startPath,
  allChartsPath,
  startStudentPath} from "./path";
import {
  reducer,
  Context,
  changePage,
  stopAction,
  appState,
  setIsSwipe,
  setSwipeTimer,
  AppState,
  Actions,
  fetchState,
  saveState,
  sessionStartDate,
  finishedQuestions,
  isPremiumUser,
} from "./Store";
import "./App.scss";
import ProgressBar from "./atoms/ProgressBar";
import Header from "./molecules/Header";
//import AskOwnIncome from "./pages/AskOwnIncome";
import Finish from "./pages/Finish";
//import AskHome from "./pages/AskHome";
import { QuestionCategories, QuestionIndex, getProgress } from "./pages/questions";
import { SettingsPages, settingsPath } from "./pages/settings";
import Start from "./pages/Start";
import AllCharts from "./pages/AllCharts";
import { fetchUserDetail } from "./Store/service";
import Intro from "./pages/Intro";
import debug from "./debug";

const getComponent = (state: AppState, path: string) => {
  for (const cat of QuestionCategories) {
    const pages = cat.createPages(state);
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      if (page.path === path) {
        const next = i < pages.length - 1 ? pages[i + 1].path : null;
        return (<page.component next={next} />);
      }
    }
  }
  if (path.startsWith("/settings/")) {
    const Page = SettingsPages[path.substring("/settings/".length)];
    if (Page) {
      return (<Page />);
    }
  }

  return path === rootPath ? (
    <Intro authenticated={true} />
  ) : path === startPath ? (
    <Start mode="default" />
  ) : path === startStudentPath ? (
    <Start mode="student" />
  ) : path === questionIndexPath ? (
    <QuestionIndex />
  ) : path === finishPath ? (
    <Finish />
  ) : path === allChartsPath ? (
    <AllCharts />
  ) : (
    <QuestionIndex /> // URL がおかしい場合は質問一覧にもどる
  )
};

const reducerForApp = 
  process.env.NODE_ENV === "production"
    ? reducer
    : (state: AppState, action: Actions) => {
      console.groupCollapsed(action.types);
      console.log("action", action);
      console.log("prevState", state);
      const result = reducer(state, action);
      console.log("currentState", result);
      console.groupEnd();
      return result;
    };

export const LoadUser = (props: { uid: string } )=> {
  const { dispatch, state } = React.useContext(Context);
  React.useEffect(() => {
    debug(`props.uid=${props.uid}`);
    if (props.uid) {
      if (firebase.analytics) {
        firebase.analytics().setUserId(props.uid);
        firebase.analytics().logEvent("login", {method: "default"});
      }
      debug(`LoadUser: user=${props.uid}`);
      fetchState(dispatch, props.uid);
    }
  }, [props.uid]);
  React.useEffect(() => {
    if (state.currentPage !== rootPath && state.user.id && !isPremiumUser(state)) {
      window.location.href = "https://content.zaim.net/home/subscriptions/add?tier=basic";
      return;
    }
  }, [state.user.id, state.currentPage]);
  React.useEffect(() => {
    // ログイン時（初回または1日ごと）およびリセット後にZaimのデータを取得する
    if (state.user.id && isPremiumUser(state)) {
      const updatedDate = state.blueprint.estimate.updatedDate;
      let nextUpdateDate: Date | null = null;
      if (updatedDate) {
        //nextUpdateDate.setMonth(nextUpdateDate.getMonth() + 1);
        if (PRODUCTION) {
          nextUpdateDate = new Date(updatedDate);
          nextUpdateDate.setDate(nextUpdateDate.getDate() + 1);
        } else {
          nextUpdateDate = new Date(updatedDate.getTime() + 60000);
        }
      }
      if (!nextUpdateDate ||
        (nextUpdateDate && nextUpdateDate <= sessionStartDate())) {
        debug("fetching user detail", nextUpdateDate);
        fetchUserDetail(dispatch);
      }
      // ユーザのロードが完了したあとで最初の plan を保存する
      if (updatedDate === sessionStartDate()) {
        saveState(state);
      }
    }
  }, [state.user.id, state.blueprint.estimate]);

  const startSwipe = useCallback(
    () => {
      if (state.swipeTimer) {
        clearTimeout(state.swipeTimer);
      }
      dispatch(setIsSwipe(true));
    },
    // eslint-disable-next-line
    [state.swipeTimer]
  );

  const endSwipe = useCallback(() => {
    const timer = setTimeout(() => {
      if (state.swipeTimer) {
        dispatch(setIsSwipe(false));
      }
    }, 600);
    dispatch(setSwipeTimer(timer as any));
    // eslint-disable-next-line
  }, []);
  const backPage = useCallback(() => {
    const page = window.location.pathname;
    dispatch(changePage(page ? page : "/", true));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    window.addEventListener("touchmove", startSwipe, false);
    window.addEventListener("touchend", endSwipe, false);
    window.addEventListener("popstate", backPage, false);
    return () => {
      window.removeEventListener("touchmove", startSwipe);
      window.removeEventListener("touchend", endSwipe);
      window.removeEventListener("popstate", backPage);
    };
  }, [startSwipe, endSwipe, backPage]);

  React.useEffect(() => {
    (document.getElementById("root") as HTMLDivElement).scrollIntoView();
  }, [state.currentPage]);

  const pages = state.beforePage
    ? [state.beforePage, state.currentPage]
    : state.nextPage
    ? [state.currentPage, state.nextPage]
    : [];
  const [isDual, setIsDual] = React.useState(false);
  const currentRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  if (props.uid && state.user.id) {
    if (state.currentPage === rootPath) {
      return <Intro authenticated={true} />;
    } else if (isPremiumUser(state)) {
      return (
        <div className="app">
        { (!finishedQuestions(state.blueprint) ||
          state.currentPage === questionIndexPath) &&
        <Header fadeout={finishedQuestions(state.blueprint)}>
          <ProgressBar percent={getProgress(state)} />
        </Header>
        }
        <CSSTransition
          in={state.inAction}
          timeout={400}
          classNames={state.nextPage ? "panel-out" : "panel-in"}
          onEnter={() => {
            if (currentRef.current && state.nextPage) {
              currentRef.current.style.position = "relative";
              currentRef.current.style.left = "-100%";
            }
            setIsDual(true);
          }}
          onEntered={() => {
            dispatch(stopAction());
            if (currentRef.current && state.nextPage) {
              currentRef.current.style.position = "";
              currentRef.current.style.left = "0";
            }
            setIsDual(false);
          }}
        >
          {pages.length > 1 ? (
            <div
              ref={currentRef}
              className={state.inAction ? "panelBoardInAction" : "panelBoard"}
            >
              {!!(state.nextPage || isDual) && (
                <div className="panel">{getComponent(state, pages[0])}</div>
              )}
              {!!(state.beforePage || isDual) && (
                <div className="panel">{getComponent(state, pages[1])}</div>
              )}
            </div>
          ) : getComponent(state, state.currentPage)
          }
        </CSSTransition>        
        </div>
      );
    }
  }
  return (
    <p className="update">読み込んでいます…このままお待ち下さい</p>
  );
};

const App = (props: { uid?: string }) => {
  const [state, dispatch] = useReducer(
    reducerForApp as React.Reducer<any, any>,
    {
      ...appState,
      currentPage: window.location.pathname || "/",
      }
    );
  return (
      <Context.Provider value={{ state, dispatch }}>
        { props.uid ?
          <LoadUser uid={props.uid} />
          :
          <Intro authenticated={false} />
        }
      </Context.Provider>
  );
};

export default App;
