import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import GetAccessToken from './auth/GetAccessToken';
import GetUser from './auth/GetUser';
import * as serviceWorker from './serviceWorker';
import qs from 'qs';
import SelectUid from './auth/SelectUid';
import { AUTH_DISABLED, functionURL, PRODUCTION, AUTH_URL } from './firebase';
import { LoadToken } from './auth/LoadToken';
import axios, { AxiosResponse } from 'axios';
import debug from './debug';
import { redirectForAuthorization } from './auth/auth';
import { Simulator } from './simulator';

if (AUTH_DISABLED) {
  const uid = sessionStorage.getItem("uid");
  if (uid) {
    debug("uid", uid);
    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <App uid={uid} />
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <SelectUid />
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
} else {
  const query = qs.parse(window.location.search.replace(/^\?/, ''));
  debug(`query=${JSON.stringify(query)}`);

  if (query.code != null) {
    // 2: callback
    const verifier = sessionStorage.getItem('verifier');
    sessionStorage.removeItem('verifier');
    if (verifier) {
      ReactDOM.render(
        <React.StrictMode>
          <GetAccessToken code={query.code as string} code_verifier={verifier}/>
        </React.StrictMode>,
        document.getElementById("root")
      );
    } else {
      console.error("no verifier");
    }
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <GetUser path={window.location.pathname} forceAuth={query.login === "true"}/>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
  interface Window {
    simulator: Simulator;
  }
}
