import * as React from "react";
import Title from "../../atoms/Title";
import styles from "./AssetChart.module.scss";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  AreaChart,
  Area,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
} from "recharts";
import { Simulator, Age, formatMoneyWithUnit } from "../../simulator";
import Container from "../../molecules/Container";
import _ from "lodash";
import { PlanningToBuyHouseNames } from "../../Store";

export default (props: {plan: Simulator, name: string, getValue: (age: Age) => number}) => {
  const age = props.plan.currentAge;
  const data = props.plan.ages.map((age, i) => ({
    index: age.age,
    [props.name]: Math.round(props.getValue(age) / 10000),
  }));
  const ticks = Array.from(Array(8).keys()).map(n => `${n*10} 歳`);
  const minValue = _.min([0, ...data.map(record => record[props.name])])!;
  const maxValue = _.max(data.map(record => record[props.name]))!;
  const domain = [Math.floor(minValue / 1000) * 1000, Math.ceil(maxValue / 1000) * 1000] as const;
  let yTicks:number[] | undefined = undefined;
  if (domain[1] - domain[0] < 20000) {
    yTicks = [];
    const delta = domain[1] - domain[0] >= 2000 ? 1000 : 100;
    for (let n = domain[0]; n < domain[1]; n += delta) {
      yTicks.push(n);
    }
  }
  const stopOffset = 
    maxValue <= 0 ? 0 : 
    minValue >= 0 ? 1 : 
    maxValue / (maxValue - minValue);
  //const chartHeight = 180;
  //const zeroPoint = (domain[1] / (domain[1] - domain[0])) * chartHeight;
  /*
  const [zeroPoint, setZeroPoint] = React.useState(null as null | number);
  
  React.useEffect(() => {
    const height = parseInt(document.querySelector(".yAxis line")!.getAttribute("height") as string);
    if (height > 0) {
      setZeroPoint((domain[1] / (domain[1] - domain[0])) * height);
    }
  }, []);
  */
  let index = 0;
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <ResponsiveContainer height={200} width="95%">
        <AreaChart className={styles.chart}
          height={240}
          data={data}
        >
  <defs>
    <linearGradient id={`${props.name}-areaFill`} x1="0" y1="0" x2="0" y2="1">
      <stop offset={stopOffset} stopColor="#3FB445" stopOpacity={0.2}/>
      <stop offset={stopOffset} stopColor="#E04545" stopOpacity={0.2}/>
    </linearGradient>
    <linearGradient id={`${props.name}-areaStroke`} x1="0" y1="0" x2="0" y2="1">
      <stop offset={stopOffset} stopColor="#3FB445" stopOpacity={1}/>
      <stop offset={stopOffset} stopColor="#E04545" stopOpacity={1}/>
    </linearGradient>
    <filter id="offsetDot" height="200%">
      <feOffset in="SourceGraphic" dx="0" dy="20" />
    </filter>    
  </defs>
          {/*CartesianGrid strokeDasharray="3 3"
            vertical={false}
            horizontalPoints={[zeroPoint]}
          />
  */}
          <ReferenceLine y={0} strokeDasharray="2 5" />
          {/*<ReferenceLine y={domain[0]} stroke="#ccc" />*/}
          <XAxis dataKey="index" axisLine={false} tickSize={3} tickMargin={6} tick={{fontSize: 10}} height={14} interval="preserveEnd" unit=" 歳"/>
          <YAxis axisLine={false} domain={domain} width={30} tick={{fontSize: 10}} tickMargin={6} tickSize={0} ticks={yTicks} />
          <Tooltip separator=" " formatter={(value) => formatMoneyWithUnit(parseInt(value as any) * 10000)} labelFormatter={(value) => `${value} 歳`}/>
          <Area
            type="monotone"
            dataKey={props.name}
            stroke={`url(#${props.name}-areaStroke)`}
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
            fill={`url(#${props.name}-areaFill)`}
          />
        </AreaChart>
        </ResponsiveContainer>
      </div>
);
};
