import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import styles from "./SelectField.module.scss";

export default (props: {
  value: string;
  placeholder?: string;
  fieldRef?: React.RefObject<HTMLSelectElement>;
  master: { [key: string]: string };
  onChange: (v: string) => void;
  unit?: string;
}) => {
  return (
    <>
      <FormControl>
      <Select 
        value={props.value}
        onChange={(e: React.ChangeEvent<any>) => props.onChange(e.target.value)}
      >
        { props.placeholder &&
          <MenuItem value="-" disabled>{props.placeholder}</MenuItem>
        }
        {Object.keys(props.master).map(k => {
          return (
            <MenuItem key={k} value={k}>
              {props.master[k]}
            </MenuItem>
          );
        })}
      </Select>
      {props.unit &&
        <label className={styles.unit}>{props.unit}</label>
      }
       </FormControl>
    </>
  );
};
