import * as React from "react";
import styles from "./RadioField.module.scss";

export default (props: {
  name?: string;
  value: string;
  master: { [key: string]: string };
  onChange: (args: string) => void;
}) => (
  <div className={styles.radioArea}>
    {Object.keys(props.master).map(key => (
      <label className={styles.selectBtn} key={key}>
        <input
          type="radio"
          name={props.name}
          value={key}
          onChange={(e: React.ChangeEvent<any>) => props.onChange(key)}
          checked={props.value === key}
        />
        <span>{props.master[key]}</span>
      </label>
    ))}
  </div>
);
