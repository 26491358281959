import * as React from "react";
import styles from "./TextField.module.scss";

export default (props: {
  value?: string | null;
  autoFocus: boolean;
  onChange: (v: string) => void;
  onFocusChange?: (focus: boolean) => void;
  maxLength?: number;
  type?: "text" | "number";
  pattern?: string;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder?: string;
}) => {
  const currentRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState(false);
  React.useEffect(() => {
    if (state) {
      currentRef.current && currentRef.current.focus();
    }
  }, [state, currentRef]);
  React.useEffect(() => {
    if (props.autoFocus) {
      const timer = setTimeout(() => {
        setState(true);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [props.autoFocus]);

  return (
    <input
      onFocus={e => props.onFocusChange && props.onFocusChange(true)}
      onBlur={e => props.onFocusChange && props.onFocusChange(false)}
      ref={currentRef}
      maxLength={props.maxLength}
      className={styles.defaultInput}
      type={props.type || "text"}
      pattern={props.pattern}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value != null ? props.value : ""}
      placeholder={props.placeholder}
      inputMode={props.inputMode}
    />
  );
};
