import React from 'react';
import firebase, { functionURL } from '../firebase';
import debug from '../debug';
import { redirectForAuthorization } from './auth';
import ErrorBoundary from '../ErrorBoundary';
import App from '../App';

const GetUser = (props: {path: string, forceAuth: boolean}) => {
  const [uid, setUid] = React.useState<string | null>(null);
  const [error, setError] = React.useState("");
  const authenticate = props.path !== "/" || props.forceAuth;
  React.useEffect(() => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    .then(() => firebase.auth().onAuthStateChanged((user) => {
      debug("onAuthStateChanged", user);
      if (user) {
        setUid(user.uid);
      } else {
        setUid("NO_USER");
      }
    }));
  }, []);
  React.useEffect(() => {
    if (uid === "NO_USER" && authenticate) {
      redirectForAuthorization()
      .catch((err) => {
        setError(String(err));
        console.log(err);
      })
    }
  }, [uid]);
  if (uid && uid !== "NO_USER") {
    return (
      <React.StrictMode>
        <ErrorBoundary>
          <App uid={uid} />
        </ErrorBoundary>
      </React.StrictMode>
    );
  } else if (error.length === 0 && !authenticate) {
    // 非ログイン状態で LP を表示
    return (
      <React.StrictMode>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    );
  } else {
    return <div>{error}</div>;
  }
};

export default GetUser;
