import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import styles from "../settings.module.scss";
import * as React from "react";

export default {
  // 亡くなる年齢
  lifespan: (<div>
    <p>現在の年齢からあと何年、生きられるかは「平均余命」として厚生労働省が公表しています。2017 年の<a href="https://www.mhlw.go.jp/toukei/saikin/hw/life/life19/dl/life19-02.pdf" target="_blank" rel="noopener noreferrer">直近の発表</a>によると、男女それぞれの平均余命は以下の通り。左側が「現在の年齢」で、右側が「残り生きられる平均余命」です。</p>
    <h3>女性</h3>
    <table className={styles.vertical}>
      <tr>
        <th>20 歳</th>
        <td>67.77 年</td>
      </tr>
      <tr>
        <th>30 歳</th>
        <td>57.91 年</td>
      </tr>
      <tr>
        <th>40 歳</th>
        <td>48.11 年</td>
      </tr>
      <tr>
        <th>50 歳</th>
        <td>38.49 年</td>
      </tr>
      <tr>
        <th>60 歳</th>
        <td>29.17 年</td>
      </tr>
      <tr>
        <th>70 歳</th>
        <td>20.21 年</td>
      </tr>
    </table>
    <h3>男性</h3>
    <table className={styles.vertical}>
      <tr>
        <th>20 歳</th>
        <td>61.77 年</td>
      </tr>
      <tr>
        <th>30 歳</th>
        <td>52.03 年</td>
      </tr>
      <tr>
        <th>40 歳</th>
        <td>42.35 年</td>
      </tr>
      <tr>
        <th>50 歳</th>
        <td>32.89 年</td>
      </tr>
      <tr>
        <th>60 歳</th>
        <td>23.97 年</td>
      </tr>
      <tr>
        <th>70 歳</th>
        <td>15.96 年</td>
      </tr>
    </table>
    <p>Zaim は上記の平均余命を考慮しながら余裕を残した人生設計ができるよう、男女ともに亡くなる年齢の初期値を「90 歳」としています。</p>
  </div>),
  // 新婚旅行
  honeymoonCost: (<div>
    <p><a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=459" target="_blank" rel="noopener noreferrer">ゼクシィの結婚トレンド調査</a>によると、新婚旅行にかける費用感の分布は、以下の通りです。</p>

    <table className={styles.vertical}>
      <tr>
        <th>〜50 万円</th>
        <td>31.9％</td>
      </tr>
      <tr>
        <th>〜99 万円</th>
        <td>47.6％</td>
      </tr>
      <tr>
        <th>〜149 万円</th>
        <td>17.3％</td>
      </tr>
      <tr>
        <th>150 万円〜</th>
        <td>3.3％</td>
      </tr>
    </table>

    <p>8 割近くの人が海外を行き先に選んでおり、中でもハワイとヨーロッパが人気です。</p>
    <p>以下のような世間の休みと重複して新婚旅行を設定すると、費用は高くなります。</p>
    <ul>
      <li>年末年始</li>
      <li>ゴールデンウィーク</li>
      <li>お盆休み</li>
      <li>シルバーウィーク</li>
      <li>その他 3 日以上のカレンダー上の連休</li>
    </ul>
  </div>),
  // 結婚指輪
  marriageRingCost: (<div>
    <p>プロポーズの時にサプライズで用意するイメージもある「婚約指輪」は、結婚の約束を交わした証しとして男性から女性に贈る慣習があります。一方の「結婚指輪」は、結婚の印に二人で用意するものとされ、男性も女性も身に着けます。</p>

    <h3>婚約指輪</h3>
    <p><a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=69" target="_blank" rel="noopener noreferrer">ゼクシィの結婚トレンド調査</a>によると、婚約指輪にかける費用感の分布は、以下の通りです。</p>

    <table className={styles.vertical}>
      <tr>
        <th>〜10 万円</th>
        <td>5.0％</td>
      </tr>
      <tr>
        <th>〜30 万円</th>
        <td>32.4％</td>
      </tr>
      <tr>
        <th>〜50 万円</th>
        <td>42.6％</td>
      </tr>
      <tr>
        <th>50 万円〜</th>
        <td>19.8％</td>
      </tr>
    </table>
    <p>平均は 35.7 万円です。多少の地域差があり、最も低いのは北海道の 27.4 万円、最も高いのは首都圏の 39.3 万円でした。</p>

    <h3>結婚指輪</h3>

    <p><a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=99" target="_blank" rel="noopener noreferrer">ゼクシィの結婚トレンド調査</a>によれば、結婚指輪にかける費用感の分布は、以下の通りです。</p>

    <table className={styles.vertical}>
      <tr>
        <th>〜10 万円</th>
        <td>3.3％</td>
      </tr>
      <tr>
        <th>〜30 万円</th>
        <td>69.4％</td>
      </tr>
      <tr>
        <th>〜50 万円</th>
        <td>21.8％</td>
      </tr>
      <tr>
        <th>50 万円〜</th>
        <td>5.5％</td>
      </tr>
    </table>
    <p>平均は約 25.1 万円です。最も低い中国・九州地方（22.6 万円）と最も高い首都圏（26.6 万円）の差は 4 万円と、婚約指輪に比べると地域差は大きくありません。</p>
  </div>),
  // 結婚式
  weddingCost: (<div>
    <p>「結婚式」は通常、結婚を誓う儀式である「挙式」や、結婚したことを親族や友人にお披露目する「披露宴」のことを指します。それ以外にも結婚の前準備として、二人の両親同士を紹介する食事会「両家顔合わせ」や婚約の儀式である「結納」があります。<a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=39" target="_blank" rel="noopener noreferrer">ゼクシィの結婚トレンド調査</a>によると、結婚にかかる費用とその内訳の平均額は、以下の通りです。</p>
    <table className={styles.vertical}>
      <tr>
        <th>両家顔合わせ</th>
        <td>6.5 万円</td>
      </tr>
      <tr>
        <th>結納</th>
        <td>22.7 万円</td>
      </tr>
      <tr>
        <th>挙式・披露宴</th>
        <td>362.3 万円</td>
      </tr>
    </table>
    <p>Zaim では上記 3 項目の合計からご祝儀としてもらえる金額の平均を引いた値を、結婚式にかかるお金の初期値としています。</p>
    <h3>九州は披露宴の人数も負担額も多い</h3>
    <p>披露宴は<a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=143" target="_blank" rel="noopener noreferrer">招待する人数</a>によって、かかる費用やもらえる<a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=190" target="_blank" rel="noopener noreferrer">ご祝儀</a>が変わるため、本人たちの実際の負担額にも影響します。</p><p>披露宴の招待客の人数は 60人〜 80人あたりが平均的。ただし、地域によって差があります。関西や北陸地方では約 60 人である一方、九州では 90 人を超えます。ご祝儀は平均して 230 万円ほどですが、こちらも地域差があり、北海道は 114 万円、九州は 300 万円近くになります。</p>
    <h3>低価格化・簡略化が進む</h3>
    <p>結婚式の実施も多様化してきており、低価格の結婚をプロデュースする「<a href="https://anymarry.mwed.jp/ceremony" target="_blank" rel="noopener noreferrer">エニマリ</a>」のようなサービスを使うと、二人だけの挙式であれば 10 万円ほどから実施できます。</p>
    <p>また、近年は<a href="https://souken.zexy.net/data/trend2020/XY_MT20_report_06shutoken.pdf#page=45" target="_blank" rel="noopener noreferrer">結納せずに両家顔合わせのみで済ませるケース</a>も増えています。</p>
  </div>),
  // 自動車税
  tax: (<div>
    <p>自動車税は、毎年 4 月 1 日時点で自動車を保有している人が払う税金です。年度の途中で車を購入した場合は、新車登録をした翌月から 3 月までの月割りで支払います。</p>

    <h3>一般的な普通車は年 3〜6 万円</h3>
    <p>金額は車種や年数、排気量によって異なり、年数が新しく排気量が少ないほど安くなります。</p>
    <p>例えば 2019 年 10 月 1 日以降に新車として登録された軽自動車（排気量 660cc）は年額 2 万 5,000 円です。一方で 2019 年 9 月 30 日以前に新車として登録された排気量 2,000cc の普通自動車は、年額 3 万 9,500 円です。</p>

    <h3>エコカー減税なら半額以下も</h3>
    <p>また、最近の新車は 「エコカー減税」 の対象になっている車種が多くあります。2021 年 3 月 31 日までに新車として登録していると、軽自動車で 25％〜75％、普通自動車で 50％〜75％ほど安くなります。</p>
  </div>),
  // 年金型の退職
  hasCorporatePension: (<div>
    <p>この項目は、退職金を年金方式でもらう以外にも、企業型の確定給付年金や確定拠出年金として年金を受け取る場合も「あり」としてください。</p>

    <h3>制度は中小企業の 7 割以上で存在</h3>
    <p>多くの企業で、退職時にまとまったお金がもらえる「退職金」制度が存在します。<a href="https://www.mhlw.go.jp/churoi/chousei/chingin/19/index.html" target="_blank" rel="noopener noreferrer">中央労働委員会の調査（2019）</a>によると従業員が 1,000 人以上の企業では 91％、<a href="https://www.sangyo-rodo.metro.tokyo.lg.jp/toukei/koyou/chingin/h30/" target="_blank" rel="noopener noreferrer">東京都労働相談情報センターの調査（2018）</a>によると 30〜299人の企業では 71％の割合です。</p>

    <h3>受取方法は 2 種類</h3>
    <p>退職金の受け取り方には、一時金として受け取る「一時金」方式と、毎月に分けて受け取る「年金」方式の二つがあります。年金・一時金どちらかを選べるか、決まっているのか、もしくはその二つを組み合わせて受け取れるのかは組織によって異なります。</p>

    <h3>受取総額は一時金より上</h3>
    <p>年金型は、一時金のように一括で大きな金額がもらえない分、受け取るまでは会社が運用を続けるため、その分の利益が上乗せされる可能性があります。</p>
    <p>例えば一時金だと 2,500 万円の退職金を、運用率 2％の「10 年確定年金」で受け取ると 250 万円ほど増える計算です。</p>

    <h3>手取総額は税金で損する可能性も</h3>
    <p>上記の例は、あくまでも税金を払う前の金額で、受取額や他の収入次第で、支払うべき税金が変わってきます。</p>
    <p>年金型による退職金の収入は「雑所得」となり、一時金の「退職所得」より税制上は不利な扱いです。具体的には所得税や住民税がかかったり、国民健康保険料や介護保険料が上がる可能性もあります。</p>
  </div>),
  // 退職一時金
  hasSeverancePay: (<div>
    <p>この項目は、退職金を一括でもらう以外にも、企業型の確定給付年金や確定拠出年金として一時金を受け取る場合も「あり」としてください。</p>

    <h3>制度は中小企業の 7 割以上で存在</h3>
    <p>多くの企業で、退職時にまとまったお金がもらえる「退職金」制度が存在します。<a href="https://www.mhlw.go.jp/churoi/chousei/chingin/19/index.html" target="_blank" rel="noopener noreferrer">中央労働委員会の調査（2019）</a>によると従業員が 1,000 人以上の企業では 91％、<a href="https://www.sangyo-rodo.metro.tokyo.lg.jp/toukei/koyou/chingin/h30/" target="_blank" rel="noopener noreferrer">東京都労働相談情報センターの調査（2018）</a>によると 30〜299人の企業では 71％の割合です。</p>

    <h3>受取方法は 2 種類</h3>
    <p>退職金の受け取り方には、一時金として受け取る「一時金」方式と、毎月に分けて受け取る「年金」方式の二つがあります。年金・一時金どちらかを選べるか、決まっているのか、もしくはその二つを組み合わせて受け取れるのかは組織によって異なります。</p>


    <h3>金額は企業規模で 2 倍の差</h3>
    <p>退職金としていくらもらえるのかは、大企業と中小企業で、大きく異なります。</p>

    <h4>大卒の相場</h4>
    <p>前述の調査によると、大学を卒業後、同じ会社に勤め続けて定年退職した場合のモデル退職金は以下のようになっています。</p>
    <table className={styles.vertical}>
      <tr><th>大企業</th><td>2,511 万円</td></tr>
      <tr><th>中小企業</th><td>1,203 万円</td></tr>
    </table>

    <h4>高卒の相場</h4>
    <p>前述の調査によると、高校を卒業後、同じ会社に勤め続けて定年退職した場合のモデル退職金は以下のようになっています。</p>
    <table className={styles.vertical}>
      <tr><th>大企業</th><td>2,379 万円</td></tr>
      <tr><th>中小企業</th><td>1,127 万円</td></tr>
    </table>


    <h3>勤続 15 年なら 600 万円までは無税</h3>
    <p>退職金は、支給額がそのままもらえるわけではありません。金額に応じ所得税と住民税を払わなくてはなりません。</p>

    <p>具体的には勤続年数が 1 年、増えるごとに 40 万円の控除（税金上の優遇）が受けられます。</p>
    <p>例えば退職時に同じ会社に 15 年間、勤続している方は 600 万円（40 万円 × 15 年）までは税金がかかりません。</p>
    <p>退職金が 1,000 万円なら、超えた 400 万円分（1,000 万円 - 600 万円）のうち、その半額である 200 万円（400 万円 ÷ 2）が所得税や住民税の課税対象となります。</p>
    <p>なお、勤続年数が 20 年を超えると、さらに優遇が受けられます。</p>
  </div>),
  // 時短期間の給与
  reducedWorkSalaryPercent: (<div>
    <p>子育てをしながら会社などに勤める際、1 日の労働時間を原則 6 時間とする<a href="https://www.mhlw.go.jp/bunya/koyoukintou/pamphlet/pdf/ikuji_h27_12.pdf#page=9" target="_blank" rel="noopener noreferrer">「育児短時間勤務」制度</a>（時短勤務）が使えます。</p>

    <h3>8 時間→6 時間なら基本給は 75％</h3>
    <p>
      時短勤務すると、もらえる給与はどうなるのでしょうか。</p><p>
      多くの企業では元々の「基本給」から時短勤務で働く時間分を算出して支払います。
      例えばフルタイム勤務が 8 時間の会社で、時短として 6 時間を勤務時間をすると、基本給は 75％になります。
</p>

    <h3>基本給以外は勤め先次第</h3>
    <p>基本給に加え手当の支給がある場合、その分の取り扱いは、以下のいずれかです。</p>
    <ul>
      <li>時短分がカット</li>
      <li>100％支給</li>
      <li>100％カット</li>
    </ul>
    <p>実際にどの対応になるかは、手当の性質や内容、就業規則における取り決めにより異なります。</p>
  </div>),
  // 子どもが生まれた後の働き方
  workAfterBirth: (<div>
    <p>大きく分けると以下の 3 パターンがあります。</p>
    <ul>
      <li>フルタイムで仕事を継続</li>
      <li>働き続けるが仕事をセーブ</li>
      <li>退職し子育てに専念</li>
    </ul>

    <h3>フルタイムで仕事を継続</h3>
    <p>配偶者がいる場合は、配偶者が子育て中心の生活を送るか、二人ともフルタイムで働いて可能な限り親族や外部の育児支援サービスを活用するかのどちらかになります。</p><p>仕事上のキャリアを継続できるため世帯収入の向上が見込めますが、同時に支出も増えることに注意が必要です。</p>

    <h3>働き続けるが仕事をセーブ</h3>
    <p>
      親族などからの金銭的なサポートがない限り、配偶者もフルタイムもしくは時短勤務など何らかの形で働く必要が出てくるでしょう。</p><p>
      世帯収入は、フルタイムで二馬力で稼ぐより減りますが、<a href="https://www8.cao.go.jp/shoushi/jidouteate/annai.html" target="_blank" rel="noopener noreferrer">児童手当</a>などを活用できます。
  </p>

    <h3>退職して子育てに専念</h3>
    <p>特殊な事情がない限りは、配偶者がフルタイムで働くことが想定されます。前の二つの選択肢より世帯収入は減るものの、自身で子育てを担当する分、かかる費用も抑えられます。</p>

    <h3>53％の女性が仕事を継続</h3>
    <p>90 年代までは、女性は働いていても妊娠・出産をきっかけに退職する傾向が強くありました。近年は育休制度などの充実により退職しない女性の割合は徐々に増えており、<a href="https://www.gender.go.jp/public/kyodosankaku/2019/201905/201905_02.html" target="_blank" rel="noopener noreferrer">内閣府の調査（2016）</a>では 53％の女性が出産後も仕事を続けています。
  </p>
  </div>),
  // 住宅ローン控除
  mortgageDeductionAmount: (<div>
    <p>10 年以上のローンを組んで自宅を買ったり大規模なリフォームを実施すると、毎年の所得税と住民税が安くなります。具体的には以下のいずれか低い方の金額が毎年、還元されます。</p>
    <ul>
      <li>年末時点でのローン残高の 1％</li>
      <li>消費税がかかる場合は 40 万円</li>
      <li>消費税がかからない場合は 20 万円</li>
    </ul>
    <p>「消費税がかかる・かからない」は主に新築か中古の違いです。消費税がかかる方が、新築となります。</p>
    <p>また、これは個人単位の減税のため、夫婦でローンを組む「ペアローン」を利用していると、それぞれが恩恵を受けられます。</p>

    <h3>減税期間は原則 10 年</h3>
    <p>
      制度の期間は<a href="http://sumai-kyufu.jp/outline/ju_loan/#article1" target="_blank" rel="noopener noreferrer">原則として 10 年間</a>です。
  2020 年 12 月までに、購入に消費税がかかった住まいに入居した場合は、特例として 13 年間まで伸びます。
</p>
    <p>また、長期に渡って住み続けられるような措置がある「長期優良住宅」や、環境に配慮し二酸化炭素の排出を抑制した「低炭素住宅」と認められる物件は追加で優遇を受けられます。</p>

    <h3>10 年間の減税の目安</h3>
    <p>
      実際いくら安くなるのかは、年収に応じて支払う所得税と住民税にも関係してくるため複雑になります。
      目安として、減税期間が 10 年間として累計で戻ってくる金額としては、およそ以下の通りです。
</p>
    <h4>年収 400 万円の場合</h4>
    <p>左辺が借りる住宅ローン額、右辺が 10 年間で受けられる減税額の目安です。</p>
    <table className={styles.vertical}>
      <tr><th>2,000 万円</th><td>162 万円</td></tr>
      <tr><th>2,500 万円</th><td>172 万円</td></tr>
      <tr><th>3,000 万円</th><td>172 万円</td></tr>
      <tr><th>3,500 万円</th><td>172 万円</td></tr>
    </table>

    <h4>年収 600 万円の場合</h4>
    <p>左辺が借りる住宅ローン額、右辺が 10 年間で受けられる減税額の目安です。</p>
    <table className={styles.vertical}>
      <tr><th>2,000 万円</th><td>168 万円</td></tr>
      <tr><th>2,500 万円</th><td>210 万円</td></tr>
      <tr><th>3,000 万円</th><td>251 万円</td></tr>
      <tr><th>3,500 万円</th><td>274 万円</td></tr>
    </table>

    <h4>年収 800 万円の場合</h4>
    <p>左辺が借りる住宅ローン額、右辺が 10 年間で受けられる減税額の目安です。</p>
    <table className={styles.vertical}>
      <tr><th>2,000 万円</th><td>168 万円</td></tr>
      <tr><th>2,500 万円</th><td>210 万円</td></tr>
      <tr><th>3,000 万円</th><td>252 万円</td></tr>
      <tr><th>3,500 万円</th><td>294 万円</td></tr>
    </table>

    <p>上記は、かなり簡略化した試算です。新築のモデルルーム会場などで、物件に応じた試算表がもらえることもあるので参考にしてください。</p>

    <h3>購入年度のみ確定申告が必須</h3>
    <p>控除を受けるためには、物件を買った初年度だけは<a href="https://www.nta.go.jp/taxes/shiraberu/shinkoku/tokushu/info-jyutakukoujo.htm" target="_blank" rel="noopener noreferrer">確定申告</a>が必要です。ローンを借りた金融機関から送られてくる「年末残高証明書」のほか、法務局から取り寄せられる「登記事項証明書」などが必要です。2 年目以降は、年末調整のみで手続きが終了します。</p>
  </div>),
  // 固定資産税
  propertyTax: (<div>
    <p>
      土地や建物など不動産を所有している人は毎年、固定資産税を払います。
      ここでは代表的な不動産である住宅で、税の額を説明してみましょう。
  </p>
    <p>固定資産税は土地の上に建つ「家屋」と、「土地」そのものを合計して計算します。</p>

    <h3>家屋の概算は購入額の 70％</h3>
    <p>家屋の固定資産税は「家屋の評価額 * 税率 1.4％」です。この「家屋の評価額」は計算が複雑なため、多くの場合「購入金額の 70％」という概算値が用いられます。</p>

    <h3>土地は公式情報から算出</h3>
    <p>土地の固定資産税も「土地の評価額 * 税率 1.4%」です。この「土地の評価額」は、国税庁が発表する<a href="https://www.rosenka.nta.go.jp/" target="_blank" rel="noopener noreferrer">路線価</a>を土地の面積を掛けた値となります。家屋よりは算出が容易です。</p>

    <h3>新築は中古より税金が優遇</h3>
    <p>例えば土地の評価額が 1,000 万円、家屋の評価額が 1,500 万円の戸建てを買うと、単純計算では 2,500 万円 * 1.4％＝35 万円が固定資産税額になります。</p>

    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>家屋</th><td>評価額 1,500 万円</td>
        </tr>
        <tr>
          <th>土地</th><td>評価額 1,000 万円</td>
        </tr>
        <tr>
          <th>新築</th><td>約 12 万円/年</td>
        </tr>
        <tr>
          <th>中古</th><td>約 23 万円/年<br /><small>（200 平米以下の場合）</small></td>
        </tr>
      </tbody>
    </table>
    <p>新築の場合は優遇施策として、土地と家屋それぞれ評価額が割り引かれ、実際は約 12 万円の負担になります。</p>
    <p>中古の場合は新築と違い、家屋の評価額は割り引きは受けられません。ただし、200 平方メートル以下の面積であれば土地の評価額は割り引かれるため、約 23 万円の負担になります。</p>
  </div>),
  // 相続
  inheritance: (<div>
    <h3>現金や土地以外に借金も対象</h3>
    <p>相続は預金や土地など金銭的に価値がある財産だけでなく、借金などの債務も相続の対象になります。</p>
    <ul>
      <li>現金や銀行預金</li>
      <li>株などの有価証券</li>
      <li>車、貴金属などの動産</li>
      <li>土地や建物などの不動産</li>
      <li>借金などの債務</li>
      <li>特許や著作権などの権利</li>
    </ul>
    <p>相続税の申告は、「本人が亡くなった」と相続する人が知った翌日から 10 か月以内に、原則として現金で納める決まりがあります。特に土地や建物などすぐに現金化できない財産を相続した場合は、相続税を見据えてあらかじめ準備しておくことが必要です。</p>

    <h3>放棄するなら 3 か月内に手続</h3>
    <p>相続は放棄も可能です。ただし、一部だけを放棄することはできません。つまり、預金や土地だけを相続し、借金は放棄、ということは不可となっています。ただし、亡くなった人の財産や借金が不明な場合は、受け継いだ財産を限度として債務も受け継ぐということも選択できます。</p>
    <p>放棄したい場合は、相続を知った日から 3 か月以内に家庭裁判所へ申述書の提出が必要です。</p>

    <h3>遺言書で本人の意志を優先</h3>
    <p>亡くなった人の遺言書がある場合は、それに沿って相続します。ない場合は、民法で「誰がどれだけ相続するか」が決められているので、それに従い相続します。</p><p>具体的な取り分は、相続する人数などによって変わります。大まかな優先順位は以下のようになっています。
</p>
    <ol>
      <li>配偶者</li>
      <li>子ども（孫）</li>
      <li>両親</li>
      <li>兄弟姉妹</li>
    </ol>

  </div>),
  // 死去
  funeralCost: (<div>
    <p>亡くなった際、かかるお金は大きく「葬儀」と「お墓」の二つです。一生黒字プランでは、以下の平均値および今後は価格が低下傾向になると予測し、一人あたりの合計値は 250 万円を標準値としています。</p>
    <h3>葬儀は平均 200 万円弱</h3>
    <h4>簡易なら 50 万以下も可能</h4>
    <p>
      <a href="https://jca-home.jp/news/%E3%80%8C%E7%AC%AC11%E5%9B%9E-%E8%91%AC%E5%84%80%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E8%AA%BF%E6%9F%BB%E5%A0%B1%E5%91%8A%E6%9B%B8%E3%80%8D%E7%99%BA/" target="_blank" rel="noopener noreferrer">日本消費者協会の調査</a>によると、葬儀にかかる費用は平均して 200 万円弱です。民間の調査では約 170 万円前後という結果が出ています。これには葬儀そのものの費用に加え、返礼品や飲食費なども含まれたものです。
</p><p>
      近年では、お通夜を省略する「一日葬」や、通夜式と告別式を省いて親しい数名で火葬のみ執り行う「直葬」など、さまざまな選択肢が増えています。
      簡略化すれば 50 万円以下に収めることも可能です。
</p>
    <h4>申請すれば 3〜7 万円の手当支給</h4>
    <p>また、国民健康保険や会社の健康保険に加入していた場合は、葬儀から 2 年以内に申請すると 3〜7 万円の手当の支給が受けられます。</p>
    <p>国民健康保険の加入者は市・区役所の保健年金課、健康保険の加入者は全国健康保険協会にお問い合わせください。</p>

    <h3>お墓は平均 167 万円</h3>
    <p><a href="https://www.info-ginza.com/zenyuseki/2017/07user/user.pdf" target="_blank" rel="noopener noreferrer">全国優良石材の会のアンケート調査</a>によると、お墓の建立費用は全国平均で約 167 万円です。地域差もあり、最も高いのは九州で約 222 万円、最も安いのは北海道で約 134 万円です。</p>
    <p>また近年では、個別のお墓を立てずにまとめて納骨する納骨堂（約 50 万円）や合同墓（約 20 万円）を選択する方が増えています。</p>

    <h3>法要は一回につき 1〜5 万円</h3>
    <p>亡くなった後は、定期的に法要を営みます。仏教においては亡くなってから数えて一周忌・三回忌・七回忌・十三回忌などを「年忌法要」といいます。三十三回忌または五十回忌を「弔い上げ」とし法要を締めくくるところが多くなっています。</p>
    <p>こうした法要には、執り行う僧侶へのお布施として 1〜5 万円程度がかかります。</p>


  </div>),
  // 完全リタイアする年齢
  retirementAge: (<div>
    <h3>65 歳リタイアが最多</h3>
    <p>「再雇用を開始する年齢」の説明にある通り、お勤めの方の場合は「定年後再雇用制度」を用いて 65 歳まで働けるようになっています。</p>
    <p>こうした背景もあり、総務省の労働力調査によると、リタイアするタイミングとして最も多いのは「65 歳」という結果が出ています。</p>
    <h3>70 歳以降も働く人は約 4 割</h3>
    <p>
      この年齢は時代とともに伸びており、70 歳以降も働いている方は 4 割弱に上っています。
      今後も平均寿命が延伸するにともない 75 歳位までが「現役」になる可能性もあります。
    </p>
  </div>),

// 職業
  jobCategory: (<div>
    <h3>新規学卒就職者の約 4 割が 3 年位内に離職</h3>
    <p>厚生労働省が発表する <a href="https://www.mhlw.go.jp/stf/houdou/0000177553_00003.html" target="_blank" rel="noopener noreferrer">新規学卒就職者の離職状況</a> によると、大学や短大など新しく学校を卒業した人のうち、39.5％が 3 年位内に会社を辞めていました。新規大卒就職者に限ると 32.8%と、少し低くなります。</p>
    <p>なお職業からの生涯年収の計算には転職サービス doda の<a href="https://doda.jp/guide/heikin/syokusyu/" target="_blank" rel="noopener noreferrer">平均年収ランキング</a>および<a href="https://www.e-stat.go.jp/stat-search/files?page=1&layout=dataset&toukei=00020312&bunya_l=13&stat_infid=000031751648" target="_blank" rel="noopener noreferrer">国家公務員給与等実態調査</a>、総務省の<a href="https://www.soumu.go.jp/main_sosiki/jichi_gyousei/c-gyousei/kyuuyo/h31_kyuuyo_1.html" target="_blank" rel="noopener noreferrer">地方公務員給与実態調査</a>などをもとに Zaim が独自に推計したものとなります。</p>
  </div>),

  // 子の誕生からの時短
  reducedWorkYears: (<div>
    <p>
      育児を理由に本来よりも短い勤務時間を選択できる「時短勤務」制度は、国の法律で<a href="https://www.mhlw.go.jp/bunya/koyoukintou/pamphlet/pdf/ikuji_h27_12.pdf#page=9" target="_blank" rel="noopener noreferrer">最長で子が 3 歳になるまで</a>利用できます。
  会社によっては、それ以上の年齢でも時短で働ける制度を設けている場合もあります。</p>
    <h3>時短は子が 1 歳半までが最多</h3>
    <p>時短勤務が実際、どれほど取得されているかというと <a href="https://www.mhlw.go.jp/file/06-Seisakujouhou-11900000-Koyoukintoujidoukateikyoku/0000174274.pdf#page=20" target="_blank" rel="noopener noreferrer">三菱 UFJ
 リサーチ&amp;コンサルティング</a>の発表によると「3 歳未満」が最も多く約 4 分の 1、次いで「2 歳未満」が約 1 割となっています。
</p><p>また正社員人数が 301 人以上の企業に限ると、「小学校 ３ 年生まで」が最も多く 1 割以上を占めています。</p>

    <h3>雇用開始後 1 年未満は不可</h3>
    <p>ただし、すべての労働者が時短勤務できるかというと、そうではありません。例えば以下のような条件が課せられています。</p>
    <ul>
      <li>通常時の 1 日あたりの労働時間が 6 時間以上</li>
      <li>雇用されてから 1 日以上が経過</li>
      <li>労働日数が 1 週間に 3 日以上</li>
    </ul>
    <p>また、時間に縛られる契約を持たない個人事業主や会社の役員は対象外となります。</p>
  </div>),
  // 自動車保険
  insuranceRepayment: (<div>
    <p>自動車に関連する保険は「自賠責保険」と「任意保険」の 2 種類があります。</p>
    <h3>自賠責保険は強制加入</h3>
    <p>自賠責保険は、自動車ごとに加入が義務付けられている強制保険で、他人を巻き込んで事故を起こした場合に、その相手を救済するためのものです。
      支払限度は、被害者一人ごとに死亡で最高 3,000 万円、後遺障害で最高 4,000 万円となっています。</p>

    <p>自賠責保険の費用は、車両の種類によって異なります。3 年契約の金額は、以下となっています。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>乗用自動車</th><td>2 万 1,550円</td>
        </tr>
        <tr>
          <th>軽自動車</th><td>2 万 1,140円</td>
        </tr>
        <tr>
          <th>小型二輪</th><td>9,680円</td>
        </tr>
        <tr>
          <th>原付自転車</th><td>	8,950円</td>
        </tr>
      </tbody>
    </table>
    <h3>任意保険</h3>
    <p>一方、任意自動車保険は、その名の通り加入は強制されいないものの、自賠責保険だけでは賄えない保障を追加するための保険です。</p>
    <p>例えば交通事故を起こして他人を死亡させ億単位の賠償金を請求されると、自賠責保険の上限 3,000 万円では賄えない可能性があります。</p>
    <p>
      保険料は、運転者の年齢や車種、保険会社によって大きく異なります。
      普段、あまり車を運転せず事故を起こしたことのない方であれば、年 1 万円程度に抑えることも可能です。
      逆に、免許を取ったばかりでスポーツカーに乗るような方だと、年 10 万円以上になることもあり得ます。
    </p>
    <p>保険期間は 1 年〜3 年の場合が多く、その都度、更新が必要です。</p>
  </div>),
  // 車検
  inspectionFee: (<div>
    <h3></h3>
    <p>車検は、自動車を保有していたら、必ず受けなくてはならない定期点検です。新車を購入したら、その 3 年後、それ以降は 2 年ごとに車検が必要です。</p>
    <p>その際、支払うのは「法定費用」と「点検費用」です。法定費用はどこの業者も同じ価格ですが、点検費用は異なります。</p>

    <h3>法定費用は最安で年 1.3 万円</h3>
    <p>法定費用には「自賠責保険」と「自動車重量税」、「印紙代」が含まれます。
      自賠責保険は 2 年分、自動車重量税は 1〜3 年分、印紙代は検査ごとに支払います。</p>
    <h4>自賠責保険</h4>
    <p>普通自動車だと、2 年分で 2 万 5,830 円、軽自動車では 2 年分で 2 万 5,070 円です。</p>
    <h4>自動車重量税</h4>
    <p>金額は車種や年数、排気量によって異なり、年数が新しく排気量が少ないほど安くなります。</p>
    <p>また、最近の新車は「エコカー減税」の対象になっている車種が多く、その場合はゼロ円で済みます。</p>
    <p>それ以外では、例えば 13 年を経過した軽自動車は 2 年で 6,600 円、普通自動車（排気量 2,000cc）は 2 年で 3 万 2,800 円ほどかかります。</p>

    <h3>点検費用は 1〜6 万円</h3>
    <p>点検費用は、車検を頼む先によって変わります。
    自動車メーカーの販売店（カーディーラー）は高く、車検専門業者やガソリンスタンドであれば安くなり、
    自動車の排気量が大きいほど高くなる傾向があります。いずれにしても、点検そのものの費用感はおよそ 1 万〜6 万円の範囲です。これに実際に必要になった修理の料金が加わります。
    </p>
    <p>なお、業者に頼まず自身で運輸局に持ち込む「ユーザー車検」であれば、手間がかかりますが費用は数千円程度で済みます。その場合も、法定費用の準備は必須です。</p>
  </div>),
  // 住まいの更新料
  rentRenewalFee: (<div>
    <h3>地域によって有無</h3>
    <p>
      家賃の契約は 2 年に一度のサイクルで更新する形が一般的です。
  更新料とは、その時にかかる費用で、実は厳密な決まりはありません。このため、更新料の有無や金額は地域などによって異なります。</p>
    <p>例えば東京都や京都では、半数の物件に更新料が設定されています。また千葉県や埼玉県では、ほぼすべての物件が対象です。</p>
    <p>一方、大阪県や兵庫県では、ほとんどありません。</p>
    <h3>家賃 1 か月分が目安</h3>
    <p>首都圏では、更新料は家賃の 1 か月分と同額のケースが多くあります。
その他の地域では金額が下がり、北海道では 1 割ほどです。</p>
  </div>),
  // 引っ越し
  movingCost: (<div>
    <h3>8 月・12 月が狙い目</h3>
    <p>引越費用は、時期によって大きく変動します。具体的には 3 月が最も高く次いで 4 月、逆に 8 月・12 月ごろが最も安くなる傾向があります。</p>
    <h3>平日午後が最安</h3>
    <p>そのほか移動距離や荷物の量、曜日によっても変わります。</p>
    <ul>
      <li>平日と休日では平日が安い</li>
      <li>午前と午後では午後が安い</li>
      <li>荷物は少ないほど安い</li>
      <li>移動距離は短いほど安い</li>
    </ul>
    <p>例えば<a href="https://hikkoshi.suumo.jp/oyakudachi/788.html" target="_blank" rel="noopener noreferrer">「SUUMO 引越し見積もり」口コミデータ（2018）</a>によると、
    3 人家族が引っ越す場合、8 月であれば約 9 万円のところ、3 月だと 15 万円以上かかります。</p>
  </div>),
  // 受給を始める年齢
  pensionBenefitAge: (<div>
    <h3>通常は 65 歳から受給</h3>
    <p>老後にもらえる公的な年金は、主に「老齢基礎年金」と「老齢厚生年金」の二つがあります。いずれも原則として、65 歳になると支給が開始します。</p>
    <p>老齢基礎年金は、単に「基礎年金」と呼ばれてることもあります。満額なら、月 5.6 万円もらえます。</p>
    <p>一方、老齢厚生年金は働いていた際の所得により変動します。<a href="https://www.mhlw.go.jp/content/000578278.pdf" target="_blank" rel="noopener noreferrer">厚生労働省の調査</a>によると、月 15 万円前後が平均です。</p>
    <p>現時点での受け取り見込み額は<a href="https://www.nenkin.go.jp/n_net/" target="_blank" rel="noopener noreferrer">ねんきんネット</a>で確認できます。</p>

    <h3>受給を始める年齢は変更可</h3>
    <p>
      生活費が足りない場合は 60 歳からであれば、老齢基礎年金・老齢厚生年金いずれも受給を前倒しできます。
      その代わり、受け取れる金額は減ってしまいます。具体的には 1 か月分を前倒すごとに -0.5％になります。
  </p>
    <p>一方、余裕がある場合は受給を遅らせることも可能です。
    受け取れる金額は、1 か月分を後倒すごとに +0.7％になります。
  </p>
    <p>
      例えば 65 歳で月 15 万 3,480 円を受給できる人が「60 歳から受給」「70 歳から受給」に切り替えると、月の受給額は以下のようになります。
</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>60 歳</th><td>10 万 7,436 円</td>
        </tr>
        <tr>
          <th>65 歳</th><td>15 万 3,480 円</td>
        </tr>
        <tr>
          <th>70 歳</th><td>21 万 7,943 円</td>
        </tr>
      </tbody>
    </table>

  </div>),
  // 奨学金
  useScholarship: (<div>
    <h3>奨学金を受給する大学生は 49％</h3>
    <p>日本学生支援機構の「学生生活調査」（2016）によると、奨学金を受給している大学生は約半数です。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>大学</th><td>48.9％</td>
        </tr>
        <tr>
          <th>修士課程</th><td>51.8％</td>
        </tr>
        <tr>
          <th>博士課程</th><td>56.9％</td>
        </tr>
      </tbody>
    </table>
    <p>その際に利用できる奨学金は、以下の 4 種類があります。</p>
    <h3>日本学生支援機構</h3>
    <p>日本学生支援機構の奨学金は、返済の義務がある「貸与型」と返済の義務がない「給付型」に分かれます。</p>
    <p>貸与型はさらに、利息のつかない「一種」と卒業後に利息が発生する「二種」があり、さらに本人の学力や世帯の収入、通学が自宅からかによって貸与を受けられる金額が変わります。</p>
    <h4>貸与型・利子なし（第一種）</h4>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>国公立</th><td>2〜5.1 万円</td>
        </tr>
        <tr>
          <th>私立</th><td>2〜6.4 万円</td>
        </tr>
      </tbody>
    </table>
    <h4>貸与型・利子あり（第二種）</h4>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>共通</th><td>2〜12 万円</td>
        </tr>
      </tbody>
    </table>
    <p>利子ありの場合は、私立の医歯学であれば 4 万円の増額が可能。その利子は卒業後から発生し、元金は 20 年以内に返済する必要があります。</p>
    <h4>給付型</h4>
    <p>
      受給したお金を返す必要がない給付型は、貸与型よりも学力や世帯の収入において、厳しい条件が課されます。
      その代わり授業料や入学金の一部が免除されたり、毎月 1.3 万円〜7.6 万円の給付を受けられたりするなど、サポートが手厚くなっています。
    </p>

    <h3>学校独自の奨学金</h3>
    <p>
      大学などが独自に設けた奨学金制度が存在する場合もあります。この奨学金は、「特待生」と呼ばれることも。
      例えば慶應義塾大学では、学部生の約 300 名に対し 50 万円もしくは 25 万円を返済不要で給付する「慶應義塾大学給費奨学金」などを用意しています。
    </p>
    <p>主だったものは、<a href="https://www.jasso.go.jp/about/statistics/shogaku_dantaiseido/index.html" target="_blank" rel="noopener noreferrer">日本学生支援機構の検索サイト</a>で条件を指定して探せるようになっています。</p>
    <h3>その他の奨学金</h3>
    <p>地方自治体や公益法人が運営する奨学金も存在します。ただし、募集は若干名であるものがほとんど。
      主だったものは、<a href="https://www.jasso.go.jp/about/statistics/shogaku_dantaiseido/index.html" target="_blank" rel="noopener noreferrer">日本学生支援機構の検索サイト</a>で条件を指定して探せるようになっています。
    </p>
  </div>),
  // 日常費
  monthlyLivingCost: (<div>
    <p>日常費には、以下のように「毎月、日常的に支払うもの」の合計値を入れてください。</p>
    <ul>
      <li>食費</li>
      <li>日用雑貨費</li>
      <li>医療費</li>
      <li>水道光熱費</li>
      <li>通信費</li>
      <li>交際費</li>
      <li>交通費</li>
    </ul>
    <p>以下はそれぞれ専用画面で入力できます。日常費には含めず、そちらにて設定ください。</p>
    <ul>
      <li>保険料</li>
      <li>子どもの教育</li>
      <li>住まい（住宅ローン・家賃・管理費）</li>
      <li>クルマ（カーローン・駐車場）</li>
      <li>臨時支出（旅行や家電の購入など）</li>
    </ul>
  </div>),
  // 育休
  childcareLeaveMonths: (<div>
    <h3>女性は 6 か月以上が大半</h3>
    <p>
      厚生労働省の「雇用均等基本調査」によると女性の 82％が出産後に育児休業を取得しており、
      その期間は約 9 割が 6 か月以上となっています。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>〜3 か月</th><td>3.9％</td>
        </tr>
        <tr>
          <th>3 か月〜</th><td>7.9％</td>
        </tr>
        <tr>
          <th>6 か月〜</th><td>22.9％</td>
        </tr>
        <tr>
          <th>10 か月〜</th><td>31.1％</td>
        </tr>
        <tr>
          <th>12 か月〜</th><td>27.6％</td>
        </tr>
        <tr>
          <th>18 か月〜</th><td>6.6％</td>
        </tr>
      </tbody>
    </table>
    <h3>男性の取得率は急上昇</h3>
    <p>一方、育児休業を取った男性は 7.5％と、まだまだ低い状態です。しかし、ここ数年では急激に伸びています。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>2019 年</th><td>7.5％</td>
        </tr>
        <tr>
          <th>2017 年</th><td>5.1％</td>
        </tr>
        <tr>
          <th>2015 年</th><td>2.7％</td>
        </tr>
        <tr>
          <th>2013 年</th><td>2％</td>
        </tr>
      </tbody>
    </table>
    <p>ただし、その取得期間は 5 日以内が半数以上と、女性と大きな差があります。</p>
    <p>
      男性・女性を問わず会社などに勤めている方であれば、育児休業中は給与の代わりに育児休業給付金（育休手当）を受け取れます。
      最初の半年間は以前の収入の 3 分の 2、それ以降は半分が支給されます。
    </p>
    <h3>自営業の保障はなし</h3>
    <p>会社などに勤めていない自営業の方には、残念ながら育児休業にあたる制度がなく、育児休業給付金も受け取れません。
    自身で休む期間を決め、仕事をやりくりする必要があります。
    </p>
  </div>),
  // 学資保険
  studentInsurance: (
    <div>
      <h3>死亡保険＋貯蓄型の保険</h3>
      <p>学資保険は、死亡保険と貯蓄機能がセットになった保険です。子どもの教育にかかる資金として積み立てると同時に、
      契約者（親）が亡くなった場合は以降の保険料の支払いが免除され、かつ満期時に学資金がそのまま受け取れる
        仕組みを持っています。</p>
      <p>
        中学や高校の入学時に祝金が受け取れ、大学入学時に満期金が受け取れるタイプのものが一般的です。
      </p>
      <p>
        総額で受け取れる金額は、一般的に学資保険として払った総額と同額か、少し多い額となります。
        ただし、子どもの医療保障などを保険に付帯した場合は、払った総額以下となる場合もあります。</p>
      <h3>メリット</h3>
      <ul>
        <li>親が亡くなっても教育資金の一部を用意できる</li>
        <li>コツコツ貯めるのが苦手でも半強制的に貯蓄できる</li>
        <li>税の支払いに優遇がある</li>
      </ul>
      <h3>デメリット</h3>
      <ul>
        <li>途中で解約すると、受け取れる金額が支払った保険料の総額より低くなり、損してしまう</li>
        <li>物価が上がる「インフレ」になると、満期時に受け取れる金額が目減りする</li>
      </ul>
    </div>
  ),
  // 仕送り
  sendRemittance: (<div>
    <h3>月の平均は 7.3 万円</h3>
    <p>
      家から離れた大学などに進学した場合、親が生活費の何割かを仕送りする家庭も多いでしょう。
        <a href="https://www.univcoop.or.jp/press/life/report.html" target="_blank" rel="noopener noreferrer">全国大学生活協同組合連合会が行ったアンケート（2020）</a>
        によると、一人暮らしまたは下宿する大学生の月の生活費は 12.9 万円、親からの仕送りは 7.3 万円が平均でした。
      </p>
    <p>ただし、以下の月の仕送り額の分布で明らかなように、家庭ごとの経済状況や教育方針によって大きな差があります。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>なし</th><td>7.1％</td>
        </tr>
        <tr>
          <th>〜5 万円</th><td> 16.3％</td>
        </tr>
        <tr>
          <th>5〜10 万円</th><td> 33.4％</td>
        </tr>
        <tr>
          <th>10 万円〜</th><td> 27.9％</td>
        </tr>
      </tbody>
    </table>
  </div>),
  // インフレ
  InflationRate: (<div>
    <h3>インフレ率＝物価の値段の上昇度</h3>
    <p>インフレ率とは、<strong>1 年前に比べ物価の値段がどの程度、上昇したか</strong> の割合を指します。例えばインフレ率 1％の場合、今年 100 円だった商品の価格は以下のようになっていくイメージです。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>今年</th>
          <td>100 円</td>
        </tr>
        <tr>
          <th>1 年後</th>
          <td>101 円</td>
        </tr>
        <tr>
          <th>2 年後</th>
          <td>102.01 円</td>
        </tr>
        <tr>
          <th>3 年後</th>
          <td>103.03 円</td>
        </tr>
      </tbody>
    </table>
    <h3>日本</h3>
    <p>経済として健全なインフレ率は 2〜3%と言われており、日本では 2013 年から 2％を目標としています。</p>
    <p>しかし実際は、ほぼ横ばいの状態が続いており、日本の平均インフレ率は <strong>2010〜2019 年の 10 年間で 0.5％、2000〜2019 年の 20 年間で 0.1％</strong> です。</p>
    <p>なお一生黒字プランでは、生活費を始めとする支出、および収入にもインフレ率が影響するようになっています。</p>
  </div>),
  // 一般 NISA
  nisa: {
    default: (<div>
      <h3>利益にかかる 20％の税金が免除</h3>
      <p>
        一般 NISA は、国が推進する投資の仕組みです。
  毎年 120 万円を上限に、株式投資などで得た利益や受け取った配当に対し、税金の免除が受けられます。</p>
      <p>メリットは、一般 NISA で投資した場合と、そうではない場合で比較すると分かりやすいでしょう。</p><p>
        例えば一般 NISA ではない形で上場企業の株を 100 万円分購入し、これを 120 万円で売却した場合は 20 万円が儲かります。
    しかし、以下の表のように、そのうち 20.315％にあたる 4,063 円は税金として支払う必要があります。</p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>株の購入額</th>
            <td>100 万円</td>
          </tr>
          <tr>
            <th>株の売却額</th>
            <td>120 万円</td>
          </tr>
          <tr>
            <th>利益</th>
            <td>20 万円</td>
          </tr>
          <tr>
            <th>課税</th>
            <td>4,063 円</td>
          </tr>
          <tr>
            <th>残る金額</th>
            <td>19 万 5,937 円</td>
          </tr>
        </tbody>
      </table>
      <p>一般 NISA として株を買うと、<strong>この税金 4,063 円を払う必要がなく（非課税）、20 万円がそのまま受け取れます。</strong></p>
      <p>
        一般 NISA は 20 歳以上の国内居住の方なら、どなたでも加入できます。
        また、一般 NISA として「投資できる枠」が年間 120 万円までなので、投資にお金を回したくないタイミングであれば無理に投資する必要はありません。
    </p>
      <h3>つみたて NISA と一般 NISA の違い</h3>
      <p>「NISA」には「つみたて NISA」と「一般 NISA」の 2 種類があ、
    「つみたて NISA」との併用はできません。つみたて NISA に切り替える場合は、変更の申込みが必要になります。</p>
      <p>ここでは、つみたて NISA と比較した一般 NISA の特徴を説明しましょう。</p>
      <h4>株式投資にも対応</h4>
      <p>つみたて NISA で投資できる先は、主に投資信託です。一方、一般 NISA は上場している会社ごとの株も購入できます。</p>

      <h4>税の支払い免除は 5 年間</h4>
      <p>つみたて NISA では、税の支払い免除（非課税）は、投資した年から最長で 20 年間までです。一方、一般 NISA は 5 年間。5 年を超えたまま保有する株式や投資信託がある場合は、以下のどれかを選択できます。</p>
      <ul>
        <li>売却して手放す</li>
        <li>課税口座に移す</li>
        <li>翌年の一般 NISA 非課税枠に移す</li>
      </ul>
      <p>
        最後の「翌年の一般 NISA 非課税枠に移す」ことを「ロールオーバー」と言います。
        5 年間、毎年 120 万円ずつの非課税枠がもらえるので、その枠に移動させるのです。
    </p>
      <h4>2023 年からは「新 NISA」へ</h4>
      <p>実は、この一般 NISA の仕組みは 2023 年で終了します。2024 年からは以下のような「新 NISA」が、代わりに使えるようになります。</p>
      <ul>
        <li>年 20 万円までの「つみたて NISA」</li>
        <li>年 102 万円までの「一般 NISA」</li>
      </ul>

      <h3>どちらの &quot;NISA&quot; が良い？</h3>
      <p>
        一般 NISA とつみたて NISA、どちらが良いかは投資スタイル次第といえます。
          </p>
      <dl>
        <dt>一般 NISA</dt>
        <dd>
          個別の会社の株に投資したい<br />
              利益が出たら売却することもある<br />
              投資規模は年間 120 万円
            </dd>
        <dt>つみたて NISA</dt>
        <dd>
          主に投資信託に投資する<br />
              中長期の資産形成を目的とする<br />
              投資規模は年間 40 万円以内
            </dd>
      </dl>
      <p>
        ただし前述のとおり、2023 年から一般 NISA は、新しい NISA の仕組みに生まれ変わります。
        これは、一般 NISA とつみたて NISA を一体化したようなものが予定されています。
          </p>
      <h3>証券口座の開設が必要</h3>
      <p>
        一般 NISA を始めるには、まず銀行や証券会社で「一般 NISA の口座」を解説する必要があります。
        通常の証券口座とは異なり、一般 NISA 口座は一人につき一つしか作れません。
        </p>
      <p>
        インターネットで一般 NISA の口座が作れる大手としては、以下のような証券会社があります。
        </p>
      <ul>
        <li>楽天証券</li>
        <li>SBI 証券</li>
      </ul>

    </div>
    ),
  },
  tsumitateNisa: {
    default: (<div>
      <h3>利益にかかる 20％の税金が免除</h3>
      <p>
        つみたて NISA は、国が推進する投資の仕組みです。
        毎年 40 万円を上限に、対象となる投資信託などを継続的に購入することで、利益にかかる税金の免除が受けられます。
        </p>
      <p>メリットは、つみたて NISA を使った場合と使わなかった場合で比較すると分かりやすいでしょう。例えば毎月、つみたて NISA を使わず投資信託を積み立て、累計 800 万円を払い込み、これを 1,000 万円で売却した場合は 200 万円が儲かります。しかし、以下の表のように、そのうち 20.315％にあたる 40 万 6,300 円は、税金として支払う必要があります。</p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>積立累計額</th>
            <td>800 万円</td>
          </tr>
          <tr>
            <th>売却額</th>
            <td>1,000 万円</td>
          </tr>
          <tr>
            <th>利益</th>
            <td>200 万円</td>
          </tr>
          <tr>
            <th>課税</th>
            <td>40 万 6,300 円</td>
          </tr>
          <tr>
            <th>残る金額</th>
            <td>159 万 3,700 円</td>
          </tr>
        </tbody>
      </table>
      <p>つみたて NISA として積み立てておくと、<strong>この税金 40 万 6,300 円を払う必要がなく、200 万円がそのまま受け取れます。</strong></p>
      <p>
        つみたて NISA は 20 歳以上の国内居住の方なら、どなたでも加入できます。
        また、辞めたくなったら、いつでも積立を停止したり、購入した商品を売却することも可能です。
        </p>
      <h3>投資信託などを自身で選択</h3>
      <p>
        つみたて NISA による投資といっても「つみたて NISA」という投資先があるわけではありません。
        実際には、つみたて NISA に対応した投資信託や定期預金を積み立てることになります。
        この投資先は、つみたて NISA 向けの口座を開設する証券会社によって異なります。
        </p>
      <h3>積立金額や期間に条件</h3>
      <p>一方で、通常の投資とは異なる制限もあります。</p>
      <h4>積立は毎年 40 万円が上限</h4>
      <p>
        投資できる金額は、毎年 40 万円までとなっています。投資は月ごとなので、毎月 3 万 3,333 円までです。
        これを上限に「つみたて NISA の対象」として認定を受けている投資信託などの商品を、好きな割合で組み合わせられます。
        なお、投資先の割り振りや金額は随時、変更が可能です。
        </p>

      <h4>期間は 20 年</h4>
      <p>非課税のまま売却できる最長期間は 20 年という制限があります。</p>

      <h4>一般 NISA との併用は不可</h4>
      <p>「一般 NISA」という別の仕組みとの併用はできません。一般 NISA に切り替える場合は、変更の申込みが必要になります。
      ややこしいですが、一般 NISA は単に「NISA」と呼ばれることもあります。
        </p>

      <h3>どちらの &quot;NISA&quot; が良い？</h3>
      <p>
        一般 NISA とつみたて NISA、どちらが良いかは投資スタイル次第といえます。
          </p>
      <dl>
        <dt>一般 NISA</dt>
        <dd>
          個別の会社の株に投資したい<br />
              利益が出たら売却することもある<br />
              投資規模は年間 120 万円
            </dd>
        <dt>つみたて NISA</dt>
        <dd>
          主に投資信託に投資する<br />
              中長期の資産形成を目的とする<br />
              投資規模は年間 40 万円以内
            </dd>
      </dl>
      <p>
        ただし、2023 年から一般 NISA は、新しい NISA の仕組みに生まれ変わります。
        これは、一般 NISA とつみたて NISA を一体化したようなものが予定されています。
          </p>

      <h3>証券口座の開設が必要</h3>
      <p>
        つみたて NISA を始めるには、まず銀行や証券会社で「つみたて NISA の口座」を解説する必要があります。
        通常の証券口座とは異なり、つみたて NISA 口座は一人につき一つしか作れません。
        </p>
      <p>
        インターネットでつみたて NISA の口座が作れる大手としては、以下のような証券会社があります。
        </p>
      <ul>
        <li>楽天証券</li>
        <li>SBI 証券</li>
      </ul>
    </div>),
  },
  periodicInvestments: {
    default: (<div>
      <h3>「プロ任せ」の資産運用</h3>
      <p>
        投資信託とは、簡単に言うと「資産運用をプロに任せる仕組み」で、
        ある特定の会社の株を自身で購入する「株式」の投資とは異なり、
        プロが選定した多種多様の会社の株や不動産などを組み合わせた「商品」を購入するスタイルの投資です。
        この商品は「ファンド」「銘柄」とも呼ばれます。
        </p>
      <h3>証券口座の開設が必要</h3>
      <p>
        投資信託を始めるには、銀行口座を開くように「証券会社」に口座を作り、そこから投資信託の商品を購入します。
        インターネットで証券口座が作れる大手としては、以下のような証券会社があります。
        </p>
      <ul>
        <li>楽天証券</li>
        <li>SBI 証券</li>
      </ul>
      <p>商品の価格は、世界や国内の景気の影響を受け、随時、値段が変わります。
      その値動きをチェックして、ご自身の判断で好きなときに買ったり売ったりすることが可能です。
        </p>
      <h3>手間が少なく手軽な「積立」</h3>
      <p>
        とはいえ「毎日、投資のことを考えていられるわけではない」「売買の手間が面倒」という方も多いでしょう。
        その場合は、投資信託の「積立」がオススメです。毎月・毎日など決まったルールで、特定の商品を買い続けます。
          </p><p>
        また、積立を辞めたくなったり売却したくなったら、いつでも辞められるようになっています。
        </p>
      <p>
        投資信託は、手軽に始められるよう多くの商品が 1 万円程度から買えます。中には、100 円や 1,000 円で購入できるものもあります。
        </p><p>
        ただし多くの商品では、運用を続けていくための手数料が発生します。購入金額のうち、年間で 0.2％〜2.0％程度です。
        </p>
      <h3>利益は分配金・売却益の 2 種類</h3>
      <p>
        投資信託で生じる利益は、運用で得た利益が購入者に分け与えられる「分配金」と、購入者自身が商品自体を売る「売却益」の 2 種類があります。</p>
      <p>
        このうち分配金は、そのまま受け取る以外に、同じ投資信託の中で再投資に回すことも可能です。
        受け取ると、その利益の 20％ほどの税金が課されますが、再投資すると課税は持ち越しになります。
        </p>
      <h3>利益の約 20％が税金</h3>
      <p>
        投資信託を売却したり、分配金を受け取ったりした場合は、その利益分に 20.315％の税金がかかります。</p><p>
        例えば以下のように、とある投資信託を 10 万円購入し、2 万円の利益が出た場合は 4,063 円が課税され、手元に 115,937 円が残ります。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>購入価格</th>
            <td>100,000 円</td>
          </tr>
          <tr>
            <th>売却価格</th>
            <td>120,000 円</td>
          </tr>
          <tr>
            <th>利益</th>
            <td>20,000 円</td>
          </tr>
          <tr>
            <th>課税</th>
            <td>4,063 円</td>
          </tr>
          <tr>
            <th>残る金額</th>
            <td>15,937 円</td>
          </tr>
        </tbody>
      </table>
      <p>
        なお投資信託を買う際、口座を「一般口座」ではなく「源泉ありの特定口座」を指定しておくと、証券会社が税金を計算し、利益から差し引いてくれます。
    </p>
      <p>
        また投資信託といえども、景気変動や運用の方法によっては価値が下がる可能性もあります。
        リスクを十分に考慮したうえで、生活に影響が出ない余剰資金の一部から始めてみましょう。
        </p>
    </div>),
  },
  // 企業型確定拠出年金
  dc: {
    default: (<div>
      <p>
        企業型確定拠出年金（企業型 DC）は、老後に備えるための企業の制度です。</p><p>
        毎月、給与などから差し引かれて通常 65 歳から受け取れる「公的」年金とは別に、
        企業が従業員一人ひとりに対して一定額（掛け金）を積み立て、60 歳になったら売却できる「企業型」の年金を指します。
      </p>
      <h3>毎月一定額を投資</h3>
      <p>
        積み立てられる金額は一般的に役職などによって異なり、企業側が決めています。
        ただし制度全体でも上限は決まっており、それは月 5 万 5,000 円、企業が他に企業年金を用意している場合は月 2 万 7,500 円です。
      </p><p>
        企業型 DC に加入すると、毎月、同じ金額を対応する投資信託を購入したり、定期預金に入金したりして年金を積み立てることになります。</p>
      <p>
        企業によって、積み立てられる商品のラインナップは異なります。
        上限以内であれば「投資信託と定期預金」「複数の投資信託」といったように、積み立てる中身は本人が自由に決められます。
      </p>
      <h3>金額を増やす「マッチング拠出」</h3>
      <p>
        「上限を超えて、毎月もっと多くの金額を積み立てたい」というアグレッシブな方ために、従業員が自身の給与から掛け金を追加できる「マッチング拠出」という仕組みも存在します。
        ただし、企業側が対応していない場合もありますので、詳しくは所属の企業にお確かめください。
      </p>

      <h3>企業型 DC のメリット</h3>
      <h4>利益への税金の支払い免除</h4>
      <p>自身で投資信託などを購入して運用し、利益が出た場合は、その 20％ほどを税金として支払わなくてはなりません。企業型 DC で積み立てておくと、その税金が免除されます。</p>
      <p>例えば毎月、企業型 DC で 2 万 3,000 円を 30 年間、積み立てた場合と、同条件で一般口座で投資信託を買い続けた場合の差は以下の通りとなります。左辺が「運用利回り（毎年平均して何％利益が増えるか）」で、右辺が「かからなかった税金分（企業型 DC でトクする額）」です。</p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>1％</th>
            <td>27.4 万円</td>
          </tr>
          <tr>
            <th>2％</th>
            <td>60.7 万円</td>
          </tr>
          <tr>
            <th>3％</th>
            <td>101 万円</td>
          </tr>
        </tbody>
      </table>
      <h4>受け取る際も税金の優遇あり</h4>
      <p>また、積み立てた年金を受け取る際にもメリットがあります。「退職所得控除」または「公的年金等控除」という優遇の対象となるからです。</p>
    </div>)
  },
  ideco: {
    default: (<div>
      <h3>国による老後資金の準備の仕組み</h3>
      <p>
        iDeCo（イデコ）は、老後に備えるための国の制度です。
        毎月、給与などから差し引かれて通常 65 歳から受け取れる「公的年金」とは別に、
        一人ひとりが自ら積み立てて準備する「個人型年金」を指します。
        </p>
      <h3>毎月一定額を投資</h3>
      <p>
        iDeCo の仕組みを一言でいうと「毎月、決まった額を投資し、60 歳になったら売却できる」というものです。
        その金額（掛け金）は会社員であれば毎月 5,000 円から 23,000 円までの範囲で、1,000 円単位で自ら決められます。
          対象者は、国内に住む 20 歳から 60 歳までです。</p>
      <h3>投資信託などを自身で選択</h3>
      <p>
        iDeCo による投資といっても「iDeCo」という投資先があるわけではありません。
        実際には、iDeCo に対応した投資信託や定期預金を積み立てることになります。
        この投資先は、iDeCo 向けの口座を開設する銀行や証券会社によって異なり、おおむね 10〜20 種類ほどが用意されています。
        </p>

      <h3>iDeCo のメリット</h3>
      <h4>所得税・住民税の減税</h4>
      <p>iDeCo は節税効果が非常に大きいことが最大の利点で、中でもすぐに効果があるのが「所得税・住民税の減税」です。</p>
      <p>例えば扶養する配偶者や子がいない会社員の方の年収（左）別に、毎月 2 万 3,000 円を 30 年間 iDeCo で投資した場合、以下の金額（右）が年末調整で戻ってきます。</p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>400 万円</th>
            <td>4 万 1,400 円</td>
          </tr>
          <tr>
            <th>500 万円</th>
            <td>5 万 5,200 円</td>
          </tr>
          <tr>
            <th>600 万円</th>
            <td>5 万 5,200 円</td>
          </tr>
          <tr>
            <th>700 万円</th>
            <td>8 万 2,800 円</td>
          </tr>
          <tr>
            <th>800 万円</th>
            <td>8 万 2,800 円</td>
          </tr>
        </tbody>
      </table>
      <p>ただし、収入のない方の場合は所得控除上の違いはありません。</p>

      <h4>利益への税金の支払い免除</h4>
      <p>また、投資で得た利益には税金がかかりません。</p>
      <p>例えば毎月、iDeCo で 2 万 3,000 円を 30 年間、積み立てた場合と、同条件で一般口座で投資信託を買い続けた場合の差は以下の通りとなります。左辺が「運用利回り（毎年平均して何％利益が増えるか）」で、右辺が「かからなかった税金分（iDeCo でトクする額）」です。</p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>1％</th>
            <td>27.4 万円</td>
          </tr>
          <tr>
            <th>2％</th>
            <td>60.7 万円</td>
          </tr>
          <tr>
            <th>3％</th>
            <td>101 万円</td>
          </tr>
        </tbody>
      </table>

      <h3>デメリット</h3>
      <h4>60 歳まで引き出し不可</h4>
      <p>積み立てた資産は 60 歳まで原則、引き出しできません。このため、もし急に物要りになったとしても、iDeCo として投資している分の資産はあてにできないことに注意してください。</p><p>ただし月に投資する金額は変更できますので、家計が苦しくなったら最低金額の 5,000 円に変えておく、などで対応するとよいでしょう。</p>

      <h3>専用口座の開設が必要</h3>
      <p>
        iDeCo を始めるには、まずiDeCoを始めるには<a href="https://www.ideco-koushiki.jp/operations/" target="_blank" rel="noopener noreferrer">国から運営を任されている銀行や証券会社</a>で「iDeCo の口座」を開設する必要があります。
        通常の証券口座とは異なり、iDeCo の口座は一人につき一つしか作れません。</p><p>運営会社を選ぶ際は「口座管理手数料が無料かどうか」「どんな投資先があるか」を確認してください。口座管理手数料は毎月かかり、171〜629 円と差があります。</p>
      <p>作った口座を別の金融機関に移設することも可能ですが、そのためには一度、それまでの投資を売却して再投資したり、しばらく売買ができなくなったりと手間がかかることに注意してください。</p>
      <p>
        なおインターネットで iDeCo の口座が作れる大手としては、以下のような証券会社があります。
          </p>
      <ul>
        <li>楽天証券</li>
        <li>SBI 証券</li>
      </ul>
    </div>
    ),
  },
  ReemploymentAge: (<div>
    <h3>お勤めの場合</h3>
    <p>会社員や公務員、アルバイトなど勤め先がある方は、その組織の制度によって何歳まで働けるかの「定年」が決まっています。</p>
    <p>多くの組織では、この定年は 60 歳と定めています。そして、それ以降も延長して、同じ勤め先で働ける制度のことを「定年後再雇用制度」といいます。
        これは組織側の義務であるため、従業員が希望すれば通常は 5 年間、働く期間を延長できます。</p>
    <p>よって一生黒字プランでは「再雇用を開始する年齢」は 60 歳、「完全リタイアする年齢」は 65 歳が標準の値となっています。</p>
    <p>なお、この延長の期間は今後、法律の改正により 70 歳までなど、さらに長くなる可能性があります。</p>
    <p>
      賃金は、定年前よりも下がることが一般的。定年退職前の賃金が 50％〜70％程度になることをイメージして、ライフプランを考えておくことがオススメです。
      </p>
    <h3>自営の場合</h3>
    <p>自身で事業を興している自営業の方の場合、「再雇用」にあたる出来事はありません。仕事を辞める「完全にリタイアする年齢」と同じにしておくことをオススメします。</p>
  </div>
  ),
  // 出産のお祝い
  maternityGift: (<div>
    <p>出産すると親や親戚、兄弟、会社などからお祝いをもらえることがあります。<a href="https://prtimes.jp/main/html/rd/p/000000009.000029931.html" target="_blank" rel="noopener noreferrer">ベビーカレンダー「出産祝いに関する調査」（2018）</a>によると、一人あたりの出産祝い額は平均 43 万円に上ります。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>両家両親</th>
          <td>18 万 2,195 円</td>
        </tr>
        <tr>
          <th>親戚</th>
          <td>9 万 8,157 円</td>
        </tr>
        <tr>
          <th>会社関係</th>
          <td>4 万 4,000 円</td>
        </tr>
        <tr>
          <th>兄弟姉妹</th>
          <td>4 万 0,952 円</td>
        </tr>
        <tr>
          <th>友人知人</th>
          <td>2 万 3,076 円</td>
        </tr>
      </tbody>
    </table>
    <p>この結果はあくまでもアンケート調査の平均であり、環境などによって大きく差があることを留意する必要があります。</p>
  </div>),
  birthCost: (<div>

    <p>妊娠が判明してから出産に至るまでに、大別して以下の費用がかかります。</p>
    <ul>
      <li>妊婦健診 … 自己負担 3〜7 万円</li>
      <li>出産費用 … 42 万円〜62 万円</li>
    </ul>
    <h3>妊婦健診費は自治体・病院で差</h3>
    <p>妊婦健診は、定期的に胎児や母体の健康状態を確認するための検診です。受信回数は 14 回前後が一般的。</p>
    <p>自治体に妊娠を届け出ると、母子手帳とともに妊婦健診で使える補助券がもらえます。検診の費用は病院によって異なり、また補助券も自治体によって異なります。</p>
    <p>差し引きした自己負担は、3〜7 万円程度を見込んでおきましょう。</p>

    <h3>出産は自然分娩なら平均 51 万円</h3>
    <h4>地域によって 20 万円の差</h4>
    <p>出産費用は自然分娩の場合、全国平均で 50 万 5,759 円です。
    一方、出産に対し、国からもらえる出産育児一時金は 42 万円です。
    平均から差し引くと、出産そのものの自己負担は 8 万円強となります。
</p>
    <p>しかしながら、出産費用は地域差が大きいことに注意してください。<a href="https://www.kokuho.or.jp/statistics/birth/2017-0620.html" target="_blank" rel="noopener noreferrer">最高値の東京都では平均 62 万円、最安値の熊本県では平均 42 万円</a>です。</p>
    <table className={styles.vertical}>
      <tbody>
        <tr>
          <th>全国</th>
          <td>50 万 5,759 円</td>
        </tr>
        <tr>
          <th>東京都</th>
          <td>62 万 1,814 円</td>
        </tr>
        <tr>
          <th>熊本県</th>
          <td>41 万 5,923 円</td>
        </tr>
      </tbody>
    </table>

    <h4>帝王切開は +10〜20 万円</h4>
    <p>もし出産が帝王切開になった場合は手術代がかかります。この費用は、公的医療保険の適用となるため、実質の負担は 10〜20 万円程度となります。</p>
    <p>また、民間の医療保険に加入している場合は、入院給付金・手術給付金の対象となりえます。</p>
    <p>なお、厚生労働省の調査（2017）によると、帝王切開による出産の割合は病院で 25.8％、診療所で 14％であり、増加傾向が続いています。</p>

    <h4>無痛分娩は +10〜20 万円</h4>
    <p>
      出産時の痛みを和らげる無痛分娩を選択した場合、費用は 10〜20 万円、増えます。これは保険適用外となるため、すべて自己負担です。</p>
    <p>
      <a href="http://www.jaog.or.jp/wp/wp-content/uploads/2017/12/20171213_2.pdf" target="_blank" rel="noopener noreferrer">厚生労働省の調査（2018）</a>によると 2016 年時点の無痛分娩の割合は 6.1％で、2008 年 2.6％から徐々に増えています。
</p>
  </div>),
  child: {
    // 就学前
    preschool: (<div>
      <h3>保育園は年 50 万〜70 万円</h3>
      <p>
        共働き家庭の場合に利用することが多いであろう保育園。費用は「認可保育園」か「認可外保育園」かによって大きく異なります。
        </p>
      <p>
        認可保育園の負担額は所得で変わるものの、平均すると年間 50 万円ほどです。
        一方、認可外保育園の平均は約 70 万円となっています。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>認可</th>
            <td>50 万円/年</td>
          </tr>
          <tr>
            <th>認可外</th>
            <td>70 万円/年</td>
          </tr>
        </tbody>
      </table>
      <h3>幼稚園は年 23 万〜53 万円</h3>
      <p>
        幼稚園の費用は、公立か私立かによって大きく異なります。
          <a href="https://www.mext.go.jp/content/20191212-mxt_chousa01-000003123_03.pdf" target="_blank" rel="noopener noreferrer">文部科学省の調査（2018）</a>によると、授業料や課外活動費用などすべて含めた平均は、公立だと年間 23 万円、私立だと年間 53 万円程度です。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>公立</th>
            <td>23 万円/年</td>
          </tr>
          <tr>
            <th>私立</th>
            <td>53 万円/年</td>
          </tr>
        </tbody>
      </table>
      <h3>人口が多い地域ほど高額</h3>
      <p>
        市区町村の人口規模が大きくなるほど、費用がかさむ傾向があります。
        例えば人口 5 万人未満の町と政令指定都市を比べると、平均して公立で約 4 万円、私立で 15 万円ほど政令指定都市の方が高くなります。
        </p>
    </div>),
    es: (<div>
      <h3>公立小学校は年間 32 万円</h3>
      <p>
        小学校の費用は、授業料の他に学校給食や課外活動など、学校生活に送るために必要な費用が含まれています。
        以下は
          <a href="https://www.mext.go.jp/a_menu/koutou/shinkou/main5_a3.htm" target="_blank" rel="noopener noreferrer">文部科学省が保護者あてに実施したアンケート</a>
          で明らかになった、年間の平均総額です。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>公立</th>
            <td>32 万 1,281 円</td>
          </tr>
          <tr>
            <th>私立</th>
            <td>159 万 8,691 円</td>
          </tr>
        </tbody>
      </table>
      <h3>私立進学者は 1.1％</h3>
      <p>なお、私立に通う小学生は全国で見ても 1.1％と、ごくわずかです。
      全国にある小学校の約 2 万 2,000 校でのうち、私立は 213 校。およそ半数が東京都など首都圏に集中しています。
          </p>
    </div>),
    jhs: (<div>
      <h3>公立と私立で年間 90 万円の差</h3>
      <p>
        中学校の費用は、授業料の他に学校給食や課外活動など、学校生活に送るために必要な費用が含まれています。
        以下は
          <a href="https://www.mext.go.jp/a_menu/koutou/shinkou/main5_a3.htm" target="_blank" rel="noopener noreferrer">文部科学省が保護者あてに実施したアンケート</a>
          で明らかになった、年間の平均総額です。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>公立</th>
            <td>48 万 8,397 円</td>
          </tr>
          <tr>
            <th>私立</th>
            <td>140 万 6,433 円</td>
          </tr>
        </tbody>
      </table>
      <h3>私立進学者は 7.3％</h3>
      <p>
        私立に通う中学生の割合は、全国では 7.3％です。
            これは地域によって差が大きく、<a href="https://www.kyoiku.metro.tokyo.lg.jp/administration/statistics_and_research/career_report/report2020.html" target="_blank" rel="noopener noreferrer">東京都教育委員会の「令和 2 年度公立学校統計調査報告書」</a>
            によると、東京都の公立小学校卒業者のうち私立中学校に進学したのは 18.4％と、全国平均より 10％も高い値でした。
          </p>
    </div>),
    hs: (<div>
      <h3>公立と私立で年間 50 万円の差</h3>
      <p>
        高校の費用は、授業料の他に課外活動や教科書代など、学校生活に送るために必要な費用が含まれています。
        以下は
          <a href="https://www.mext.go.jp/a_menu/koutou/shinkou/main5_a3.htm" target="_blank" rel="noopener noreferrer">文部科学省が保護者あてに実施したアンケート</a>
          で明らかになった、年間の平均総額です。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>公立</th>
            <td>45 万 7,380 円</td>
          </tr>
          <tr>
            <th>私立</th>
            <td>96 万 9,911 円</td>
          </tr>
        </tbody>
      </table>
      <h3>私立進学者は 32.9％</h3>
      <p>
        私立に通う高校生の割合は、<a href="https://www.mext.go.jp/content/20200825-mxt_chousa01-1419591_8.pdf" target="_blank" rel="noopener noreferrer">全国では 32.9％</a>です。
            東京都は突出して割合が大きく、今後も<a href="https://www.metro.tokyo.lg.jp/tosei/hodohappyo/press/2020/09/09/08_01.html" target="_blank" rel="noopener noreferrer">約 40％の生徒を私立が受け入れる計画</a>です。
          </p>
      <h3>3 年で約 40 万円の私立高校も登場</h3>
      <p>
        今日、高校の形態は多種多様になっており、単位制・通信制課程などを擁し、必ずしも私立だからといって高額な費用がかからないケースも出てきています。
            例えば学校法人角川ドワンゴ学園が運営する<a href="https://nnn.ed.jp/admission/tuition/net/" target="_blank" rel="noopener noreferrer">N 高等学校・S 高等学校</a>のオンラインコースの学費シミュレーションでは、世帯年収が 590 万円未満ほどの場合、3 年間の実質負担額は約 20 万円という設定です。
          </p>
    </div>),
    university: (<div>
      <p>
        大学の費用は、国公立か私立か、また専攻分野によっても大きく異なります。
        文部科学省の統計による、年間の費用はおよそ以下の通りです。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>国立</th>
            <td>82 万円 </td>
          </tr>
          <tr>
            <th>公立</th>
            <td>93 万円 </td>
          </tr>
          <tr>
            <th>私立文系</th>
            <td>117 万円 </td>
          </tr>
          <tr>
            <th>私立理系</th>
            <td>155 万円 </td>
          </tr>
          <tr>
            <th>私立医歯系</th>
            <td>482 万円 </td>
          </tr>
          <tr>
            <th>短大</th>
            <td>100 万円 </td>
          </tr>
        </tbody>
      </table>
      <p>一生黒字プランの算出では、これら授業料の他に課外活動など、学校生活に送るために必要な費用も含まれています。</p>
    </div>),
    graduate: (<div>
      <h3>理系卒業者の 4 割は進学</h3>
      <p>文部科学省の「学校基本調査」（2018）によると、大学卒業者の修士課程進学率は理学系・工学系で約 4 割を占める一方、人文学では 2.5％、社会科学では 4.7％など、文系学部からは低くなっています。</p>
      <h3>学費は国立か私立かで大差</h3>
      <p>
        2 年間の学費は、国立の大学院の場合は 135 万円、私立は 200〜400 万円です。
        特に MBA など社会人に対応したコースや法科大学院といった専門系は高額になるケースが見受けられます。
        </p>
      <table className={styles.vertical}>
        <tbody>
          <tr>
            <th>国立</th>
            <td>2 年で 135 万円</td>
          </tr>
          <tr>
            <th>私立</th>
            <td>2 年で 200〜400 万円</td>
          </tr>
        </tbody>
      </table>
      <p>一生黒字プランの算出では、これら授業料の他に課外活動など、学校生活に送るために必要な費用も含まれています。</p>
    </div>),

  }

}
