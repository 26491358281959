import React from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { TabList, TabPanel } from '@material-ui/lab';

export const EventTabs = withStyles({
  root: {
    backgroundColor: "#92DC95",
    borderRadius: 29,
  },
  flexContainer: {
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
    width: 260,
    zIndex: 1
  },
  indicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: 'auto',
    background: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -10,
      right: -10,
      bottom: 0,
      borderRadius: 29,
      backgroundColor: '#fff'
    },
  }})(TabList);

export const EventTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        opacity: 1
      },
      height: 40,
      minWidth: 120,
      width: 120,
      fontSize: 12,
      color: '#ffffff',
      fontWeight: 700,
      '&$selected': {
        color: '#3FB445',
      }
    },
    wrapper: {
      // zIndex: 2,
      // marginTop: spacing(0.5),
      textTransform: 'initial'
    },
    selected: {
    },
  }),
)(Tab);

export const EventTabPanel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 16
    },
  }),
)(TabPanel);
