import * as React from "react";
import styles from "./AgeTable.module.scss";
import { formatMoney, Simulator } from "../../simulator";

export default (props: { plan: Simulator }) => {
  return (
    <div className={styles.container}>
      <div>*金額は万円単位</div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableScroller}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>年齢</th>
                <th>人数</th>
                <th>総資産</th>
                <th colSpan={6} className={styles.payment}>支出</th>
                <th colSpan={5} className={styles.income}>収入</th>
              </tr>
              <tr>
                <th>あなた</th>
                <th>合計</th>
                <th>合計</th>
                <th className={styles.payment}>合計</th>
                <th className={styles.payment}>住まい</th>
                <th className={styles.payment}>クルマ</th>
                <th className={styles.payment}>子ども</th>
                <th className={styles.payment}>生活費</th>
                <th className={styles.payment}>その他</th>
                <th className={styles.income}>合計</th>
                <th className={styles.income}>資産</th>
                <th className={styles.income}>仕事</th>
                <th className={styles.income}>老後</th>
                <th className={styles.income}>その他</th>
              </tr>
            </thead>
            <tbody>
              {props.plan.ages.map(a =>
                <tr key={a.age}>
                  <th>{a.age}</th>
                  <td className={styles.total}>{a.familyMembers()}</td>
                  <td className={`${styles.asset} ${styles.total}`}>{formatMoney(a.asset())}</td>
                  <td className={`${styles.payment} ${styles.total}`}>{formatMoney(a.expense())}</td>
                  <td className={`${styles.payment}`}>{formatMoney(a.houseCost())}</td>
                  <td className={`${styles.payment}`}>{formatMoney(a.carCost())}</td>
                  <td className={`${styles.payment}`}>{formatMoney(a.childExpense())}</td>
                  <td className={`${styles.payment}`}>{formatMoney(a.livingCostEtc())}</td>
                  <td className={`${styles.payment}`}>{formatMoney(a.otherExpense())}</td>
                  <td className={`${styles.income} ${styles.total}`}>{formatMoney(a.income())}</td>
                  <td className={`${styles.income}`}>{formatMoney(a.investmentProfit())}</td>
                  <td className={`${styles.income}`}>{formatMoney(a.workIncome())}</td>
                  <td className={`${styles.income}`}>{formatMoney(a.pension())}</td>
                  <td className={`${styles.income}`}>{formatMoney(a.otherIncome())}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.fixedHeader}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>年齢</th>
              </tr>
              <tr>
                <th>あなた</th>
              </tr>
            </thead>
            <tbody>
              {props.plan.ages.map(a =>
                <tr key={a.age}>
                  <th>{a.age}</th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
