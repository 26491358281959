import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/analytics";

export const PRODUCTION =
  process.env.REACT_APP_PRODUCTION === "1";

const firebaseConfig = 
  PRODUCTION ?
  {
    apiKey: "AIzaSyC2L4NIMUB-nCJef_x2ATZNz0qsfIkJkoQ",
    authDomain: "lifetime-production.firebaseapp.com",
    databaseURL: "https://lifetime-production.firebaseio.com",
    projectId: "lifetime-production",
    storageBucket: "lifetime-production.appspot.com",
    messagingSenderId: "63765241077",
    appId: "1:63765241077:web:990995d0cda30257d8f9bc",
    measurementId: "G-W5JNJGMSQT"
  }
  :
  {
    apiKey: "AIzaSyC94sGpbX42Vs9x_2Fc0cy3r5S1XUfkTvQ",
    authDomain: "lifetime-d4cd2.firebaseapp.com",
    databaseURL: "https://lifetime-d4cd2.firebaseio.com",
    projectId: "lifetime-d4cd2",
    storageBucket: "lifetime-d4cd2.appspot.com",
    messagingSenderId: "328956413700",
    appId: "1:328956413700:web:f26b65b79eb42f76113251",
    measurementId: "G-CBJGR5DPXC"
  };

const REGION = 'asia-northeast1';

firebase.initializeApp(firebaseConfig);

export const functions = firebase.app()!.functions(REGION);

if (process.env.NODE_ENV === 'development') {
  // CORS を回避するため proxy を通して localhost:5001 に接続する
  console.log('using emulator...');
  functions.useFunctionsEmulator('http://localhost:3000');
}

export const functionURL = (name: string) => {
  return process.env.NODE_ENV === 'development' ?
    `http://${window.location.hostname}:3000/${firebaseConfig.projectId}/${REGION}/${name}` :
    `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net/${name}`;
}

export const db = firebase.firestore();
if (process.env.NODE_ENV === 'development') {
  db.settings({
    host: `${window.location.hostname}:8081`,
    ssl: false
  });

}

export const AUTH_DISABLED =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_AUTH !== "1";

export const AUTH_URL = PRODUCTION ? "https://id.zaim.net" : 
  process.env.NODE_ENV === "development" ?
  "http://localhost:3005" :
  "https://id.stg-zaim.net";

export const AUTH_CLIENT_ID = PRODUCTION ?
  '97a98a47-56a9-4cab-a0f9-516b88ff9d9e' :
  process.env.NODE_ENV === "development" ?
  '97453fa2-cbe1-49c4-8434-8734d408e7f6' :
  '9740d816-ff1b-4312-a741-b6f8b8ae19f4'; // staging

if (firebase.analytics) {
  firebase.analytics();
  console.log("using firebase analytics");
} else {
  console.log("firebase.analytics not found");
}

export default firebase;
