import * as React from "react";
import styles from "./ReviewIndex.module.scss";
import { SettingsPages, settingsPath } from "../../pages/settings";
import { acquireBadge, Actions, AppState, changePage, Context, saveState, sessionStartDate, setRequiredReviews, setReviews } from "../../Store";
import { Review, Simulator } from "../../simulator";
import _ from "lodash";
import completedIcon from "./completed.svg";
import InfoDialog from "../InfoDialog";

const Checkbox = (props: { checked: boolean }) => {
  const icon = props.checked ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#49C54F" />
      <path d="M13 20.17L8.82997 16L7.40997 17.41L13 23L25 11L23.59 9.59L13 20.17Z" fill="white" />
    </svg>) :
    (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" fill="white" stroke="#9E9E9E" strokeWidth="2" />
        <path d="M13 20.17L8.83 16L7.41 17.41L13 23L25 11L23.59 9.59L13 20.17Z" fill="#E0E0E0" />
      </svg>);
  return (
    <div>
      {icon}
    </div>);
}

const renewReviews = (dispatch: (action: Actions) => void, state: AppState, plan: Simulator) => {
  let needToRenew: boolean;
  if (state.blueprint.reviewedDate) {
    const today = sessionStartDate();
    if (today.getFullYear() === state.blueprint.reviewedDate.getFullYear() &&
      today.getMonth() === state.blueprint.reviewedDate.getMonth()) {
      needToRenew = false;
    } else {
      needToRenew = true;
    }
  } else {
    needToRenew = true;
  }
  if (needToRenew) {
    dispatch(setRequiredReviews(plan.reviews().map(review => review.id), true));
    return null;
  } else {
    const reviews = plan.reviews();
    if (!_.isEqual(new Set(reviews.map(review => review.id)), new Set(state.blueprint.requiredReviews))) {
      dispatch(setRequiredReviews(plan.reviews().map(review => review.id), false));
    }
    return reviews;
  }
}

export default (props: { plan: Simulator, settingsRef: React.RefObject<HTMLElement> }) => {
  const { dispatch, state } = React.useContext(Context);
  const reviews = renewReviews(dispatch, state, props.plan);
  const [clicked, setClicked] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  React.useEffect(() => {
    if (clicked) {
      // チェックされたあとに保存する
      saveState(state);
      if (state.blueprint.requiredReviews.length === state.blueprint.reviews.length) {
        dispatch(acquireBadge("monthly", true));
        setCompleted(true);
      }
    }
  }, [state.blueprint.requiredReviews, state.blueprint.reviews]);
  if (!reviews) {
    return <div className="update">情報を読み込んでいます…</div>
  } else {
    const linkToReview = (review: Review, event: React.MouseEvent) => {
      if (review.page === "#settings") {
        props.settingsRef.current!.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.history.pushState(true, "", settingsPath(review.page));
        dispatch(changePage(settingsPath(review.page)));
      }
      event.stopPropagation();
    }
    const checkReview = (review: Review) => {
      if (_.includes(state.blueprint.reviews, review.id)) {
        dispatch(setReviews(_.filter(state.blueprint.reviews, (id) => review.id != id)));
      } else {
        dispatch(setReviews([...state.blueprint.reviews, review.id]));
      }
      setClicked(true);
    }
    return (
      <div className={styles.container}>
        { reviews.map((review) => {
          return (
            <div key={review.id} className={styles.reviews}>
              <div className={styles.checkbox} onClick={() => { checkReview(review) }}>
                <Checkbox checked={_.includes(state.blueprint.reviews, review.id)} />
              </div>
              <div className={styles.item} onClick={() => { checkReview(review) }}>
                <div className={`${styles.content} ${styles[_.includes(state.blueprint.reviews, review.id) ? "checked" : "unchecked"]}`}>
                  <div className={styles.title}>
                    {review.title}
                  </div>
                  <div className={styles.description}>
                    {review.description}
                  </div>
                </div>
                <div className={styles.edit} onClick={(e) => { linkToReview(review, e as React.MouseEvent) }}>
                  編集
              </div>
              </div>
            </div>
          )
        })}
        <InfoDialog open={completed} setOpen={setCompleted} image={completedIcon}>
          <div className={styles.completedTitle}>
            今月の確認は完了しました！<br />
            また来月、チェックしてください。
          </div>
        </InfoDialog>
      </div>
    );
  }
};
