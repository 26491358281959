import * as React from "react";
import Link from "../../atoms/Link";
import { Section } from "../../pages/settings";
import { Simulator } from "../../simulator";
import { Context, saveState } from "../../Store";
import { FieldDef } from "../fields";
import SubtotalFooter, { Subtotal } from "../SubtotalFooter";
import styles from "./EditSettings.module.scss";

type Components = {key: string, component: React.FC<{ plan: Simulator }>, field: FieldDef<any>}[];
type FieldType = "parent" | "child" | "default";
export type SectionComponents = [Section, Components];

const groupComponents = (components: Components): [FieldType, Components][]  => {
  const fieldType = (field: FieldDef<any>) =>
    field.parent ? "parent" : field.child ? "child" : "default";
  const groups: [FieldType, Components][] = [];
  let lastGroup: Components = [];
  let lastField: FieldDef<any> | null = null;
  components.forEach((comp) => {
    if (lastGroup && lastField) {
      if (fieldType(comp.field) === "parent" ||
          fieldType(comp.field) !== fieldType(lastField)) {
        groups.push([fieldType(lastField), lastGroup]);
        lastGroup = [];
      }
    }
    lastGroup.push(comp);
    lastField = comp.field;
  });
  if (lastGroup && lastField) {
    groups.push([fieldType(lastField), lastGroup]);
  }
  return groups;
}

export default (props: {title: string, 
  sections: SectionComponents[], 
  plan: Simulator,
  subtotal?: Subtotal,
  elements?: JSX.Element}) => {
  const { dispatch, state } = React.useContext(Context);
  
  return (
    <>
      <div className={styles.header}>
        <h1>{props.title}</h1>
        <Link className={styles.backLink} onClick={
          () => {
            saveState(state);
            window.history.back();
          }
        }>
          ←
      </Link>
      </div>
      { props.sections.map(([section, components]) => {
        return components.length > 0 && (
          <div key={section.title} className={styles.container}>
            <h2>{section.title}</h2>
            {
              groupComponents(components).map(([fieldType, group]) => (
                <div key={group[0].key} className={`${styles.section} ${styles[fieldType]}`}>
                  {group.map((c) => {
                    return (
                      <c.component key={c.key} plan={props.plan} />
                    );
                  })}
                </div>
              ))
            }
          </div>
        );
      })}
      {props.elements}
      <SubtotalFooter plan={props.plan} subtotal={props.subtotal} />
    </>
  );
  
}
