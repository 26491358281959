import * as React from "react";
import styles from "./Button.module.scss";

export default (props: {
  stopPrevent?: boolean;
  disabled?: boolean;
  children: string | React.ReactElement;
  onClick: () => void;
}) => (
  <button
    className={props.disabled ? styles.btn_disabled : styles.btn}
    disabled={!!props.disabled}
    onClick={(e: React.MouseEvent<any>) => {
      if (!props.stopPrevent) {
        e.preventDefault();
        e.stopPropagation();
      }
      props.onClick();
    }}
  >
    {props.children}
  </button>
);
