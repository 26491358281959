import * as React from "react";
import _ from "lodash";
import { Context, changePage, setAttributes } from "../../Store";
import "react-datasheet/lib/react-datasheet.css";
import Timeline from "../../templates/Timeline";
import AssetChart from "../../templates/AssetChart";
import { formatMoney, formatMoneyWithUnit, Simulator } from "../../simulator";
import SettingsIndex from "../../templates/SettingsIndex";
import ResetButton from "../../templates/ResetButton";
import Comment from "../../templates/Comment";
import styles from "./Finish.module.scss";
import Container from "../../molecules/Container";
import Button from "../../atoms/Button";
import { questionIndexPath, allChartsPath } from "../../path";
import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import AgeTable from "../../templates/AgeTable";
import ReviewIndex from "../../templates/ReviewIndex";
import Badges from "../../templates/Badges";
import TotalFooter from "../../templates/TotalFooter";
import { EventTab, EventTabPanel, EventTabs } from "../../molecules/EventTabs";
import { PRODUCTION } from "../../firebase";


export default () => {
  const { dispatch, state } = React.useContext(Context);
  const [plan, setPlan] = React.useState(null as null | Simulator);
  const [selectedAgeIndex, selectAgeIndex] = React.useState(-1);
  React.useEffect(() => {
    const p = new Simulator(state.blueprint);
    setPlan(p);
    if (!PRODUCTION) {
      // console からのデバッグ用
      window.simulator = p;
    }
    selectAgeIndex(p.ages.length - 1);
  }, []);
  const showAsset = (asset: number) => formatMoneyWithUnit(asset);

  const [tab, setTab] = React.useState('timeline');

  const handleChangeTab = (event: any, newValue: string) => {
    setTab(newValue);
  };
  const linkTarget = {
    settings: React.useRef<HTMLElement>(null),
    review: React.useRef<HTMLElement>(null),
  };
  if (plan) {
    const lastAge = _.last(plan.ages)!;
    return (
      <>
        <div className={styles.container}>
          <div className={styles.resultContainer}>
            <h2>診断結果</h2>
            <div className={styles.chart}>
              <div className={styles.summary}>
                {lastAge && `${lastAge.age} 歳で資産 ${showAsset(_.last(plan.ages)!.asset())}`}
              </div>
              <AssetChart plan={plan} getValue={age => age.asset()} name="資産" />
              <Comment containerClassName="fullWidthContainer">
                {plan.commentForChart()}
              </Comment>
            </div>
            <TabContext value={tab}>
              <div className={styles.tabs}>
                <EventTabs aria-label="tabs" onChange={handleChangeTab}>
                  <EventTab label="ライフイベント" value="timeline" />
                  <EventTab label="収支年表" value="table" />
                </EventTabs>
              </div>
              <EventTabPanel value="timeline">
                <Timeline plan={plan} />
              </EventTabPanel>
              <EventTabPanel value="table" className={styles.tabPanel}>
                <AgeTable plan={plan} />
              </EventTabPanel>
            </TabContext>
          </div>
          <div className={styles.preSection} ref={linkTarget.settings as React.RefObject<HTMLHeadingElement>}>
            もっと詳しく診断しよう
        </div>
          <h3 className={styles.sectionTitle}>プラン編集</h3>
          <Comment containerClassName="fullWidthContainer">
            {plan.commentForSettings()}
          </Comment>
          <SettingsIndex plan={plan} />
          <div className={styles.preSection} ref={linkTarget.review as React.RefObject<HTMLHeadingElement>}>
            どこを編集したらいいか迷ったら
        </div>
          <h3 className={styles.sectionTitle}>確認リスト</h3>
          <Comment containerClassName="fullWidthContainer">
            上記のプラン編集の中でも、特にここは確認していただきたいという項目を集めました。今の状況を設定したら、チェックマークをタップして完了してください。
        </Comment>
          <ReviewIndex plan={plan} settingsRef={linkTarget.settings} />
          <Badges plan={plan} />
          <div className={styles.attention}>
            <h4>免責事項</h4>
            <p>一生黒字プランは、ご利用者様の入力を元に収支や資産の推移をシミュレーションし参考情報を提供するサービスであり、ご利用者様の家計の継続的な黒字収支等を保証するものではありません。また、シミュレーション結果については万全の注意を払っておりますが、正確性、完全性、信頼性、有用性および適時性を保証するものではありません。ご利用者様は、当サービスの利用にあたっては、ご自身の責任にて判断するものとします。ご利用者様が当サービスを利用されたことにより生じたいかなる結果についても、当社は責任を負いかねますことをあらかじめご了承ください。また、当サービスは事前の通知なしに、内容を変更することがあります。</p>
          </div>
          <div className={styles.return}>
            <a href="https://kj4nh.app.goo.gl/home">Zaim トップに戻る</a>
          </div>
        </div>
        <TotalFooter plan={plan} target={linkTarget} />
      </>
    );
  } else {
    return <div className="update">情報を読み込んでいます…</div>
  }
};
