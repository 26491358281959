import * as React from "react";
import styles from "./Question.module.scss";
import Container from "../../../molecules/Container";
import Title from "../../../atoms/Title";
import HalfDivideArea from "../../../molecules/HalfDivideArea";
import Button from "../../../atoms/Button";
import Link from "../../../atoms/Link";
import robotIcon from "../robot.svg";
import { QuestionProps, EndQuestion } from "..";
import { Context, AppState } from "../../../Store";
import { completeQuestionCategoryHook, nextQuestion, completeQuestionCategory } from "..";
import { FieldDef, getFieldTitle } from "../../../templates/fields";

export const QuestionField = (props: {
  field: FieldDef<any>;
  children: React.ReactElement;
}) => {
  const { dispatch, state } = React.useContext(Context);
  const title = getFieldTitle(props.field, state);
  const warning = props.field && props.field.warning ? props.field.warning(state) : null;
  const comment = typeof props.field.comment === "function" ? props.field.comment(state) : props.field.comment;
  return (
    <>
      <Container height={50}>
        <Title message={title || ""} />
      </Container>
      {props.field.subtitle && props.field.subtitle.length > 0 &&
        <Container>
          <div className={styles.description}>
            {props.field.subtitle}
          </div>
        </Container>
      }
      <Container>
        {props.children}
      </Container>
      {comment &&
        <Container>
        <div className={styles.comment}>
          {comment}
        </div>
      </Container>
      }
      {warning &&
        <Container>
        <div className={styles.warning}>
          {warning}
        </div>
      </Container>
      }
    </>
  );
}

const Question = (props: {
  field?: FieldDef<any>;
  children: React.ReactElement;
  question: QuestionProps;
  enableNext?: boolean;
  addSkip?: boolean;
}) => {
  const { dispatch, state } = React.useContext(Context);
  React.useEffect(completeQuestionCategoryHook(state, props.question.category));
  const linkToNext = () => {
    let nextPage: string;
    if (props.question.next) {
      if (typeof props.question.next === "function") {
        const nextFunc = props.question.next as ((state: AppState) => string | "end");
        nextPage = nextFunc(state);
      } else {
        nextPage = props.question.next as string;
      }
    } else {
      nextPage = EndQuestion;
    }
    if (nextPage !== EndQuestion) {
      nextQuestion(dispatch, state, nextPage);
    } else {
      completeQuestionCategory(dispatch, state, props.question.category);
    }
  }
  return (
    <div className={styles.content}>
      <Container height={80}>
        <img src={robotIcon} width="72px" height="72px" />
      </Container>
      { props.field &&
        <QuestionField field={props.field}>
          {props.children}
        </QuestionField>
      }
      { !props.field && props.children }
      {props.addSkip &&
        <Container>
          <a onClick={linkToNext} className={styles.later}>
            不明なので後で入力
          </a>
        </Container>
      }
      <Container height={200}>
        <HalfDivideArea>
          <Link onClick={() => window.history.back()}>前へ</Link>
          <Button disabled={props.enableNext === false} onClick={linkToNext}>次へ</Button>
        </HalfDivideArea>
      </Container>
    </div>
  );
};

export default Question;
