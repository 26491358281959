import { db, AUTH_DISABLED } from '../firebase';
import { AppState, Blueprint, Properties } from '.'
import firebase from '../firebase';
import _ from "lodash";
import debug from '../debug';

//export const FETCH_USER = "FETCH_USER";
export const PUT_PLAN_SUCCESS = "PUT_PLAN_SUCCESS";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const COLLECTION_NAME = AUTH_DISABLED ? "dev-plans" : "plans";

const dateToFirestore = (date: Date) => {
  if (date != null) {
    return date.toISOString().substring(0, 10);
  } else {
    return null;
  }
}

const dateFromFirestore = (date: any) => {
  if (date != null) {
    if (date.toDate) { // firestore.Timestamp
      return date.toDate();
    } else {
      return new Date(date);
    }
  } else {
    return null;
  }
}

const blueprintConveter = {
  toFirestore: (data: object) => {
    data = _.omitBy(data, _.isUndefined);
    return _.cloneDeepWith(data, function customizer(value, key, obj): any {
      if (key === "birthday") {
        return dateToFirestore(value);
      }
      //console.log("value", _.isPlainObject(value), value && value.constructor === Properties, value && value.constructor.toString());
      if (obj && value && (_.isPlainObject(value) || 
        value.constructor === Properties)) {
        return _.cloneDeepWith(_.omitBy(value, _.isUndefined), customizer);
      }
    });
  },

  fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions) => {
    let data = snapshot.data(options);
    data = _.cloneDeepWith(data, (value, key, obj) => {
      if (key === "birthday" || (value && value.toDate)) {
        return dateFromFirestore(value);
      }
    });
    return new Blueprint(data);
  }
}

export const fetchPlan = (uid: string) => {
  return db.collection(COLLECTION_NAME)
    .withConverter(blueprintConveter)
    .doc(uid).get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null;
      }
    });
}

export const savePlan = (uid: string, data: Blueprint) => {
  /*
  Object.entries(state.blueprint).filter(([key]) =>
    ['wishChildNum', 'houseType'].includes(key));
  */
  debug('savePlan', uid, data);
  const obj = Object.assign({}, data);
  return db.collection(COLLECTION_NAME).withConverter(blueprintConveter)
    .doc(uid).set(obj)
    .then(() => {
      console.log("saved plan");
    })
    .catch((err) => {
      console.log(err, `uid=${uid}`);
    });
}
