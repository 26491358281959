import * as React from "react";
import SettingsCategory from "../SettingsCategory";
import { BasicSettingsPage, ExpenseSettingsPages, FamilySettingsPage, IncomeSettingsPages, SettingsPages } from "../../pages/settings";
import { Context } from "../../Store";
import { Simulator } from "../../simulator";
import styles from "./SettingsIndex.module.scss";

export default (props: {plan: Simulator}) => {
  const { dispatch, state } = React.useContext(Context);
  return (
    <>
    <SettingsCategory page={FamilySettingsPage} path="family" />
    <SettingsCategory page={BasicSettingsPage} path="config" />
    <h4 className={styles.settingsType}>支出</h4>
    {ExpenseSettingsPages.map((page) => {
      if (page.skip && page.skip(state)) {
        return null;
      } else {
        return <SettingsCategory page={page.page} path={page.key} key={page.key}
          icon={page.icon} plan={props.plan} badge={true} />
      }
    })}
    <h4 className={styles.settingsType}>収入</h4>
    {IncomeSettingsPages.map((page) => {
      if (page.skip && page.skip(state)) {
        return null;
      } else {
        return <SettingsCategory page={page.page} path={page.key} key={page.key}
          icon={page.icon} plan={props.plan} badge={true} />
      }
    })}
    </>
  );
};
