import { PRODUCTION } from "./firebase"

const debug = PRODUCTION ? (obj: any, ...args: any[]) => {} : console.debug.bind(window.console);
/*
if (PRODUCTION) {

}
const debug = (obj: any, ...args: any[]) => {
  if (!PRODUCTION) {
    console.debug(obj, ...args);
  }
}
*/

export default debug;
