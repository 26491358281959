import * as React from "react";
import styles from "./SettingsCategory.module.scss";
import { Context, changePage, acquireBadge, BadgeName } from "../../Store";
import Link from "../../atoms/Link";
import { SettingsPageComponent, settingsPath } from "../../pages/settings";
import { formatMoney, formatMoneyWithUnit, Simulator } from "../../simulator";
import disclosureIcon from "./disclosure.svg";

export default (props: {
  path: string,
  icon?: string,
  page: SettingsPageComponent,
  plan?: Simulator,
  badge?: boolean,
}) => {
  const { dispatch } = React.useContext(Context);
  return (
    <div className={styles.category} onClick={() => {
      if (props.badge) {
        dispatch(acquireBadge(props.path as BadgeName));
      }
      window.history.pushState(true, "", settingsPath(props.path));
      dispatch(changePage(settingsPath(props.path)));
    }}>
        {props.icon &&
          <div className={styles.icon}>
            <img src={props.icon} />
          </div>
        }
      <div className={styles.title}>{props.page.title}</div>
      {props.plan && props.page.subtotal &&
        <div className={styles.subtotal}>
          合計 {formatMoneyWithUnit(props.page.subtotal(props.plan))}
        </div>
      }
      <div className={styles.icon}>
        <img src={disclosureIcon} />
      </div>
    </div>
  );
};
