import { Blueprint, Properties, Child, ChildcarePolicy, SchoolTypes, ExaminationTypes, LessonType, Insurance, Debt, PeriodicInvestment, House, Car, sessionStartDate, Investment, HousePurchasePlan, MovingPlan, Profession, Job, defaultCarSellPrice, JobChange, professionHasSalary, hasWork, DEFAULT_HOUSE_REFORM_COST } from "../Store";
import { Memoize } from 'typescript-memoize';
import _, { List, Many } from "lodash";
import debug from "../debug";
import { getJobSalaryTable, jobCategoryMap } from "../Store/jobs";

const CAR_LOAN_INTEREST = 0.04;
export const HOUSING_LOAN_INTEREST = 0.014;
export const HOUSING_LOAN_MONTHLY_PAYMENT_RATE = 0.003013;
export const STUDENT_LOAN_INTEREST = 0.02;
export const STUDENT_LOAN_MONTHLY_PAYMENT_RATE = 0.00642;
const HOUSING_LOAN_ADVANCE_REPAYMENT = 1000000;
const sum = (...array: (number | null | undefined | false)[]) => _.sum(_.compact(array));

export const isSameMonth = (d1: Date, d2: Date) => d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
const beginningOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);
export const yearsSince = (from: Date, now: Date) => {
  if (now.getMonth() < from.getMonth() ||
    (now.getMonth() === from.getMonth() && now.getDate() < from.getDate())) {
    return now.getFullYear() - from.getFullYear() - 1;
  } else {
    return now.getFullYear() - from.getFullYear();
  }
}

export const getAge = (birth: Date) => {
  return yearsSince(birth, sessionStartDate());
}

export const getAgeInYearEnd = (birth: Date, baseDate: Date) => {
  const yearEnd = new Date();
  yearEnd.setFullYear(baseDate.getFullYear());
  yearEnd.setMonth(11); // Dec
  yearEnd.setDate(31);
  return yearsSince(birth, yearEnd);
}

const getAgeInSchoolYearStart = (birth: Date, year: number) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(3); // Apr
  date.setDate(2);
  return yearsSince(birth, date);
}

// 現在の年と支払い完了月をもとに今年支払う月数を算出する
const getPaymentMonths = (currentYear: number, fullPaymentDate: Date) => {
  if (currentYear < fullPaymentDate.getFullYear()) {
    return 12;
  } else if (currentYear === fullPaymentDate.getFullYear()) {
    return fullPaymentDate.getMonth() + 1;
  } else {
    return 0;
  }
}

interface AgePattern {
  startAge?: number;
  endAge?: number;
}

interface RepeatedAgePattern extends AgePattern {
  spanYears?: number;
}

const matchAge = (age: number, pattern: AgePattern) => {
  return (!pattern.endAge || age < pattern.endAge) &&
    (!pattern.startAge || pattern.startAge <= age);
}

const matchRepeatedAge = (age: number, pattern: RepeatedAgePattern) => {
  return matchAge(age, pattern) &&
    pattern.spanYears &&
    ((age - (pattern.startAge || 0)) % pattern.spanYears) === 0;
}

export const formatMoney = (value: number) => {
  return new Intl.NumberFormat('ja-JP').format(Math.round(value / 10000));
}

export const formatMoneyWithUnit = (value: number) => {
  const sign = value < 0 ? "-" : "";
  const v = Math.abs(Math.round(value / 10000));
  const tenthousands = v % 10000;
  const hundredmillions = Math.floor(v / 10000);
  const f = Intl.NumberFormat('ja-JP');
  if (hundredmillions > 0) {
    return `${sign}${f.format(hundredmillions)} 億 ${f.format(tenthousands)} 万円`;
  } else {
    return `${sign}${f.format(tenthousands)} 万円`;
  }
}

// 千円単位で表示する
export const formatSmallMoneyWithUnit = (value: number) => {
  const sign = value < 0 ? "-" : "";
  const v = Math.abs(Math.round(value / 1000));
  const thousands = v % 100000;
  const hundredmillions = Math.floor(v / 100000);
  const f = Intl.NumberFormat('ja-JP');
  if (hundredmillions > 0) {
    return `${sign}${f.format(hundredmillions)} 億 ${f.format(thousands / 10)} 万円`;
  } else if (thousands > 0) {
    return `${sign}${f.format(thousands / 10)} 万円`;
  } else {
    return `${sign}${value} 円`;
  }
}

export type Event = {
  age: number;
  events: AgeEvent[];
}

export type AgeEvent = {
  title: string;
  target: "own" | "partner" | "family" | "child";
  childIndex?: number;
  icon: "person" | "baby" | "car" | "house" | "income" | "payment" | "wedding" | "work";
}

export type Review = {
  id: number;
  title: string;
  description?: string;
  page: string;
}

const earningsTable = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.02, 1.0, 1.0, 1.0, 1.0, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98];

// 年金マスター（万円／月）
const pensionTableFrom60 = {
  basic: [3.9, 4.2, 4.5, 4.8, 5.2, 5.5, 6.0, 6.4, 6.9, 7.3, 7.8],
  welfare: {
    350: [4.3, 4.7, 5.1, 5.5, 5.8, 6.2, 6.7, 7.2, 7.8, 8.3, 8.8],
    450: [5.6, 6.1, 6.6, 7.0, 7.5, 8.0, 8.7, 9.3, 10.0, 10.7, 11.4],
    550: [6.8, 7.4, 8.0, 8.5, 9.1, 9.7, 10.5, 11.3, 12.1, 13.0, 13.8],
    650: [8.1, 8.8, 9.5, 10.2, 10.9, 11.6, 12.6, 13.5, 14.5, 15.5, 16.5],
    more: [8.9, 9.7, 10.4, 11.2, 11.9, 12.7, 13.8, 14.8, 15.9, 17.0, 18.0]
  }
};

// 0 歳で4月以降が誕生日の場合は schoolAge === -1 となる場合があるので、
// このテーブルは schoolAge + 1 をインデックスとする。
const educationCostTable: Record<keyof SchoolTypes, { [key: string]: any }> = {
  preschool: {
    nursery: [25.4, 25.4, 25.4, 25.4, 33.8, 33.8, 33.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    public: [0.0, 0.0, 0.0, 0.0, 22.4, 22.4, 22.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    private: [0.0, 0.0, 0.0, 0.0, 52.8, 52.8, 52.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  },
  es: {
    public: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 32.1, 32.1, 32.1, 32.1, 32.1, 32.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    private: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 159.9, 159.9, 159.9, 159.9, 159.9, 159.9, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  },
  jhs: {
    public: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 48.8, 48.8, 48.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    private: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 140.6, 140.6, 140.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  },
  hs: {
    public: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 45.7, 45.7, 45.7, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    private: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 97.0, 97.0, 97.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    kosen: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 201.7, 144.7, 144.7, 144.7, 144.7, 0.0, 0.0, 0.0, 0.0],
  },
  university: {
    tandai: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 218.7, 158.3, 0.0, 0.0, 0.0, 0.0],
    public: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 178.4, 107.0, 107.0, 107.0, 0.0, 0.0],
    private_humanities: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 244.2, 157.6, 157.6, 157.6, 0.0, 0.0],
    private_science: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 268.8, 184.3, 184.3, 184.3, 0.0, 0.0],
  },
  graduateSchool: {
    public: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 140.0, 114.0, 0.0],
    private_humanities: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 150.0, 130.0, 0.0],
    private_science: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 180.0, 160.0, 0.0],
    overseas: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 350.0, 350.0, 0.0],
  },
};

const examinationCostTable: Record<keyof ExaminationTypes, Array<any>> = {
  es: [0.0, 0.0, 0.0, 0.0, 0.0, 40.0, 80.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  jhs: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 82.8, 123.4, 254.7, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  hs: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 9.4, 19.4, 29.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  university: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 31.3, 43.3, 51.3, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
};

const lessonCostTable = {
  more: [0.0, 0.0, 0.0, 3.8, 4.2, 4.2, 4.2, 10.7, 10.7, 10.7, 10.7, 10.7, 10.7, 7.7, 7.7, 7.7, 4.4, 4.4, 4.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  average: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  less: [0.0, 0.0, 0.0, 0.0, -2.1, -2.1, -2.1, -5.4, -5.4, -5.4, -5.4, -5.4, -5.4, -7.7, -7.7, -7.7, -4.4, -4.4, -4.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
}

const livingCostTable = {
  parent_male: 996000,
  parent_female: 1020000,
  single_male: 1698000,
  single_female: 1722000,
  no_kids: 2412000,
  kids_base: 2064000,
}

const kidsLivingCostTable =
  [444000.0, 444000.0, 444000.0, 444000.0, 607647.0, 607647.0, 523647.0, 621281.0, 621281.0, 621281.0, 621281.0, 621281.0, 621281.0, 956397.0, 968397.0, 980397.0, 985380.0, 997380.0, 1009380.0, 2444000.0, 1644000.0, 1644000.0, 1644000.0, 1644000.0, 1644000.0];

// 社会人になる年
const childGraduationAge = (policy: ChildcarePolicy) => {
  if (policy.schoolTypes.hs === "employment") {
    return { age: 15, lastSchool: "中学" }
  } else if (policy.schoolTypes.university === "employment") {
    if (policy.schoolTypes.hs === "kosen") {
      return { age: 20, lastSchool: "高専" }
    } else {
      return { age: 18, lastSchool: "高校" }
    }
  } else if (policy.schoolTypes.graduateSchool === "employment") {
    if (policy.schoolTypes.university === "tandai") {
      return { age: 20, lastSchool: "短大" }
    } else {
      return { age: 22, lastSchool: "大学" }
    }
  } else {
    return { age: 24, lastSchool: "大学院" }
  }
}

// 子供が一人暮らしを始める年
const childIndependenceAge = (policy: ChildcarePolicy) => {
  if (policy.liveAloneAge != null) {
    return policy.liveAloneAge;
  } else {
    return childGraduationAge(policy).age;
  }
}

const hasWelfarePension = (job: Job) => {
  if (_.includes(["regular_employee", "contract_worker", "public_employee"], job.profession)) {
    return true;
  }
  if ("part_time_job" === job.profession) {
    const salary = (job.monthlySalary || 0) * 12 + (job.bonus || 0);
    return salary >= 1300000;
  }
  return false;
}

const getDebtBalanceFromYears = (years: number, repayment: number, interest: number) => {
  let balance = 0;
  const monthlyInterest = Math.pow(1 + interest, 1 / 12);
  for (let i = 0; i < years * 12; i++) {
    balance += repayment / Math.pow(monthlyInterest, i);
  }
  return balance;
};

class Aggregate {
  ages?: PersonAge[];

  @Memoize()
  averageSalary(): number {
    if (this.ages) {
      const index = this.ages.findIndex((a) => a.salary() > 0);
      if (index < 0) {
        return 0;
      }
      let count = 0;
      let sum = 0;
      for (let i = index; i < this.ages.length; i++) {
        sum += this.ages[i].salary();
        count++;
      }
      let lastSalary = this.ages[index].salary();
      for (let age = this.ages[index].age - 1; age >= 22; age--) {
        if (earningsTable[age] <= 0) break; // 念のため
        const salary = lastSalary / earningsTable[age];
        sum += salary;
        count++;
        lastSalary = salary;
      }
      return sum / count;
    } else {
      throw "no records";
    }
  }
}

export class Simulator {
  blueprint: Blueprint;
  //assets: number[];
  currentAge: number;
  ages: Age[];
  carReplacements: Car[];
  movingPlans: MovingPlan[];
  debtHelpers = new WeakMap<object, DebtHelper>();
  own: Person;
  partner?: Person;
  housingLoan?: DebtHelperWithAge;

  constructor(blueprint: Blueprint) {
    debug("run simulator");
    this.blueprint = blueprint;

    const age = getAgeInYearEnd(this.blueprint.own.birthday, this.blueprint.currentDate());
    this.currentAge = age;
    let years = blueprint.own.lifespan - age + 1;
    if (years <= 3) { years = 3 };
    const events: Event[] = [];
    let ageSim: Age | null = null;
    let _ages = [];
    const aggregate = { own: new Aggregate(), partner: new Aggregate() };
    this.carReplacements = _.flatten([...blueprint.cars, ...blueprint.carPurchasePlans]
      .map(car => this.expandPeriodicCarReplacement(car)));
    this.movingPlans = [...this.blueprint.movingPlans, ...this.expandMovingPlans()];
    this.own = new Person(this, this.blueprint.own, aggregate.own);
    if (this.blueprint.hasPartner && this.blueprint.partner) {
      this.partner = new Person(this, this.blueprint.partner, aggregate.partner);
    }
    for (let i = 0, a = age; i < years; i++, a++) {
      ageSim = new Age(this, this.blueprint, a, ageSim, i, aggregate);
      _ages.push(ageSim);
    }
    aggregate.own.ages = _ages.map((a) => a.own);
    aggregate.partner.ages = _ages.map((a) => a.partner).filter((p) => p) as PersonAge[];
    this.ages = _ages;
    this.initHousingLoan();
  }

  atAge(age: number) {
    return this.ages[age - this.baseAge()];
  }

  initHousingLoan() {
    if (this.blueprint.houseOwnership === "owned" &&
      this.blueprint.housingLoanFullPayment &&
      this.blueprint.housingLoanRepayment) {
      this.housingLoan = new DebtHelperWithAge(
        new DebtHelper({
          balance: getDebtBalanceFromYears(
            -yearsSince(this.blueprint.housingLoanFullPayment, this.blueprint.currentDate()),
            this.blueprint.housingLoanRepayment,
            HOUSING_LOAN_INTEREST),
          monthlyPayment: this.blueprint.housingLoanRepayment,
          interest: HOUSING_LOAN_INTEREST,
        }),
        this.currentAge);
    }
    if (!this.housingLoan &&
        this.blueprint.planningToBuyHouse === "yes" &&
        this.blueprint.housePurchasePlan &&
        this.blueprint.housePurchasePlan.age &&
        this.blueprint.housePurchasePlan.price) {
      let age = this.atAge(this.blueprint.housePurchasePlan.age);
      if (!age && this.ages[0].age - 1 === this.blueprint.housePurchasePlan.age) {
        // 年末時点での年齢 (this.ages[0].age) より -1 歳の値が入力されている場合は、
        // 今年購入予定として計算する
        age = this.ages[0];
      }
      if (age) {
        const downPayment = age.housingLoanDownPayment();
        const balance = this.blueprint.housePurchasePlan.price - downPayment;
        this.housingLoan = new DebtHelperWithAge(
          new DebtHelper({
            balance: balance,
            monthlyPayment: balance * HOUSING_LOAN_MONTHLY_PAYMENT_RATE,
            interest: HOUSING_LOAN_INTEREST,
          }, this.blueprint.own.birthday.getMonth()),
          this.blueprint.housePurchasePlan.age);
      }
    }
  }

  baseYear() {
    return this.blueprint.currentDate().getFullYear();
  }

  baseAge() {
    return getAgeInYearEnd(this.blueprint.own.birthday, this.blueprint.currentDate());
  }

  initialSavings() {
    let savings = this.blueprint.own.savings || 0;
    if (this.partner) {
      savings += this.partner.properties.savings || 0;
    }
    return savings;
  }

  /*
    家族構成に応じて住み替える

    子どもが生まれるごとに +2 万円家賃を増やす
    子ども全員が独立したら -4 万円する（0 以下にはしない）
    子どもがいない場合は 65 歳になったら -3 万円する（0 以下にはしない）
  */
  expandMovingPlans(): MovingPlan[] {
    if (!this.baseAge()) return [];
    const lastPlan = _.last(this.blueprint.movingPlans);
    if (!lastPlan || !lastPlan.auto) return [];
    let lastRent = this.blueprint.houseOwnership === "rental" ? this.blueprint.rent : null;
    let lastMovingPlan = _.maxBy(this.blueprint.movingPlans.filter(p => !p.auto), p => p.age || 0);
    if (this.blueprint.houseOwnership === "owned" || this.blueprint.planningToBuyHouse === "yes") {
      // 家を購入済みまたは購入予定で、売却予定もない場合はスキップする
      if (!this.blueprint.planningToSellHouse) return [];
      // 家を売却後に引越し予定がない場合は家賃の算定ができないのでスキップする
      if (!this.blueprint.sellHouseAge ||
        (lastMovingPlan && lastMovingPlan.age && lastMovingPlan.age < this.blueprint.sellHouseAge)) return [];
    }
    const minIndex = lastMovingPlan && lastMovingPlan.age ? lastMovingPlan.age - this.baseAge() : 0;
    if (lastMovingPlan && lastMovingPlan.rent) lastRent = lastMovingPlan.rent;
    if (!lastRent) return [];
    let plans: MovingPlan[] = [];
    if (this.blueprint.wishChild === "yes") {
      plans = this.blueprint.desiredChildren
        .filter(c => c.birthday && c.birthday.getFullYear() - this.baseYear() >= minIndex)
        .map((c, i) => ({
          auto: false,
          age: this.baseAge() + c.birthday.getFullYear() - this.baseYear(),
          movingCost: lastMovingPlan ? lastMovingPlan.movingCost : 200000,
          rent: lastRent! + 20000 * (i + 1),
          rentRenewalFee: lastMovingPlan ? lastMovingPlan.rentRenewalFee : this.blueprint.rentRenewalFee,
        }));
    }
    const yearsUntilLastChildBorn = _.max([
      ...(this.blueprint.hasChild ? this.blueprint.currentChildren : []),
      ...(this.blueprint.wishChild === "yes" ? this.blueprint.desiredChildren : [])
    ].filter(c => c.birthday).map(c => c.birthday.getFullYear() - this.baseYear()));
    if (yearsUntilLastChildBorn != null) {
      const lastPlan = _.last(plans);
      plans = [
        ...plans,
        {
          auto: false,
          age: this.baseAge() + yearsUntilLastChildBorn! + childIndependenceAge(this.blueprint.childcarePolicy),
          movingCost: lastMovingPlan ? lastMovingPlan.movingCost : 200000,
          rent: _.max([(lastPlan && lastPlan.rent ? lastPlan.rent : lastRent!) - 40000, 0]),
          rentRenewalFee: lastMovingPlan ? lastMovingPlan.rentRenewalFee : this.blueprint.rentRenewalFee,
        }
      ];
    } else if (plans.length === 0) {
      if (this.baseAge() + minIndex < 65) {
        plans = [
          {
            auto: false,
            age: 65,
            movingCost: lastMovingPlan ? lastMovingPlan.movingCost : 200000,
            rent: _.max([lastRent! - 30000, 0]),
            rentRenewalFee: lastMovingPlan ? lastMovingPlan.rentRenewalFee : this.blueprint.rentRenewalFee,
          }
        ]
      }
    }
    return plans;
  }

  expandPeriodicCarReplacement(car: Car): Car[] {
    if (car.sellPlan === "periodic" &&
      car.price &&
      car.purchaseAge != null &&
      car.purchaseAge < this.releaseCarAge() &&
      car.purchaseAge < this.sellCarAge(car) &&
      this.sellCarAge(car) < this.releaseCarAge()) {
      const years = this.sellCarAge(car) - car.purchaseAge;
      const nextCar = {
        ...car,
        price: car.price * Math.pow(this.blueprint.inflationRatio, years),
        sellPrice: car.sellPrice != undefined ? car.sellPrice * Math.pow(this.blueprint.inflationRatio, years) : undefined,
        purchaseAge: this.sellCarAge(car),
        sellAge: this.sellCarAge(car) + years
      };
      return _.compact([nextCar, ...this.expandPeriodicCarReplacement(nextCar)]);
    } else {
      return [];
    }
  }

  releaseCarAge() {
    return _.max(_.compact([
      this.blueprint.maxDrivingAge,
      this.blueprint.partner &&
      (this.blueprint.maxDrivingAge + 
        getAge(this.blueprint.own.birthday) - getAge(this.blueprint.partner.birthday))]))!;
  }

  sellCarAge(car: Car) {
    if (car.sellAge != null && car.sellPlan !== "none") {
      return _.min([car.sellAge, this.releaseCarAge()])!;
    } else {
      return this.releaseCarAge();
    }
  }

  debtHelper(key: any, debt: Debt, fromMonth: number = 0): DebtHelper {
    let helper = this.debtHelpers.get(key);
    if (!helper) {
      helper = new DebtHelper(debt, fromMonth);
      this.debtHelpers.set(key, helper);
    }
    return helper;
  }

  @Memoize()
  events() {
    const events: Event[] = [];
    let maxIncomeAge: Age = this.ages[0];
    this.ages.forEach(age => {
      if (age.income() > maxIncomeAge.income()) {
        maxIncomeAge = age;
      }
    });
    for (let i = 0; i < this.ages.length; i++) {
      const ageEvents = this.ages[i].events();
      if (maxIncomeAge === this.ages[i]) {
        ageEvents.push({
          title: "世帯収入が最高額",
          target: "family",
          icon: "income",
        });
      }
      if (ageEvents.length > 0) {
        events.push({ age: this.ages[i].age, events: ageEvents });
      }
    }
    return events;
  }

  commentForChart() {
    if (this.ages.length === 0) {
      return "";
    }
    let min = this.ages[0];
    let max = this.ages[0];
    this.ages.forEach(age => {
      if (max.asset() <= age.asset()) max = age;
      if (age.asset() <= min.asset()) min = age;
    });
    if (min.asset() < 0) {
      return `診断による資産額の予測です。 ${max.age} 歳で ${formatMoneyWithUnit(max.asset())}とピークになる一方、 ${min.age} 歳時に最大 ${formatMoneyWithUnit(-min.asset())}マイナスになります。プラン編集から支出や収入を見直してみましょう。`
    } else {
      return "診断から資産額を予測すると、生涯を通じて資産がマイナスになるタイミングはありませんでした。イベントと収支を確認してみましょう。"
    }
  }

  commentForSettings() {
    if (this.ages.length === 0) {
      return "";
    }
    const age = _.last(this.ages)!;
    if (age.asset() < 0) {
      return `寿命予想の ${age.age} 歳までで ${formatMoneyWithUnit(-age.asset())}が足りなくなる試算です。以下の設定を変えてプラマイゼロに近づけていきましょう。`;
    } else if (age.asset() < 500) {
      return `寿命予想の ${age.age} 歳には資産が ${formatMoneyWithUnit(age.asset())}、残る予想です。まだ反映していない「やりたいこと」があれば、設定してみましょう！`;
    } else {
      return `寿命予想の ${age.age} 歳には資産が ${formatMoneyWithUnit(age.asset())}、残る予想です。余裕がありますので生前贈与やレジャー費を増やすことも検討してみましょう。`;
    }
  }

  reviews(): Review[] {
    const month = sessionStartDate().getMonth() + 1;
    return _.compact(_.flatten(
      [
        !isSameMonth(sessionStartDate(), this.blueprint.createdDate!) && [
          month === 1 &&
          {
            id: 20,
            title: "給与の手取りに変化はありましたか？",
            description: "昇給などがあれば手取りの月収に反映ください",
            page: "work"
          },
          month === 1 &&
          this.blueprint.hasDebt &&
          {
            id: 14,
            title: "借入金の残高に変化はありますか？",
            description: "予定どおり返済が進んでいるかをご確認ください",
            page: "living"
          },
          month === 2 &&
          (!_.isEmpty(this.blueprint.own.tsumitateNisa) ||
            !_.isEmpty(this.blueprint.partner?.tsumitateNisa)) &&
          {
            id: 25,
            title: "つみたて NISA の状況に変化はありますか？",
            description: "利回りや積立金額が変わっていたら修正ください",
            page: "asset"
          },
          month === 2 &&
          (this.blueprint.hasChild && this.blueprint.numCars === 0 &&
            this.blueprint.planningToBuyCar === "unknown") &&
          {
            id: 8,
            title: "クルマの購入を検討していますか？",
            description: "仕事・家庭環境の変化がきっかけになることも",
            page: "car"
          },
          month === 3 &&
          this.blueprint.movingPlans.find(plan => plan.age === this.ages[0].age) &&
          {
            id: 10,
            title: "引っ越しましたか？",
            description: "今年、別の住まいに住み替える計画でした",
            page: "house"
          },
          month === 3 &&
          {
            id: 22,
            title: "新しく始めた投資はありますか？",
            description: "投資信託や NISA を始めた場合は反映ください",
            page: "asset"
          },
          month === 4 &&
          (this.blueprint.hasChild &&
            _.find(this.ages[0].children, (child) => child.schoolAge <= 12)) &&
          {
            id: 31,
            title: "子育てに関連し、働き方に変化はありましたか？",
            description: "育休期間や勤務時間が変わったら更新ください",
            page: "work"
          },
          month === 4 &&
          (!_.isEmpty(this.blueprint.own.nisa) ||
            !_.isEmpty(this.blueprint.partner?.nisa)) &&
          {
            id: 23,
            title: "NISA の状況に変化はありますか？",
            description: "積立金額や利回りが変わっていたら反映ください",
            page: "asset"
          },
          month === 5 &&
          {
            id: 16,
            title: "日常費に変化はありますか？",
            description: "Zaim に記録した家計簿を自動で反映できます",
            page: "living"
          },
          month === 6 &&
          this.blueprint.hasChild &&
          {
            id: 29,
            title: "子の教育方針に変化はありますか？",
            description: "入学する学校や習い事を反映させてください",
            page: "child"
          },
          month === 6 &&
          this.blueprint.marriageAge === this.ages[0].age &&
          {
            id: 13,
            title: "結婚の希望時期に変化はありましたか？",
            description: "今年、結婚する予定になっていました",
            page: "otherExpense"
          },
          month === 7 &&
          {
            id: 17,
            title: "家族に変化はありますか？",
            description: "子の誕生や同居人の増減があれば反映ください",
            page: "family"
          },
          month === 7 &&
          (this.blueprint.own.sideJobs.find(j => j.started === false && j.startAge === this.ages[0].age) ||
           this.blueprint.partner && this.ages[0].partner &&
           this.blueprint.partner.sideJobs.find(j => j.startAge === this.ages[0].partner?.age)) &&
          {
            id: 18,
            title: "副業で収入を得ていますか？",
            description: "今年、副業を始める予定でした",
            page: "work"
          },
          month === 8 &&
          (this.blueprint.houseOwnership === "owned" && this.housingLoan != null) &&
          {
            id: 12,
            title: "家のローンに変化はありましたか？",
            page: "house"
          },
          month === 8 &&
          this.blueprint.hasDebt &&
          _.find(this.blueprint.debt, (debt) =>
            debtFullPaymentDate(debt, this.blueprint.createdDate!).getFullYear() === sessionStartDate().getFullYear()) &&
          {
            id: 15,
            title: "借入金は完済しましたか？",
            description: "今年、借入金の返済が終わることになっています",
            page: "living"
          },
          month === 9 &&
          this.blueprint.partner &&
          {
            id: 28,
            title: "配偶者の年金額に変化はありますか？",
            description: "年収や雇用形態が変わると年金額も変わります",
            page: "retirement"
          },
          month === 9 &&
          this.blueprint.hasChild && this.blueprint.houseOwnership !== "owned" &&
          {
            id: 11,
            title: "家の購入を検討していますか？",
            description: "購入者の 3 割が結婚・子どもの出生がきっかけ",
            page: "home"
          },
          month === 10 &&
          (!_.isEmpty(this.blueprint.own.ideco) ||
            !_.isEmpty(this.blueprint.partner?.ideco)) &&
          {
            id: 24,
            title: "iDeCo の状況に変化はありますか？",
            description: "積立金額や利回りが変わっていたら反映ください",
            page: "asset"
          },
          month === 10 &&
          (this.ages[0].own.isChildcareLeaveEndAge() ||
           (this.ages[0].partner && this.ages[0].partner.isChildcareLeaveEndAge())) &&
          {
            id: 19,
            title: "育休から仕事に復帰しましたか？",
            description: "プランでは今年復帰することになっています",
            page: "work"
          },
          month === 11 &&
          (_.find(this.blueprint.own.jobChanges, (job) => job.age === this.ages[0].age) ||
            (this.ages[0].partner && this.blueprint.partner &&
              _.find(this.blueprint.partner.jobChanges, (job) => job.age === this.ages[0].partner!.age))) &&
          {
            id: 21,
            title: "予定どおり転職しましたか？",
            description: "今年、転職するかもしれないという計画でした",
            page: "work"
          },
          month === 11 &&
          {
            id: 27,
            title: "あなたの年金額に変化はありますか？",
            description: "年収や雇用形態が変わると年金額も変わります",
            page: "retirement"
          },
          month === 12 &&
          _.find(this.blueprint.carPurchasePlans, (car) => car.purchaseAge === this.ages[0].age) &&
          {
            id: 9,
            title: "クルマは買い替えましたか？",
            description: "今年、買い換える予定になっていました",
            page: "car"
          },
          month === 12 &&
          (!_.isEmpty(this.blueprint.own.periodicInvestments) ||
            !_.isEmpty(this.blueprint.partner?.periodicInvestments)) &&
          {
            id: 26,
            title: "積立投資の状況に変化はありますか？",
            description: "積立金額や利回りが変わっていたら反映ください",
            page: "asset"
          },
          _.includes([1, 4, 7, 10], month) &&
          {
            id: 5,
            title: "予定どおり貯金できていますか？",
            description: "今の預貯金と照らし合わせてみてください",
            page: "asset"
          },
          _.includes([2, 5, 8, 11], month) &&
          {
            id: 6,
            title: "臨時の支出は、ありましたか？",
            description: "予定外の支出が発生していたら反映ください",
            page: "otherExpense"
          },
          _.includes([3, 6, 9, 12], month) &&
          {
            id: 7,
            title: "臨時の収入の予定は、ありますか？",
            description: "予定外にもらえたお金があれば反映ください",
            page: "otherIncome"
          }
        ],
        _.last(this.ages)!.asset() < 0 &&
          {
            id: 1,
            title: "赤字を改善しましょう",
            description: "生涯の収支が赤字です。収入を見直してみませんか？",
            page: "work"
          },
        isSameMonth(sessionStartDate(), this.blueprint.createdDate!) && [
          {
            id: 2,
            title: "投資を入力してみましたか？",
            description: "まだ投資経験がなければチェックして完了です",
            page: "asset"
          },
          {
            id: 3,
            title: "加入している保険を登録しましたか？",
            description: "加入していなければチェックして完了です",
            page: "living"
          },
        ],
      ]));
  }

  totalChildExpense() {
    return _.sum(this.ages.map(a => a.childExpense()));
  }

  totalHouseCost() {
    return _.sum(this.ages.map(a => a.houseCost()));
  }

  totalCarCost() {
    return _.sum(this.ages.map(a => a.carCost()));
  }

  totalLivingCost() {
    return _.sum(this.ages.map(a => a.livingCostEtc()));
  }

  totalOtherExpense() {
    return _.sum(this.ages.map(a => a.otherExpense()));
  }

  totalWorkIncome() {
    return _.sum(this.ages.map(a => a.workIncome()));
  }

  totalPension() {
    return _.sum(this.ages.map(a => a.pension()));
  }

  totalOtherIncome() {
    return _.sum(this.ages.map(a => a.otherIncome()));
  }

  totalInvestmentProfit() {
    return _.sum(this.ages.map(a => a.investmentProfit()));
  }

  totalAssetAndInvestmentProfit() {
    return this.initialSavings() + this.totalInvestmentProfit();
  }
}

export class Person {
  properties: Properties;
  jobChanges: JobChange[];
  simulator: Simulator;
  aggregate: Aggregate;
  name: string;
  studentLoanKey = {};

  constructor(simulator: Simulator, properties: Properties, aggregate: Aggregate) {
    this.simulator = simulator;
    this.properties = properties;
    this.aggregate = aggregate;
    this.name = this.properties === simulator.blueprint.own ? "own" : "partner";
    if (this.properties.jobChanges.length === 0) {
      this.jobChanges = this.employmentFromStudent();  
    } else {
      this.jobChanges = properties.jobChanges;
    }
  }

  employmentFromStudent(): JobChange[] {
    if (this.properties.profession === "student" && this.properties.birthday &&
      getAgeInYearEnd(this.properties.birthday, this.simulator.blueprint.currentDate()) <= 22) {
      return [{
        age: 23,
        monthlySalary: 250000,
        bonus: 0,
        profession: "regular_employee"
      }];
    } else {
      return [];
    }
  }

  basicPensionPerYear() {
    const index = Math.min(Math.max(this.properties.pensionBenefitAge - 60, 0), 10)
    return pensionTableFrom60.basic[index] * 10000 * 12;
  }

  welfarePensionPerYear() {
    if ((hasWelfarePension(this.properties) ||
      this.jobChanges.find(job => hasWelfarePension(job)))) {
      const averageSalary = this.aggregate.averageSalary();
      const index = Math.min(Math.max(this.properties.pensionBenefitAge - 60, 0), 10)
      const salary = averageSalary / 10000;
      const key = salary <= 350 ? "350" :
        salary <= 450 ? "450" :
          salary <= 550 ? "550" :
            salary <= 650 ? "650" : "more";
      return pensionTableFrom60.welfare[key][index] * 10000 * 12;
    } else {
      return 0;
    }
  }

  nationalPensionFromUserDetail() {
    const withTax = 0.875;
    const pensionWithBenefitAge = (pension: number, benefitAge: number) => {
      const ageDiff = _.clamp(benefitAge - 65, -5, 5);
      if (ageDiff >= 0) {
        return pension * (1 + 0.007 * ageDiff) * withTax;
      } else {
        return pension * (1 + 0.005 * ageDiff) * withTax;
      }
    }
    // 年金番号を選択した場合
    const pn = this.properties.basicPensionNumber;
    if (pn) {
      const pension = this.simulator.blueprint.estimate.pension?.
        find((p) => p.basicPensionNumber === pn)?.annualPension;
      if (pension) {
        return pensionWithBenefitAge(pension, this.properties.pensionBenefitAge);
      }
    }
    // 連携データが１つしかないばあいはこちらを利用する
    if (this.simulator.own === this &&
        this.simulator.blueprint.estimate.pension?.length === 1) {
      return pensionWithBenefitAge(
        this.simulator.blueprint.estimate.pension[0].annualPension,
        this.properties.pensionBenefitAge);
    }
    // 旧インポートデータ用
    if (this.simulator.blueprint.estimate.annualPension &&
      this.simulator.own === this) {
      return pensionWithBenefitAge(
        this.simulator.blueprint.estimate.annualPension,
        this.properties.pensionBenefitAge);
    }
    return null;
  }

  nationalPension() {
    const withTax = 0.875;
    const pension = this.nationalPensionFromUserDetail();
    if (pension != null) {
      return pension;
    } else {
      return (this.basicPensionPerYear() +
        this.welfarePensionPerYear()) * withTax;
    }
  }
}

class InvestmentHelper {
  investment: PeriodicInvestment | Investment;
  age: number;
  taxRate: number;
  lastValuation: number = 0;

  constructor(investment: PeriodicInvestment | Investment, age: number, taxRate: number) {
    this.investment = investment;
    this.age = age;
    this.taxRate = taxRate;
  }

  interestRate() {
    // 計算を単純にするため、利率から税金分を差し引く
    return this.applyTax(this.investment.yield ? this.investment.yield / 100 : 0.03);
  }

  valuation() {
    if (this.investment.sellAge && this.investment.sellAge <= this.age) {
      return 0; // 売却後は0にする
    } else {
      return this.sellValuation();
    }
  }

  salesAmount() {
    if (this.investment.sellAge === this.age) {
      return this.sellValuation();
    } else {
      return 0;
    }
  }

  applyTax(value: number) {
    return value * (1.0 - this.taxRate);
  }

  sellValuation() {
    return this.lastValuation * (1.0 + this.interestRate()) + this.yearlyInvestment();
  }

  profit() {
    return this.salesAmount()
      + this.valuation()
      - this.yearlyInvestment()
      - this.lastValuation;
  }

  yearlyInvestment() {
    return 0;
  }
}

class SpotInvestmentHelper extends InvestmentHelper {
  investment: Investment;

  constructor(investment: Investment, age: number, taxRate: number) {
    super(investment, age, taxRate);
    this.investment = investment;
  }

  // シミュレーション開始の前年度までの評価額を設定する 
  initializePastValuation() {
    if (this.investment.purchasePrice &&
      // purchased === false であっても年齢が過去であれば購入済みとみなす
      (this.investment.purchased ||
        this.investment.purchaseAge && this.investment.purchaseAge < this.age) &&
      // 売却済みの投資はスキップする
      (!this.investment.sellAge ||
        this.age <= this.investment.sellAge)) {
      const age = this.investment.purchaseAge || (this.age - 1);
      const years = Math.max(this.age - age, 1);
      this.lastValuation = this.investment.purchasePrice * ((1.0 + this.interestRate()) ** (years - 1));
    }
  }

  yearlyInvestment() {
    if (this.investment.purchasePrice && this.investment.purchaseAge &&
      !this.investment.purchased) {
      if (this.investment.purchaseAge === this.age) {
        return this.investment.purchasePrice;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
}

class PeriodicInvestmentHelper extends InvestmentHelper {
  investment: PeriodicInvestment;

  constructor(investment: PeriodicInvestment, age: number, taxRate: number) {
    super(investment, age, taxRate);
    this.investment = investment;
  }

  // シミュレーション開始の前年度までの評価額を設定する 
  initializePastValuation() {
    if (this.investment.monthlyInvestment &&
      // purchased === false であっても年齢が過去であれば購入済みとみなす
      (this.investment.started ||
        this.investment.startAge && this.investment.startAge < this.age) &&
      // 売却済みの投資はスキップする
      (!this.investment.sellAge ||
        this.age <= this.investment.sellAge)) {
      const startAge = this.investment.startAge || (this.age - 1);
      const years = Math.max(this.age - startAge, 1);
      let v = 0;
      for (let i = 0; i < years; i++) {
        v += this.investment.monthlyInvestment * 12 * ((1.0 + this.interestRate()) ** i);
      }
      this.lastValuation = v;
    }
  }

  yearlyInvestment() {
    if (this.investment.monthlyInvestment &&
      (!this.investment.startAge || this.investment.startAge <= this.age) &&
      this.investment.endAge && this.age < this.investment.endAge) {
      return this.investment.monthlyInvestment * 12;
    } else {
      return 0;
    }
  }
}

class PersonAge {
  person: Person;
  age: number;
  lastAge: PersonAge | null;
  _income?: number;
  properties: Properties;
  ageSim: Age;
  investments: (PeriodicInvestmentHelper | SpotInvestmentHelper)[];

  constructor(ageSim: Age, person: Person, age: number, lastAge: PersonAge | null) {
    this.ageSim = ageSim;
    this.person = person;
    const properties = this.properties = person.properties;
    this.age = age;
    this.lastAge = lastAge;
    this.investments = [
      ...properties.periodicInvestments.map(inv => new PeriodicInvestmentHelper(inv, age, 0.2)),
      ...properties.ideco.map(inv => new PeriodicInvestmentHelper(inv, age, 0)),
      ...properties.nisa.map(inv => new PeriodicInvestmentHelper(inv, age, 0)),
      ...properties.tsumitateNisa.map(inv => new PeriodicInvestmentHelper(inv, age, 0)),
      ...properties.dc.map(inv => new PeriodicInvestmentHelper(inv, age, 0)),
      ...properties.investments.map(inv => new SpotInvestmentHelper(inv, age, 0.2)),
    ];
    if (lastAge) {
      this.investments.forEach((inv, i) => {
        inv.lastValuation = lastAge.investments[i].valuation();
      })
    } else {
      this.investments.forEach((inv, i) => {
        inv.initializePastValuation();
      })
    }
  }

  events() {
    const name = this.properties === this.ageSim.blueprint.own ?
      "あなた" : "配偶者";
    const target = this.properties === this.ageSim.blueprint.own ?
      "own" : "partner";
    const job = this.currentJob();
    return (_.flattenDeep<string | false>([
      this.properties.gender === "female" &&
      hasWork(job) && job.profession !== "part_time_job" &&
        this.ageSim.children.filter(c => !c.born && c.age === 0).map(c => "産休:baby"),
      this.isChildcareLeaveEndAge() && "育休復帰:work",
      hasWork(job) && [
        job.profession !== "part_time_job" &&
          this.properties.workAfterBirth === "retire" &&
          this.ageSim.children.slice(0,1).filter(c => !c.born && c.age === 0).map(c => "退職:work"),
        (this.properties.reemploymentAge === this.age ||
        (this.properties.reemploymentAge == null && this.properties.retirementAge === this.age)) &&
          "定年:work",
        this.properties.reemploymentAge != null && this.properties.retirementAge != null &&
        this.properties.reemploymentAge !== this.properties.retirementAge && [
          this.properties.reemploymentAge === this.age && "再雇用:work",
          this.properties.retirementAge === this.age && "退職:work",
        ]
      ],
      this.person.jobChanges.filter(job => job.age === this.age && professionHasSalary(job.profession)).map((job, i) =>
        this.properties.workAfterBirth === "retire" && job.profession === "part_time_job" ? "パートを開始:work" : 
        i === 0 && this.properties.profession === "student" ? "就職:work" :
        "転職:work"),
      this.ageSim.blueprint.isStudentMode && this.properties.startWorkingAge === this.age &&
        professionHasSalary(this.properties.profession) && "就職:work",
      this.properties.pensionBenefitAge === this.age && "年金受給を開始:person",
      this.properties.lifespan === this.age && "寿命を迎える:person",
      !_.isEmpty(this.properties.ideco) && 60 === this.age && "iDeCo 満期:income",
      !_.isEmpty(this.properties.dc) && 60 === this.age && "企業型確定拠出年金の満期:income",
      !_.isEmpty(this.ageSim.cars) && this.ageSim.blueprint.maxDrivingAge === this.age && "自動車免許を返納:car",
    ]).filter(Boolean) as string[]).map(value => {
      const [title, icon] = value.split(":");
      return { title, target, icon };
    }) as AgeEvent[];
  }

  alive() {
    return this.age <= this.properties.lifespan;
  }

  pension() {
    if (!this.alive()) return 0;
    let pension = 0;
    if (this.person.properties.pensionBenefitAge <= this.age) {
      if (this.person.properties.customMonthlyPension != null) {
        pension = this.person.properties.customMonthlyPension * 12;
      } else {
        pension = this.person.nationalPension();
      }
    }
    return pension + this.corporatePensionBenefits();
  }

  // 投資の売却金額
  investmentSalesAmount() {
    return sum(
      ...this.investments.map(inv => inv.salesAmount()),
    )
  }

  investmentValuation() {
    return sum(...this.investments.map(v => v.valuation()));
  }

  yearlyInvestment() {
    return sum(...this.investments.map(v => v.yearlyInvestment()));
  }

  investmentProfit() {
    return sum(...this.investments.map(v => v.profit()));
  }

  // 産休＋育休
  _totalChildcareLeaveMonths() {
    return (this.properties.gender === "female" ?
      (this.properties.maternityLeaveMonths || 0) : 0) +
      (this.properties.childcareLeaveMonths || 0);
  }

  isChildcareLeaveEndAge() {
    if (this.properties.workAfterBirth !== "retire" &&
      this.properties.childcareLeaveMonths &&
      this.properties.childcareLeaveMonths >= 6) {
      const desiredChildren = this.ageSim.children.filter(c => !c.born);
      const months = this._totalChildcareLeaveMonths();
      return desiredChildren.map(child => {
        if (0 <= child.age && child.child.birthday) {
          const firstMonth = child.child.birthday.getMonth();
          const totalMonthsFromYearStart = firstMonth + months;
          return Math.floor(totalMonthsFromYearStart / 12) === child.age;
        }
      }).some(v => v) || false;
    }
    return false;
  }

  /* 年収から産休・育休分などをマイナスする */
  @Memoize()
  salary(): number {
    let salary = this.baseSalary();
    if (this.properties.workAfterBirth !== "retire") {
      const desiredChildren = this.ageSim.children.filter(c => !c.born);
      const months = this._totalChildcareLeaveMonths();
      const years = Math.ceil(months / 12);
      const child = desiredChildren.reverse().find(c => 0 <= c.age && c.age < years);
      if (child) {
        const m = Math.min(months / 12 - child.age, 1);
        salary -= this.baseSalary() * m;
      }
      if (this.properties.reduceWorkAfterBirth &&
        this.properties.reducedWorkSalaryPercent &&
        this.properties.reducedWorkYears &&
        desiredChildren.find(c => 0 <= c.age && c.age < this.properties.reducedWorkYears!)) {
        salary *= this.properties.reducedWorkSalaryPercent / 100;
      }
    }
    return salary;
  }

  /*
    年収
    現在の年収から、年齢があがるごとに年収マスターの係数を掛ける。
    わたしの転職イベントがあれば年収が変化する（配偶者の転職イベントはない）。
    再雇用された年は0.5をかける。完全リタイア後は0になる。
  */
  @Memoize()
  baseSalary(): number {
    const getJobSalary = (job: Job, startAge: number | null): number | null => {
      if (professionHasSalary(job.profession)) {
        if (job.useJobName && job.jobCategory && job.jobName) {
          const salaryTable = getJobSalaryTable(job.jobCategory, job.jobName);
          if (salaryTable) {
            // テーブルを使う場合は昇給率を使わない。
            const index = _.clamp(this.age - 19, 0, 82);
            return salaryTable[index] * 10000 * 0.8;
          } else {
            // jobName がおかしい
            return 0;
          }
        } else {
          if (startAge === null || startAge === this.age) {
            // テーブルを使わない場合は設定された収入を転職時の年収とする。
            // その後の昇給は昇給率を使う。
            return (job.monthlySalary || 0) * 12 +
              (job.bonus || 0);
          } else {
            return null;
          }
        }
      } else {
        return 0;
      }
    }

    if (this.age < this.person.properties.retirementAge) {
      // 再雇用される年なら50%に減額
      if (this.age === this.person.properties.reemploymentAge) {
        if (this.lastAge) {
          return this.lastAge.baseSalary() * 0.5;
        } else {
          return 0;
        }
      }
      // 出産後専業主婦・主夫になるなら初出産の年は空
      if (this.properties.workAfterBirth === "retire" &&
        this.ageSim.children.filter(c => !c.born)[0]?.age === 0) {
        return 0;
      }
      // 転職する年齢なら転職後の年収
      for (const job of [...this.person.jobChanges].reverse()) {
        if (job.age && job.age <= this.age) {
          const salary = getJobSalary(job, job.age);
          if (salary != null) return salary;
        }
      }

      /*
      現在なら現在の年収、
      前年が空なら今年も空、
      、
      完全リタイアする年なら空、
      */

      // 55歳以上なら2%減少（減給率）
      if (this.lastAge && this.age >= 55) {
        return this.lastAge.baseSalary() * 0.98;
      }

      // 出産後キャリアを重視しないならその後の昇給は本来の 25％、通常は昇給率を掛ける
      if (this.lastAge) {
        if (this.properties.workAfterBirth === "soft" &&
          this.ageSim.children[0]?.age >= 0) {
          return this.lastAge.baseSalary() * this.properties.salarySoftIncreaseRatio;
        } else {
          return this.lastAge.baseSalary() * this.properties.salaryHardIncreaseRatio;
        }
      }

      return getJobSalary(this.person.properties, null) ?? 0;
    } else {
      return 0;
    }
  }

  sideJobIncome() {
    return this.properties.sideJobs
      .filter(job =>
        (job.started || (job.startAge && job.startAge <= this.age)) &&
        (job.endAge == null || this.age < job.endAge))
      .map(job => (job.monthlyIncome || 0) * 12)
      .reduce((sum, income) => sum + income, 0);
  }

  // 出産手当金
  maternityAllowance() {
    if (this.properties.workAfterBirth !== "retire" &&
      this.properties.maternityLeaveMonths &&
      hasWelfarePension(this.currentJob())) {
      const desiredChildren = this.ageSim.children.filter(c => !c.born);
      const child = desiredChildren.find(c => c.age === 0);
      if (child) {
        return this.baseSalary() * 0.67 * this.properties.maternityLeaveMonths / 12;
      }
    }
    return 0;
  }

  // 育児休業給付金
  childcareLeaveBenefits() {
    if (this.properties.workAfterBirth !== "retire" &&
      this.properties.childcareLeaveMonths &&
      hasWelfarePension(this.currentJob())) {
      const desiredChildren = this.ageSim.children.filter(c => !c.born);
      const months = this._totalChildcareLeaveMonths();
      const years = Math.ceil(months / 12);
      const child = desiredChildren.reverse().find(c => 0 <= c.age && c.age < years);
      if (child) {
        let m = Math.min(months / 12 - child.age, 1);
        if (child.age === 0) {
          // 産休分を引く
          if (this.properties.gender === "female" &&
            (this.properties.maternityLeaveMonths || 0) > 0) {
            m -= this.properties.maternityLeaveMonths! / 12;
          }
        }
        return this.baseSalary() * 0.6 * m;
      }
    }
    return 0;
  }

  // 仕事に関する収入
  workIncome() {
    return this.salary() +
      this.severancePay() +
      this.sideJobIncome() +
      this.maternityAllowance() +
      this.childcareLeaveBenefits();
  }


  currentJob(): Job {
    let profession: Profession | null = null;
    const job = this.person.jobChanges.filter(job => job.age != null)
      .sort((a, b) => b.age! - a.age!)
      .find(job => job.age! >= this.age);
    return job || this.properties;
  }

  // 企業年金
  corporatePensionBenefits() {
    if (this.person.properties.hasCorporatePension &&
      this.person.properties.corporatePensionStartAge! <= this.age &&
      this.age < this.person.properties.corporatePensionEndAge!) {
      return this.person.properties.corporatePensionBenefit! * 12;
    } else {
      return 0;
    }
  }

  // 退職金
  severancePay() {
    if (this.person.properties.hasSeverancePay &&
      this.person.properties.severancePay &&
      this.age === this.person.properties.severancePayAge) {
      return this.person.properties.severancePay;
    } else {
      return 0;
    }
  }

  // 奨学金
  studentLoan() {
    if (this.properties.hasStudentLoanRepayment) {
      if (this.properties.studentLoanMonthlyAmount && this.properties.studentLoanReceivingMonths &&
        this.properties.startWorkingAge) {
        const balance = this.properties.studentLoanMonthlyAmount * this.properties.studentLoanReceivingMonths;
        const debt = this.ageSim.simulator.debtHelper(
          this.person.studentLoanKey,
          {
            balance,
            interest: STUDENT_LOAN_INTEREST,
            monthlyPayment: balance * STUDENT_LOAN_MONTHLY_PAYMENT_RATE
          }, 10);
        const index = this.age - this.properties.startWorkingAge;
        if (index >= 0) {
          return debt.annualPayment(index);
        } else {
          return 0;
        }
      }
      if (this.properties.studentLoanRepayment && this.properties.studentLoanFullPayment) {
        return this.properties.studentLoanRepayment * 
          getPaymentMonths(this.ageSim.year(), this.properties.studentLoanFullPayment);
      }
    }
    return 0;
  }

  // ===== 支出
  fixedExpense() {
    return this.studentLoan();
  }

  specialExpense() {
    return sum(
      this.person.properties.lifespan === this.age &&
      this.ageSim.blueprint.funeralCost
    );
  }
}

class InsuranceHelper {
  insurance: Insurance;
  age: number;

  constructor(insurance: Insurance, age: number) {
    this.insurance = insurance;
    this.age = age;
  }

  annualPayment() {
    if ((!this.insurance.startAge || this.insurance.startAge <= this.age) &&
      (this.insurance.endAge && this.age < this.insurance.endAge) &&
      this.insurance.monthlyPayment) {
      return this.insurance.monthlyPayment * 12;
    } else {
      return 0;
    }
  }

  repayment() {
    if (this.insurance.maturityRepayment &&
      this.insurance.maturityRepaymentAge &&
      this.age === this.insurance.maturityRepaymentAge) {
      return this.insurance.maturityRepayment;
    } else if (this.insurance.pensionRepaymentStartAge &&
      this.insurance.pensionRepaymentEndAge &&
      this.insurance.monthlyPensionRepayment &&
      this.insurance.pensionRepaymentStartAge <= this.age &&
      this.age < this.insurance.pensionRepaymentEndAge) {
      return this.insurance.monthlyPensionRepayment * 12;
    } else {
      return 0;
    }
  }
}

const debtFullPaymentDate = (debt: Debt, fromDate: Date) => {
  if (debt.monthlyPayment && debt.balance) {
    const months = debt.balance / debt.monthlyPayment;
    const newMonth = fromDate.getMonth() + months;
    const years = Math.floor(newMonth / 12);
    return new Date(fromDate.getFullYear() + years, newMonth % 12, 1);
  } else {
    return fromDate;
  }
}

export class DebtHelper {
  debt: Debt;
  fromMonth: number;
  annualPayments: number[] = [];
  advanceRepayments: {index: number, repayment: number}[] = [];

  constructor(debt: Debt, fromMonth: number = 0) {
    this.debt = debt;
    this.fromMonth = fromMonth;
    this.updateAnnualPayments();
  }

  updateAnnualPayments() {
    const debt = this.debt;
    if (debt.balance && debt.monthlyPayment) {
      let balance = debt.balance;
      const payments: number[] = Array(this.fromMonth).fill(0);
      const monthlyInterest = Math.pow((1 + (debt.interest || 0)), 1 / 12);
      for (let i = 0; balance > 0 && i < 12 * 100; i++) {
        // 利子のほうが増えていって無限ループにならないように上限 100 年とする
        let payment = debt.monthlyPayment;
        if (i % 12 === 0) {
          const advance = this.advanceRepayments.find(p => p.index === i / 12);
          if (advance) {
            payment += advance.repayment;
          }
        }
        if (payment < balance) {
          payments.push(payment);
          balance -= payment;
          balance *= monthlyInterest;
        } else {
          payments.push(balance);
          balance = 0;
        }
      }
      this.annualPayments = _.chunk(payments, 12).map(p => p.reduce((n, v) => n + v));
    } else {
      this.annualPayments = [0];
    }
  }

  lastAdvanceRepaymentIndex() {
    const p = _.last(this.advanceRepayments);
    return p ? p.index : null;
  }

  addAdvanceRepayment(index: number, repayment: number) {
    this.advanceRepayments.push({index, repayment});
    this.updateAnnualPayments();
  }

  annualPayment(yearIndex: number) {
    if (yearIndex < this.annualPayments.length) {
      return this.annualPayments[yearIndex];
    } else {
      return 0;
    }
  }

  fullPaymentIndex() {
    return this.annualPayments.length - 1;
  }
}

class DebtHelperWithAge {
  startAge: number;
  helper: DebtHelper;

  constructor(helper: DebtHelper, age: number) {
    this.startAge = age;
    this.helper = helper;
  }

  fullPaymentAge() {
    return this.startAge + this.helper.fullPaymentIndex();
  }

  lastAdvanceRepaymentAge() {
    const index = this.helper.lastAdvanceRepaymentIndex();
    return index != null ? this.startAge + index : null;
  }

  addAdvanceRepayment(age: number, repayment: number) {
    if (age >= this.startAge) {
      this.helper.addAdvanceRepayment(age - this.startAge, repayment);
    }
  }

  annualPayment(age: number) {
    if (age >= this.startAge) {
      return this.helper.annualPayment(age - this.startAge);
    } else {
      return 0;
    }
  }
}

const annualPaymentForDebt = (debt: Debt, fromMonth: number, ageIndex: number) => {
  if (debt.monthlyPayment && debt.balance) {
    const months = debt.balance / debt.monthlyPayment;
    const monthsInFirstYear = 12 - fromMonth;
    if (ageIndex === 0) {
      return Math.min(debt.balance, debt.monthlyPayment * monthsInFirstYear);
    } else {
      const restMonths = months - monthsInFirstYear - (ageIndex - 1) * 12;
      if (restMonths > 0) {
        return Math.min(restMonths, 12) * debt.monthlyPayment;
      } else {
        return 0;
      }
    }
  } else {
    return 0;
  }
}

const defaultHousePrice = (blueprint: Blueprint) => {
  return 40000000;
}

class ChildAge {
  child: Child;
  age: number;
  schoolAge: number;
  index: number;
  born: boolean;
  policy: ChildcarePolicy;
  studentInsurance?: InsuranceHelper;
  parent: Age;

  constructor(child: Child, index: number, parent: Age, born: boolean) {
    this.child = child;
    this.age = getAgeInYearEnd(child.birthday, new Date(parent.year(), 1, 0));
    this.schoolAge = getAgeInSchoolYearStart(child.birthday, parent.year()); // 年度のはじまりの時点で何歳か
    this.index = index;
    this.born = born;
    this.parent = parent;
    this.policy = parent.blueprint.childcarePolicy;
    if (this.policy.studentInsurance) {
      this.studentInsurance = new InsuranceHelper(this.policy.studentInsurance, this.schoolAge);
    }
  }

  events() {
    const graduation = childGraduationAge(this.policy);
    const indep = childIndependenceAge(this.policy);
    return (_.flattenDeep<string | false>([
      !this.born && this.age === 0 && "誕生",
      this.schoolAge === 6 && "義務教育を開始",
      this.schoolAge === graduation.age &&
      (graduation.age === indep ?
        `${graduation.lastSchool}を卒業・独立` :
        `${graduation.lastSchool}を卒業`),
      this.schoolAge === indep && graduation.age < indep && '独立'
    ]).filter(Boolean) as string[]).map(title => ({
      title,
      childIndex: this.index,
      target: "child",
      icon: "person",
    })) as AgeEvent[];
  }

  _childAllowance() {
    if (0 <= this.age && this.age < 15) {
      if (this.age < 3) {
        return 180000;
      } else if (this.age < 7) {
        if (this.index <= 2) {
          // 第２子まで
          return 120000;
        } else {
          // 第３子以降
          return 180000;
        }
      } else {
        return 120000;
      }
    } else {
      return 0;
    }
  }

  // ===== 教育費 =====
  _educationCost() {
    if (-1 <= this.schoolAge && this.schoolAge < 24) {
      let sum = 0;
      const index = this.schoolAge + 1;
      // デフォルト
      for (const name of ["preschool", "es", "jhs", "hs", "university", "graduateSchool"]) {
        const key = name as keyof SchoolTypes;
        if (this.policy.schoolTypes[key] === "employment") {
          break;
        }
        const table = educationCostTable[key][this.policy.schoolTypes[key]];
        if (table) {
          const cost = table[index];
          if (cost) {
            sum += cost * 10000;
          }
        } else {
          throw `no key for ${key}:${this.policy.schoolTypes[key]}`;
        }
      }
      // 受験
      for (const name of ["es", "jhs", "hs", "university"]) {
        const key = name as keyof ExaminationTypes;
        const table = examinationCostTable[key];
        if (this.policy.examinationTypes[key] !== "yes") {
          continue;
        }
        if (table) {
          const cost = table[index];
          if (cost) {
            sum += cost * 10000;
          }
        } else {
          throw `no key for ${key}:${this.policy.examinationTypes[key]}`;
        }
      }
      // 習いごと
      const table = lessonCostTable[this.policy.lessonType];
      if (table) {
        const cost = table[index];
        if (cost) {
          sum += cost * 10000;
        }
      }
      return sum * Math.pow(this.parent.blueprint.inflationRatio, this.parent.index);
    } else {
      return 0;
    }
  }

  // 仕送り
  _remittance() {
    if (this.policy.sendRemittance && this.policy.liveAloneAge) {
      if (this.policy.remittanceYears > 0) {
        if (_.inRange(this.schoolAge, this.policy.liveAloneAge, this.policy.liveAloneAge + this.policy.remittanceYears)) {
          return this.policy.monthlyRemittance * 12;
        }
      }
    }
    return 0;
  }

  // ===== 臨時支出 =====
  _specialExpense() {
    return sum(
      this.age === 0 && sum(
        this.policy.birthCost,
      ),
      this.age === 20 && this.policy.adultCeremonyCost,
    );
  }

  // ===== 収入
  _specialIncome() {
    return sum(
      this.age === 0 && sum(
        420000, // 出産育児一時金 42 万円
        this.policy.maternityGift, // 出産祝い
      ),
      this._childAllowance(), // 児童手当
      this.studentInsurance?.repayment(), // 学資保険
      _.inRange(this.schoolAge, 18, 22) && this.policy.useScholarship &&
      this.policy.scholarshipMonthlyAllowance * 12 // 奨学金
    );
  }

  expense() {
    return sum(
      this._educationCost(),
      this._remittance(),
      this.studentInsurance && this.studentInsurance.annualPayment(),
      this._specialExpense(),
      -this._specialIncome(),
    )
  }
}

export class CarAge {
  car: Car;
  owner: Age;
  held: boolean;

  constructor(car: Car, owner: Age, held: boolean) {
    this.car = car;
    this.owner = owner;
    this.held = held;
  }

  cost() {
    return sum(
      this.downPayment(),
      this.loan(),
      this.tax(),
      this.insurance(),
      this.inspectionCost(),
      -this.sellProfit(),
    )
  }

  downPayment() {
    if (!this.held && this.car.price && this.currentlyOwned() && this.owner.age === this.car.purchaseAge) {
      if (this.car.useLoan && this.car.loanRepayment) {
        return 0;
      } else {
        return this.car.price;
      }
    } else {
      return 0;
    }
    /* // ローンの月額と年数から頭金を計算する：年数を入れないようにしたのでコメントアウト
    if (!this.held && this.car.price && this.currentlyOwned() && this.owner.age === this.car.purchaseAge) {
      if (this.car.useLoan && this.car.loanYears && this.car.loanRepayment) {
        return _.max([this.car.price - (this.car.loanYears * this.car.loanRepayment * 12), 0]);
      } else {
        return this.car.price;
      }
    } else {
      return 0;
    }
    */
  }

  sellAge() {
    return this.owner.simulator.sellCarAge(this.car);
  }

  sellProfit() {
    if (this.sellAge() === this.owner.age &&
      (this.car.sellPlan !== "none" ||
        this.owner.simulator.releaseCarAge() === this.owner.age)) {
      return this.car.sellPrice ?? defaultCarSellPrice({
        ...this.car,
        sellAge: this.sellAge()
      }) ?? 0;
    }
    return 0;
  }

  loanHelper() {
    if (this.car.purchaseAge != null && this.car.price != null && this.car.purchaseAge <= this.owner.age) {
      return this.owner.simulator.debtHelper(this.car,
        { balance: this.car.price, monthlyPayment: this.car.loanRepayment, interest: CAR_LOAN_INTEREST });
    }
    return null;
  }
  
  loan() {
    if (this.car.useLoan && this.car.loanRepayment) {
      if (this.held && this.car.loanFullPayment) {
        return this.car.loanRepayment *
          getPaymentMonths(this.owner.year(), this.car.loanFullPayment);
      } else if (this.car.purchaseAge != null && this.loanHelper() != null) {
        return this.loanHelper()!.annualPayment(this.owner.age - this.car.purchaseAge);
      }
    }
    return 0;
  }

  tax() {
    if (this.currentlyOwned()) {
      return this.car.tax;
    } else {
      return 0;
    }
  }

  insurance() {
    if (this.currentlyOwned()) {
      return this.car.insuranceRepayment;
    } else {
      return 0;
    }
  }

  inspectionCost() {
    if (this.currentlyOwned()) {
      if (this.held && this.car.nextInspectionYear != null) {
        const years = this.owner.year() - this.car.nextInspectionYear;
        if (years >= 0 && years % 2 === 0) {
          return this.car.inspectionFee;
        }
      }
      if (!this.held && this.car.purchaseAge) {
        const yearsSincePurchase = this.owner.age - this.car.purchaseAge;
        let years: number;
        if (this.car.newOrUsed === "used") {
          years = yearsSincePurchase - 1;
        } else {
          years = yearsSincePurchase - 3;
        }
        if (years >= 0 && years % 2 === 0) {
          return this.car.inspectionFee;
        }
      }
    }
    return 0;
  }

  currentlyOwned() {
    if (this.owner.simulator.sellCarAge(this.car) <= this.owner.age) {
      return false;
    }
    if (this.held) {
      return true;
    } else {
      if (this.car.purchaseAge && this.car.purchaseAge <= this.owner.age) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export class Age {
  simulator: Simulator;
  blueprint: Blueprint;
  age: number;
  lastAge: Age | null;
  own: PersonAge;
  partner?: PersonAge;
  children: ChildAge[];
  //independentChildren: ChildAge[];
  index: number;
  insurance: InsuranceHelper[];
  cars: CarAge[];

  constructor(simulator: Simulator, blueprint: Blueprint, age: number, lastAge: Age | null, index: number, aggregate: { "own": Aggregate, "partner": Aggregate }) {
    this.simulator = simulator;
    this.blueprint = blueprint;
    this.age = age;
    this.lastAge = lastAge;
    this.index = index;
    this.own = new PersonAge(this, simulator.own, age, lastAge?.own || null);
    if (
      simulator.partner &&
      blueprint.partner &&
      blueprint.partner.birthday &&
      ("married" === blueprint.wishPartner ||
        ("yes" === blueprint.wishPartner && blueprint.marriageAge && blueprint.marriageAge <= age))) {
      this.partner = new PersonAge(this, simulator.partner,
        getAgeInYearEnd(blueprint.partner.birthday, blueprint.currentDate()) + index, lastAge?.partner || null);
    }
    const children =
      _.take(blueprint.currentChildren, blueprint.childNum || 0).map((child, i) =>
        new ChildAge(child, i, this, true))
      .concat(_.take(blueprint.desiredChildren, blueprint.wishChildNum || 0).map((child, i) =>
        new ChildAge(child, i + (blueprint.childNum || 0), this, false)));
    this.children =
      children.filter(c => 0 <= c.age && 
        c.schoolAge <= Math.max(childIndependenceAge(blueprint.childcarePolicy),
                                childGraduationAge(blueprint.childcarePolicy).age));
    //this.independentChildren = 
    //  children.filter(c => c.age === childIndependenceAge(blueprint.childcarePolicy));
    this.insurance = _.concat(
      _.includes(["yes", "planned"], blueprint.hasInsurance) ?
        blueprint.insurances.map(i => new InsuranceHelper(i, age)) : [],
      _.includes(["yes", "planned"], blueprint.hasSavingsTypeInsurance) ?
        blueprint.savingsTypeInsurance.map(i => new InsuranceHelper(i, age)) : []
    );
    this.cars = [
      ...blueprint.cars.map(car => new CarAge(car, this, true)),
      ...blueprint.carPurchasePlans.map(car => new CarAge(car, this, false)),
      ...simulator.carReplacements.map(car => new CarAge(car, this, false)),
    ];
  }

  year() {
    return this.blueprint.currentDate().getFullYear() + this.index;
  }

  familyType() {
    return this.meAndPartner() === 2 ?
      (this.numberOfChildren() > 0 ? "child" : "couple") : "single";
  }

  events(): AgeEvent[] {
    return _.compact(_.flatten([
      this.simulator.movingPlans.find(plan => plan.age === this.age) && {
        title: "引っ越し",
        target: "family",
        icon: "house",
      },
      this.blueprint.wishPartner === "yes" && this.age === this.blueprint.marriageAge && {
        title: "結婚",
        target: "family",

        icon: "wedding",
      },
      this.blueprint.planningToBuyHouse === "yes" &&
      this.blueprint.housePurchasePlan && this.blueprint.housePurchasePlan.age === this.age && [
        {
          title: "マイホームを購入",
          target: "family",
          icon: "house",
        },
        {
          title: "住宅ローンを開始",
          target: "family",
          icon: "house",
        },
      ],
      this.isHousingLoanFullPaymentAge() && {
        title: "住宅ローンを完済",
        target: "family",
        icon: "work",
      },
      this.blueprint.carPurchasePlans.filter(car => car.purchaseAge === this.age).map(() => ({
        title: "自動車を購入",
        target: "family",
        icon: "car",
      })),
      ...this.children.map(c => c.events()),
      ...this.own.events(),
      this.partner && this.partner?.events(),
      //...this.independentChildren.map(c => c.events()),
      this.blueprint.specialExpense.filter(e => e.age === this.age).map((e) => ({
        title: e.name || "臨時支出",
        target: "family",
        icon: "payment",
      })),
      this.blueprint.specialIncome.filter(e => e.age === this.age).map((e) => ({
        title: e.name || "臨時収入",
        target: "family",
        icon: "income",
      })),
    ]));
  }

  // 貯金累計
  /*
  @Memoize()
  savings(): number {
    if (this.lastAge) {
      let savings = this.lastAge.savings();
      if (this.partner && !this.partner.lastAge) {
        savings += this.partner.properties.savings || 0;
      }
      return savings + this.annualSavingsBalance();
    } else {
      let savings = this.blueprint.own.savings || 0;
      if (this.partner) {
        savings += this.partner.properties.savings || 0;
      }
      return savings + this.annualSavingsBalance();
    }
  }
  */

  @Memoize()
  asset(): number {
    return this.savings() + this.investmentValuation();
  }

  @Memoize()
  savings(): number {
    if (this.lastAge) {
      return this.lastAge.savings() + this.income() - this.expense()
        - this.investment()
        - this.investmentProfit() + this.investmentSalesAmount();
    } else {
      return this.simulator.initialSavings() + this.income() - this.expense()
        - this.investment()
        - this.investmentProfit() + this.investmentSalesAmount();
    }
  }

  investmentValuation() {
    return sum(
      this.own.investmentValuation(),
      this.partner?.investmentValuation(),
    );
  }

  investmentSalesAmount() {
    return sum(
      this.own.investmentSalesAmount(),
      this.partner?.investmentSalesAmount(),
    );
  }

  @Memoize()
  basicLivingCost(): number {
    if (this.lastAge) {
      if (this.age >= 75) {
        return this.lastAge.livingCost();
      } else if (this.age >= 60) {
        const min = _.min([this.lastAge.livingCost(), this.meAndPartner() === 2 ? 2280000 : 1200000]);
        return _.max([this.lastAge.livingCost() * 0.97, min])!;
      } else {
        return this.lastAge.livingCost() *
          this.blueprint.livingCostIncreaseRatio *
          this.blueprint.inflationRatio;
      }
    } else {
      return (this.blueprint.monthlyLivingCost || 0) * 12;
    }
  }

  increasedLivingCost() {
    if (this.lastAge) {
      return sum(
        this.blueprint.independentHouseAge === this.age && 1,
        this.meAndPartner() === 2 && this.lastAge.meAndPartner() === 1 && 0.5,
        Math.max(this.childrenAndHousemates() - this.lastAge.childrenAndHousemates(), 0) * 0.2,
        Math.max(this.lastAge.familyMembers() - this.familyMembers(), 0) * -0.8,
      ) * this.lastAge.personalLivingCost();
    } else {
      return 0;
    }
  }

  @Memoize()
  livingCost() {
    return this.basicLivingCost() + this.increasedLivingCost();
  }

  // 生活費など
  @Memoize()
  livingCostEtc() {
    return sum(
      this.livingCost(),
      this.own.fixedExpense(),
      this.partner?.fixedExpense(),
      ...this.insurance.map(i => i.annualPayment()),
      ...this.insurance.map(i => -i.repayment()),
      ...this.blueprint.debt?.map(d => annualPaymentForDebt(d, this.blueprint.createdDate!.getMonth(), this.index)),
    )
  }

  @Memoize()
  personalLivingCost(): number {
    return this.livingCost() / (this.meAndPartner() + this.childrenAndHousemates() * 0.5);
  }

  isHousingLoanFullPaymentAge() {
    this.housingLoan();
    if (this.simulator.housingLoan) {
      return this.age === this.simulator.housingLoan.fullPaymentAge();
    } else {
      return false;
    }
  }

  @Memoize()
  defaultHousingLoanDownPayment() {
    if (this.blueprint.planningToBuyHouse === "yes" &&
      this.blueprint.housePurchasePlan &&
      this.blueprint.housePurchasePlan.age &&
      this.blueprint.housePurchasePlan.price &&
      this.age === this.blueprint.housePurchasePlan.age) {
      let maxDownPayment = 0;
      if (this.lastAge) {
        maxDownPayment = Math.max(this.lastAge.savings() - this.lastAge.livingCost() * 0.5, 0);
      }
      return Math.min(this.blueprint.housePurchasePlan.price * 0.2, maxDownPayment);
    } else {
      return 0;
    }
  }

  housingLoanDownPayment() {
    if (this.blueprint.planningToBuyHouse === "yes" &&
      this.blueprint.housePurchasePlan &&
      this.blueprint.housePurchasePlan.age &&
      this.blueprint.housePurchasePlan.price &&
      this.age === this.blueprint.housePurchasePlan.age) {
      return this.blueprint.housePurchasePlan.downPayment != null ?
        this.blueprint.housePurchasePlan.downPayment :
        0;//this.defaultHousingLoanDownPayment();
    } else {
      return 0;
    }
  }

  // 住宅ローン
  @Memoize()
  housingLoan() {
    if (this.simulator.housingLoan) {
      if (this.blueprint.housingLoanAdvanceRepayment) {
        const lastRepaymentAge = this.simulator.housingLoan.lastAdvanceRepaymentAge() || this.simulator.housingLoan.startAge;
        if (lastRepaymentAge < this.age) {
          const years = this.age - lastRepaymentAge;
          const age = this.simulator.ages[this.index - years];
          if (age && this.lastAge && age != this.lastAge &&
            age.savings() + HOUSING_LOAN_ADVANCE_REPAYMENT <= this.lastAge.savings()) {
            this.simulator.housingLoan.addAdvanceRepayment(this.age, HOUSING_LOAN_ADVANCE_REPAYMENT);
          }
        }
      }
      return this.simulator.housingLoan.annualPayment(this.age);
    } else {
      return 0;
    }
  }

  // 住宅ローン控除
  mortgageDeduction() {
    if (this.housingLoan() > 0 && this.simulator.housingLoan) {
      if (this.age < this.simulator.housingLoan?.startAge + 10 &&
        (!this.blueprint.mortgageDeductionEndDate ||
         this.year() < this.blueprint.mortgageDeductionEndDate.getFullYear())) {
        return Math.min(this.housingLoan(), this.blueprint.mortgageDeductionAmount);
      }
    }
    return 0;
  }

  _currentOwnedHouse(): House | null {
    if (this.blueprint.planningToSellHouse &&
      this.blueprint.sellHouseAge &&
      this.blueprint.sellHouseAge <= this.age) {
      return null;
    }
    if (this.blueprint.houseOwnership === "owned") {
      return this.blueprint.currentHouse || null;
    }
    if (this.blueprint.planningToBuyHouse === "yes" &&
      this.blueprint.housePurchasePlan?.age &&
      this.blueprint.housePurchasePlan.age <= this.age) {
      return this.blueprint.housePurchasePlan || null;
    }
    return null;
  }

  // 住宅管理費
  houseManagementCost() {
    const house = this._currentOwnedHouse();
    if (house && house.monthlyManagementCost) {
      return house.monthlyManagementCost * 12;
    } else {
      return 0;
    }
  }

  houseInitialCost() {
    if (this.blueprint.planningToBuyHouse === "yes" &&
      this.blueprint.housePurchasePlan?.age &&
      this.blueprint.housePurchasePlan.age === this.age) {
      if (this.blueprint.housePurchasePlan.initialCost != null) {
        return this.blueprint.housePurchasePlan.initialCost;
      } else {
        return (this.blueprint.housePurchasePlan.price ?? 0) * 0.05;
      }
    }
    return 0;
  }

  houseReformCost() {
    let purchasedAge = -1;
    switch (this.blueprint.planningToBuyHouse) {
      case "bought":
        purchasedAge = this.simulator.currentAge -
          (this.blueprint.currentDate().getFullYear() - this.blueprint.createdDate.getFullYear());
        break;
      case "yes":
        if (this.blueprint.housePurchasePlan?.age) {
          purchasedAge = this.blueprint.housePurchasePlan.age;
        }
        break;
    }
    const house = this._currentOwnedHouse();
    if (purchasedAge >= 0 && house) {
      if (this.age === purchasedAge + 15 || this.age === purchasedAge + 30) {
        return house.reformCost ?? DEFAULT_HOUSE_REFORM_COST;
      }
    }
    return 0;
  }

  // 固定資産税
  _propertyTax() {
    const house = this._currentOwnedHouse();
    if (house && house.propertyTax) {
      return house.propertyTax;
    } else {
      return 0;
    }
  }

  // 教育費
  childExpense() {
    return sum(...this.children.map(c => c.expense()));
  }

  otherExpense() {
    return sum(
      this.own.age <= 75 && this.blueprint.annualBasicExpense &&
        this.blueprint.annualBasicExpense * Math.pow(this.blueprint.inflationRatio, this.index),
      ...this.blueprint.specialExpense.map(e =>
        e.age === this.age && e.amount),
      ...this.blueprint.monthlyExpense.map(e =>
        matchAge(this.age, e) && e.amount && e.amount * 12),
      ...this.blueprint.repeatedExpense.map(e =>
        matchRepeatedAge(this.age, e) && e.amount),
      this.own.specialExpense(),
      this.partner?.specialExpense(),
    )
  }

  // 支出合計
  expense() {
    return sum(
      this.houseCost(),
      this.carCost(),
      this.childExpense(),
      this.livingCostEtc(),
      this.otherExpense()
    );
  }

  // ===== 収入
  income() {
    return sum(
      this.workIncome(),
      this.pension(),
      this.otherIncome(),
      this.investmentProfit(),
    );
  }

  workIncome() {
    return sum(
      this.own.workIncome(),
      this.partner?.workIncome(),
    );
  }

  pension() {
    return sum(
      this.own.pension(),
      this.partner?.pension(),
    );
  }

  otherIncome() {
    return sum(
      this.sellHouseProfit(),
      ...this.blueprint.specialIncome.map(e =>
        e.age === this.age && e.amount),
      ...this.blueprint.monthlyIncome.map(e =>
        matchAge(this.age, e) && e.amount && e.amount * 12),
      ...this.blueprint.repeatedIncome.map(e =>
        matchRepeatedAge(this.age, e) && e.amount),
      ...this.blueprint.own.inheritance.map(i =>
        i.age === this.age && i.amount && i.amount),
      ...(this.blueprint.partner ?
        this.blueprint.partner.inheritance.map(i =>
          i.age === this.age && i.amount && i.amount) : []),
    );
  }

  // 持ち家の売却益
  sellHouseProfit() {
    if (this.blueprint.planningToSellHouse &&
      this.blueprint.sellHouseAge &&
      this.age === this.blueprint.sellHouseAge) {
      if (this.blueprint.sellHousePrice != null) {
        return this.blueprint.sellHousePrice;
      } else {
        let housePrice: number = 0;
        if (this.blueprint.houseOwnership === "owned" &&
          this.blueprint.currentHouse) {
          housePrice = this.blueprint.currentHouse?.price || defaultHousePrice(this.blueprint);
        } else if (this.blueprint.planningToBuyHouse === "yes" &&
          this.blueprint.housePurchasePlan) {
          housePrice = this.blueprint.housePurchasePlan.price ??
            defaultHousePrice(this.blueprint);
        }
        return housePrice * 0.5;
      }
    } else {
      return 0;
    }
  }

  houseCost() {
    return sum(
      this.rent(),
      this.movingCost(),
      this.houseManagementCost(),
      this._propertyTax(),
      this.housingLoan(),
      this.housingLoanDownPayment(),
      this.houseInitialCost(),
      this.houseReformCost(),
      -this.mortgageDeduction(),
    );
  }

  carCost() {
    return sum(
      ...this.cars.map(car => car.cost()),
      this.blueprint.parkingRent &&
      this.cars.find(car => car.currentlyOwned()) &&
      this.blueprint.parkingRent * 12
    );
  }

  movingOrHousePurchasePlans(): (HousePurchasePlan | MovingPlan)[] {
    return _.compact([
      ...this.simulator.movingPlans,
      this.blueprint.planningToBuyHouse === "yes" && this.blueprint.housePurchasePlan
    ]).filter(plan => plan.age != null).sort((a, b) => (b.age! - a.age!));
  }

  // 家賃
  rent() {
    // 引越しまたは住宅購入の予定がある場合はそちらから算出する
    const plans = this.movingOrHousePurchasePlans();
    const rent = plans.map(plan => {
      if (plan.age! <= this.age) {
        const moving = plan as MovingPlan;
        const house = plan as HousePurchasePlan;
        if (house.price != null) { // HousePurchasePlan
          return 0;
        } else if (moving.rent != null) { // MovingPlan (賃貸)
          let rent = moving.rent! * 12;
          if (moving.rentRenewalFee && (this.age - plan.age!) % 2 === 0) {
            rent += moving.rentRenewalFee;
          }
          return rent;
        }
      }
    }).filter(v => v != null)[0];
    if (rent != null) return rent;

    if (this.blueprint.houseOwnership == "rental" &&
      this.blueprint.rent) {
      let rent = this.blueprint.rent * 12;
      if (this.blueprint.rentRenewalFee && this.blueprint.nextRentRenewalYear) {
        const yearsFromRenewal = this.year() - this.blueprint.nextRentRenewalYear;
        if (yearsFromRenewal >= 0 && yearsFromRenewal % 2 === 0) {
          rent += this.blueprint.rentRenewalFee;
        }
      }
      return rent;
    } else {
      return 0;
    }
  }

  movingCost() {
    const plans = this.movingOrHousePurchasePlans();
    const movingCost = plans.map(plan => plan.age === this.age ? plan.movingCost : null)
      .filter(v => v != null)[0];
    return movingCost || 0;
  }

  @Memoize()
  investmentProfit() {
    return sum(
      this.own.investmentProfit(),
      this.partner?.investmentProfit()
    );
  }

  investment() {
    return sum(
      this.own.yearlyInvestment(),
      this.partner?.yearlyInvestment()
    )
  }

  // ===== 人数
  numberOfHousemates() {
    if (this.blueprint.hasHousemate) {
      return this.blueprint.housemates.filter(m =>
        (!m.fromYear || m.fromYear <= this.year()) &&
        (!m.toYear || this.year() < m.toYear)).length;
    } else {
      return 0;
    }
  }

  meAndPartner() {
    switch (this.blueprint.wishPartner) {
      case "married":
        return _.compact([this.own.alive(), this.partner?.alive()]).length;
      case "yes":
        if (this.blueprint.marriageAge && this.blueprint.marriageAge <= this.age) {
          return _.compact([this.own.alive(), this.partner?.alive()]).length;
        } else {
          return 1;
        }
      default:
        return 1;
    }
  }

  familyMembers() {
    return this.meAndPartner() + this.childrenAndHousemates();
  }

  numberOfChildren() {
    return this.children.filter(c => c.schoolAge < childIndependenceAge(this.blueprint.childcarePolicy)).length;
  }

  childrenAndHousemates() {
    return this.numberOfChildren() + this.numberOfHousemates();
  }
}
