import React from 'react';
import { functions, AUTH_DISABLED } from '../firebase';
import { Context, AppState, Actions } from '.'
import { User } from '.'

//export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export type EstimatedExpenseType = "living" | "annual" | "exclude" | "child" | "women" | "car";

export type UserDetail = {
  expense: {
    categoryId: number;
    genreId: number;
    categoryName: string;
    genreName: string;
    amount: number;
    expenseType: EstimatedExpenseType;
  }[] | null;
  savings: number;
  pension: {
    basicPensionNumber: string;
    annualPension: number;
  }[] | null;
  annualPension?: number | null; // 以前のバージョンで取得した年金額
}

export const getUser = (uid: string) => {
  if (AUTH_DISABLED) {
    return new Promise((resolve, reject) => {
      resolve({id: uid})
    });
  } else {
    return functions.httpsCallable('getUser')()
      .then((r) => { return r.data });
  }
}

export const getUserDetail = () => {
  if (AUTH_DISABLED) {
    return new Promise<UserDetail>((resolve, reject) => {
      const params = (new URL(window.document.location.toString())).searchParams;
      let livingCost: number | null = null;
      if (params.get("livingCost") === "default") {
        resolve({ expense: [], savings: 1000000, 
          pension: [],
          annualPension: null });
      } else {
        resolve({ expense: [
          {
            "categoryId": 104,
            "genreId": 10403,
            "categoryName": "Utilities",
            "genreName": "Internet-related",
            "amount": 94,
            "expenseType": "living"
          },
          {
            "categoryId": 107,
            "genreId": 10702,
            "categoryName": "Socializing",
            "genreName": "Gifts",
            "amount": 1667,
            "expenseType": "living"
          },
          {
            "categoryId": 108,
            "genreId": 10805,
            "categoryName": "Hobbies",
            "genreName": "Cartoon",
            "amount": 0,
            "expenseType": "annual"
          },
          {
            "categoryId": 108,
            "genreId": 10899,
            "categoryName": "Hobbies",
            "genreName": "Other",
            "amount": 887,
            "expenseType": "annual"
          },
          {
            "categoryId": 111,
            "genreId": 11101,
            "categoryName": "Fashion",
            "genreName": "Clothes",
            "amount": 100540,
            "expenseType": "living"
          },
          {
            "categoryId": 111,
            "genreId": 11106,
            "categoryName": "Fashion",
            "genreName": "Cosmetics",
            "amount": 6580,
            "expenseType": "women"
          },
          {
            "categoryId": 199,
            "genreId": 19905,
            "categoryName": "Other",
            "genreName": "Uncategorized",
            "amount": 34668,
            "expenseType": "exclude"
          }
        ], savings: 1000000, 
        pension: [
          {
            basicPensionNumber: "1234-123456",
            annualPension: 200000
          },
          {
            basicPensionNumber: "9876-987654",
            annualPension: 1000000
          },
        ],
        annualPension: null });
      }
    });
  } else {
    return functions.httpsCallable('getUserDetail')()
      .then((r) => { return r.data as UserDetail});
  }
}

export type fetchUserSuccess = {
  types: typeof FETCH_USER_SUCCESS;
  payload: {
    data: User
  }
};

const mapGender = (id: number) =>  {
  switch (id) {
    case 1: return 'male';
    case 2: return 'female';
    case 3: return 'other';
    default: return undefined;
  }
}

export const reducer = (state: AppState, action: Actions): AppState => {
  switch (action.types) {
    case FETCH_USER_SUCCESS: {
      const data = action.payload.data;
      const s = {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data
        }
      };
      state.blueprint.own.gender = mapGender(data.gender_id || 0);
      if (data.birth_date && data.birth_date != '0000-00-00') {
        state.blueprint.own.birthday = new Date(data.birth_date);
      }

      return s;
    }
    default: {
      return state;
    }
  }
};
