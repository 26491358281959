import * as React from "react";
import styles from "./HalfDivideArea.module.scss";

const HalfDivideArea = (props: {
  height?: number | string;
  width?: number | string;
  children: React.ReactElement[];
}) => {
  return (
    <div
      style={{
        height: props.height ? props.height : ""
      }}
      className={styles.area}
    >
      <div className={styles.partsFirst}>{props.children[0]}</div>
      <div className={styles.partsSecond}>{props.children[1]}</div>
    </div>
  );
};

export default HalfDivideArea;
