import _ from "lodash";
import * as React from "react";
import { formatMoney, formatMoneyWithUnit, Simulator } from "../../simulator";
import styles from "./SubtotalFooter.module.scss";

export type Subtotal = {
  title: string;
  value: (sim: Simulator) => number;
}

export default (props: { plan: Simulator, subtotal?: Subtotal }) => {
  const asset = _.last(props.plan.ages)!.asset();
  return (
    <>
    <div className={styles.hiddenArea}></div>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {props.subtotal &&
          <div className={styles.section}>
            <div className={styles.title}>{props.subtotal.title}</div>
            <div className={styles.total}><span>合計</span> {formatMoneyWithUnit(props.subtotal.value(props.plan))}</div>
          </div>
        }
        <div className={styles.section}>
          <div className={styles.title}>
            {_.last(props.plan.ages)!.age} 歳の時の資産
          </div>
          <div className={`${styles.total} ${asset >= 0 ? styles.plus : styles.minus}`}>
            {formatMoneyWithUnit(asset)}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
