import * as React from "react";
import styles from "./DateField.module.scss";
import SelectField from "../../atoms/SelectField";

const months: { [key: string]: string } = {};
Array.from(Array(12).keys()).forEach(i => (months[`${i + 1}`] = `${i + 1}`));
const dates: { [key: string]: string } = {};
Array.from(Array(31).keys()).forEach(i => (dates[`${i + 1}`] = `${i + 1}`));

export default (props: {
  value: Date;
  startYear: number;
  endYear: number;
  withoutDate?: boolean;
  onChange: (date: Date) => void;
}) => {
  const years = React.useMemo(() => {
    const ys: { [key: string]: string } = {};
    let year = props.startYear;
    while (year <= props.endYear) {
      ys[`${year}`] = `${year}`;
      year += 1;
    }
    return ys;
  }, [props.startYear, props.endYear]);
  const yearRef = React.useRef<HTMLSelectElement>(null);
  const monthRef = React.useRef<HTMLSelectElement>(null);
  const dateRef = React.useRef<HTMLSelectElement>(null);
  return (
    <div className={styles.dateField}>
      <SelectField
        value={`${props.value.getFullYear()}`}
        fieldRef={yearRef}
        master={years}
        onChange={v => {
          if (monthRef.current) {
            monthRef.current.focus();
          }
          props.onChange(
            new Date(
              parseInt(v, 10),
              props.value.getMonth(),
              props.value.getDate()
            )
          );
        }}
      />
      <label>年</label>
      <SelectField
        value={`${props.value.getMonth() + 1}`}
        fieldRef={monthRef}
        master={months}
        onChange={v => {
          if (dateRef.current) {
            dateRef.current.focus();
          }
          props.onChange(
            new Date(
              props.value.getFullYear(),
              parseInt(v, 10) - 1,
              props.value.getDate()
            )
          );
        }}
      />
      <label>月</label>
      {!props.withoutDate && (
        <>
          <SelectField
            value={`${props.value.getDate()}`}
            fieldRef={dateRef}
            master={dates}
            onChange={v =>
              props.onChange(
                new Date(
                  props.value.getFullYear(),
                  props.value.getMonth(),
                  parseInt(v, 10)
                )
              )
            }
          />
          <label>日</label>
        </>
      )}
    </div>
  );
};
