import _ from "lodash";
import * as React from "react";
import { formatMoney, formatMoneyWithUnit, Simulator } from "../../simulator";
import styles from "./TotalFooter.module.scss";
import editIcon from "./edit.svg";
import calendarIcon from "./calendar.svg";

export type Subtotal = {
  title: string;
  value: (sim: Simulator) => number;
}

const LinkButton = (props: {icon: string, title: string, target: React.RefObject<HTMLElement>}) => {
  return (
    <div className={styles.linkButton} onClick={() => props.target.current!.scrollIntoView({behavior: 'smooth'})}>
      <img className={styles.linkButtonIcon} src={props.icon} />
      <div className={styles.linkButtonTitle}>
        {props.title}
      </div>
    </div>
  );
}

export default (props: { plan: Simulator, target: {[key: string]: React.RefObject<HTMLElement>} }) => {
  const asset = _.last(props.plan.ages)!.asset();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.totalWrapper}>
          <div className={styles.title}>
            {_.last(props.plan.ages)!.age} 歳の時の資産
          </div>
          <div className={`${styles.total} ${asset >= 0 ? styles.plus : styles.minus}`}>
            {formatMoneyWithUnit(asset)}
          </div>
        </div>
        <LinkButton icon={editIcon} title="編集" target={props.target.settings} />
        <LinkButton icon={calendarIcon} title="確認" target={props.target.review} />
      </div>
    </div>
  );
};
