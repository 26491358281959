import * as React from "react";
import styles from "./FixedNumberField.module.scss";

export default (props: {
  value: number | string | null;
  formatter?: (value: number) => string,
}) => {
  const value = props.value != null ?
    (props.formatter && typeof props.value === "number" ?
      props.formatter(props.value) : props.value) :
    "-";
  return (
    <div className={styles.container}>
      <div className={styles.value}>
        {value}
      </div>
      <div className={styles.comment}>
        （自動算出）
      </div>
    </div>
  );
};
