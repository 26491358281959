import { ThemeProvider, Dialog, DialogContent, Button, createMuiTheme } from "@material-ui/core";
import React from "react";
import styles from "./InfoDialog.module.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#49C54F"
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 16,
      }
    },
    MuiDialogContent: {
      root: {
        background: "#ffffff",
        display: "flex",
        flexFlow: "column",
        marginBottom: 16,
        '& > button:hover': {
          boxShadow: 'none',
        },
        '& > img':  {
          marginBottom: 16,
        },
      }
    },
    MuiButton: {
      label: {
        color: 'white',
        fontWeight: 'bold',
      },
      contained: {
        boxShadow: "none",
        marginTop: 16,
      }
    },
  }
});

export default (props: {
    open: boolean,
    setOpen: (open: boolean) => void,
    image: string,
    title?: string,
    children: React.ReactNode,
  }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogContent>
          <img src={props.image} />
          { props.title &&
            <div className={styles.title}>
              { props.title }
            </div>
          }
          <div className={styles.comment}>
            { props.children }
          </div>
          <Button variant="contained" color="primary" onClick={() => props.setOpen(false)}>
            閉じる
          </Button>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
