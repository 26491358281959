import * as React from "react";
import styles from "./Container.module.scss";

const Container = (props: {
  height?: number | string;
  width?: number | string;
  className?: string;
  children: React.ReactElement;
}) => {
  return (
    <div
      style={{
        height: props.height,
        width: props.width ? props.width : "100%"
      }}
      className={styles.centeringParent + " " + props.className}
    >
      <div className={styles.centeringItem}>{props.children}</div>
    </div>
  );
};

export default Container;
