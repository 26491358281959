import * as React from "react";
import Container from "../../molecules/Container";
import styles from "./QuestionCategory.module.scss";
import Button from "../../atoms/Button";
import Description from "../../atoms/Description";
import { Context, changePage, QuestionCategoryName } from "../../Store";

const QuestionCategory = (props: {
  category: QuestionCategoryName,
  title: string, path: string, icon: string
}) => {
  const { dispatch, state } = React.useContext(Context);
  return (
    <div className={styles.category}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.button}>
      <Button 
        disabled={state.blueprint.completed.includes(props.category)}
        onClick={() => {
         window.history.pushState(true, "", props.path);
         dispatch(changePage(props.path));
        }}>質問スタート</Button>
      </div>
      <div className={styles.icon}>
        <img src={props.icon} />
      </div>
    </div>
  );
};

export default QuestionCategory;
