import * as React from "react";
import styles from "./NumberField.module.scss";

export default (props: {
  value?: string | null;
  placeholder?: string;
  autoFocus: boolean;
  onChange: (v: string) => void;
  onFocusChange?: (focused: boolean) => void;
  step: number;
}) => {
  const currentRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState(false);
  React.useEffect(() => {
    if (state) {
      currentRef.current && currentRef.current.focus();
    }
  }, [state, currentRef]);
  React.useEffect(() => {
    if (props.autoFocus) {
      const timer = setTimeout(() => {
        setState(true);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [props.autoFocus]);

  return (
    <input
      onFocus={e => props.onFocusChange && props.onFocusChange(true)}
      onBlur={e => props.onFocusChange && props.onFocusChange(false)}
      ref={currentRef}
      className={styles.defaultInput}
      placeholder={props.placeholder}
      type="number"
      step={props.step}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value != null ? props.value : ""}
    />
  );
};
