import { AppState, Blueprint, saveState, changePage, setAttributes, Actions, ProfessionNames, WishPartnerNames, Context, getValueFromPath, generateAttributesFromPath, professionHasSalary, HouseOwnershipNames, NewOrUsedHouseNames, HouseTypeNames, PlanningToBuyHouseNames, Gender, WishChildNames, PlanningToBuyCarNames, sessionStartDate, nthChildName, QuestionCategoryName, finishedQuestions } from "../../Store";
import { questionIndexPath } from "../../path";
import { SelectYearMonth, SelectRadio, SelectYesNoRadio, SelectNumber, FieldDef, Select, InputMoney, InputAge, getFieldDefaultValue, InputExpense, SelectExistenceRadio, InputTextWithOptions, InputYearsFromNow, InputNumber, InputIncome, InputMonths } from "../../templates/fields";
import styles from "./questions.module.scss";
import _ from "lodash";
import React from "react";
import Question, { QuestionField } from "./Question";
import EstimatedExpense from "../../templates/EstimatedExpense";
import QuestionCompleted from "./QuestionCompleted";
import QuestionCategory from "../../templates/QuestionCategory";
import Comment from "../../templates/Comment";
import questionImage1 from "./question1.svg";
import questionImage2 from "./question2.svg";
import questionImage3 from "./question3.svg";
import questionImage4 from "./question4.svg";
import questionImage5 from "./question5.svg";
import Container from "../../molecules/Container";
import { civilJobCategories, jobCategories, jobCategoryMap } from "../../Store/jobs";
import { getAge } from "../../simulator";

const SelectYesNo = SelectYesNoRadio;
const SelectExistence = SelectExistenceRadio;

export const EndQuestion = "end";
export type EndQuestion = typeof EndQuestion;
export type NextQuestion = string | ((state: AppState) => string | EndQuestion) | EndQuestion;

export type QuestionProps = {
  category: QuestionCategoryName,
  next?: NextQuestion | null
}

export type CombinedFieldDef = {
  fields: FieldDef<any>[],
  key: string,
}

const combine = (...fields: FieldDef<any>[]) => {
  return {
    fields,
    key: fields[0].key
  }
}

type NoFieldComponent = { component: React.FC, key: string }
type FieldDefOrComponent<T> = FieldDef<T> | NoFieldComponent | CombinedFieldDef;
type NestedFieldDefElement = NestedFieldDefElement[] | FieldDefOrComponent<any> | false | undefined | null;
type NestedFieldDefs = NestedFieldDefElement[];

type NextQuestionProps = {
  next: NextQuestion | null
};

class QuestionCategoryFields {
  fields: (state: AppState) => NestedFieldDefs;
  cachedComponents: { [key: string]: React.FC<NextQuestionProps> } = {};
  categoryTitle: string;
  category: QuestionCategoryName;
  icon: string;

  constructor(category: QuestionCategoryName, categoryTitle: string, icon: string, fields: (state: AppState) => NestedFieldDefs) {
    this.category = category;
    this.categoryTitle = categoryTitle;
    this.fields = fields;
    this.icon = icon;
  }

  createPages(state: AppState) {
    return _.compact(_.flattenDeep(this.fields(state))).map((c) => {
      const key = this.category + '/' + (c as FieldDefOrComponent<any>).key as string;
      if (!this.cachedComponents[key]) {
        //console.log('new key', key);
        this.cachedComponents[key] = this._createComponent(c);
      }
      return {
        key: key,
        path: `/questions/${key}`,
        component: this.cachedComponents[key],
      };
    });
  }


  _createComponent(fieldOrComponent: FieldDefOrComponent<any>) {
    const category = this.category;
    if ((fieldOrComponent as any).component) {
      const c = (fieldOrComponent as NoFieldComponent);
      return function _fieldComponent(props: NextQuestionProps) {
        return (
          <Question
            question={{
              category: category,
              next: props.next
            }}
          >
            <c.component />
          </Question>
        );
      }
    } else if ((fieldOrComponent as any).fields) {
      const c = (fieldOrComponent as CombinedFieldDef);
      return function _combinedFieldComponent(props: NextQuestionProps) {
        const { dispatch, state } = React.useContext(Context);
        const [value, setValue] = React.useState(
          c.fields.map(f => getValueFromPath<any>(state.blueprint, f.path!))
        );
        const updateValue = (i: number, v: any | null) => {
          const newValue = _.clone(value);
          newValue[i] = v;
          setValue(newValue);
          const attr = generateAttributesFromPath(state.blueprint, c.fields[i].path!, v);
          dispatch(setAttributes(attr));
        }
        React.useEffect(() => {
          c.fields.forEach((f, i) => {
            const defaultValue = getFieldDefaultValue(f, state);
            if (defaultValue !== undefined) {
              updateValue(i, defaultValue);
            }
          });
        }, []);
        return (
          <Question
            question={{
              category: category,
              next: props.next
            }}
            enableNext={value.every(v => v != null)}
          >
            <>
              {c.fields.map((field, i) =>
                <div className={styles.fieldContainer} key={field.key}>
                  <QuestionField field={field}>
                    <field.field value={value[i]} setValue={(v: any | null) => updateValue(i, v)} />
                  </QuestionField>
                </div>
              )}
            </>
          </Question>
        );
      }
    } else {
      const field = (fieldOrComponent as FieldDef<any>);
      return function _questionComponent(props: NextQuestionProps) {
        const { dispatch, state } = React.useContext(Context);
        const [value, setValue] = React.useState(getValueFromPath<any>(state.blueprint, field.path!));
        const updateValue = (v: any | null) => {
          setValue(v);
          const attr = generateAttributesFromPath(state.blueprint, field.path!, v);
          dispatch(setAttributes(attr));
        }
        React.useEffect(() => {
          const defaultValue = getFieldDefaultValue(field, state);
          if (defaultValue !== undefined) {
            updateValue(defaultValue);
          }
        }, []);
        return (
          <Question
            field={field}
            question={{
              category: category,
              next: props.next
            }}
            enableNext={value != null && (!field.valid || field.valid(value))}
            addSkip={field.skippable}
          >
            <field.field value={value} setValue={updateValue} />
          </Question>
        );
      }
    }
  }
}

const OwnOrPartner = (handler: (person: "own" | "partner", name: string) => NestedFieldDefElement): NestedFieldDefElement => {
  return Object.entries({
    own: "あなた",
    partner: "配偶者"
  }).map((entry) => {
    return handler(entry[0] as "own" | "partner", entry[1]);
  });
}

export const StudentQuestions = new QuestionCategoryFields("student", "", "",
  (state: AppState) => [
  SelectRadio(Gender)(["own", "gender"], "あなたの性別は？"),
  SelectYearMonth(1920, sessionStartDate().getFullYear())(["own", "birthday"], "あなたの生年月は？", "", {
    valid: (value) => _.inRange(getAge(value), 18, 24),
    warning: (state) =>
      getAge(state.blueprint.own.birthday) > 25 ? "学生版は 25 歳までの方が対象です" :
      getAge(state.blueprint.own.birthday) < 18 ? "18 歳以上の方が対象です" :
      null,
  }),
    InputAge(["own", "startWorkingAge"], "働き始める予定の年齢を教えてください", "例えば浪人せずに 4 年制大学を卒業して就職する場合は 23 歳とします"),
  SelectRadio({ yes: "はい", no: "いいえ" })(["wishPartner"], "将来、結婚を希望していますか？", "迷う場合は今の気持ちに近い方を選んでください"),
    SelectRadio({ yes: "はい", no: "いいえ" })(["wishChild"], "子どもを希望しますか？", "迷う場合は今の気持ちに近い方を選んでください"),
    SelectRadio({ yes: "はい", no: "いいえ" })(["planningToBuyHouse"], "住まいを購入したいですか？", "迷う場合は今の気持ちに近い方を選んでください"),
  SelectExistence(["own", "hasStudentLoanRepayment"], `返済が必要な奨学金はありますか？`),
  state.blueprint.own.hasStudentLoanRepayment && combine(
    InputIncome(["own", "studentLoanMonthlyAmount"], "毎月の奨学金の合計金額", "年単位で受け取っている場合は 12 分割した値を入れてください"),
    InputMonths(["own", "studentLoanReceivingMonths"], "奨学金を受け取る月数"),
  ),
  SelectRadio({regular_employee: "正社員", public_employee: "公務員"})(["own", "profession"], "仕事の種類"),
  state.blueprint.own.profession === "public_employee" && combine(
    Select(civilJobCategories.map(c => c.name))(["own", "jobCategory"], "公務員の種類", "", { key: "own_jobCategory_civil_jobs"}),
    Select((s: AppState) => jobCategoryMap[s.blueprint.own.jobCategory || ""]?.map(job => job.name) || [])(["own", "jobName"], "職種"),
  ),
  state.blueprint.own.profession !== "public_employee" && combine(
    Select(jobCategories.map(c => c.name))(["own", "jobCategory"], "就職先としてイメージする職種"),
    Select((s: AppState) => jobCategoryMap[s.blueprint.own.jobCategory || ""]?.map(job => job.name) || [])(["own", "jobName"], "職種の詳細（職業）"),
  ),
]);

export const DefaultQuestionCategories = [
  new QuestionCategoryFields("family", "家族", questionImage3, (state: AppState) => [
    {
      component: () => (
        <Container width="90%">
          <>
            <p>まずは、今のあなたについて質問します。</p>
            <p>中断しても、入力した内容は自動的に保存します。<br />ご安心ください。</p>
          </>
        </Container>
        ),
      key: "comment"
    },
    SelectRadio(Gender)(["own", "gender"], "あなたの性別は？"),
    SelectYearMonth(1920, sessionStartDate().getFullYear())(["own", "birthday"], "あなたの生年月は？"),
    Select(WishPartnerNames)(["wishPartner"], "結婚していますか？"),
    _.includes(["married"], state.blueprint.wishPartner) && [
      SelectRadio(Gender)(["partner", "gender"], "配偶者の性別は？"),
      SelectYearMonth(1920, sessionStartDate().getFullYear())(["partner", "birthday"], "配偶者の生年月は？"),
    ],
    SelectYesNo(["hasChild"], "子どもはいますか？"),
    state.blueprint.hasChild && [
      SelectNumber({ max: 10, unit: "人" })(["childNum"], "子どもは何人いますか？"),
    ],
    state.blueprint.hasChild && state.blueprint.currentChildren.map((c, i) =>
      SelectYearMonth(state.blueprint.own.birthday.getFullYear() + 16, sessionStartDate().getFullYear())
        (["currentChildren", i, "birthday"], `${nthChildName(i)}の生年月は？`)),
    SelectRadio(WishChildNames)(["wishChild"], "今後、子どもを希望しますか？", "すでにいる子どもは含めません。"),
    state.blueprint.doesWishChild && [
      SelectNumber({ max: 10, unit: "人" })(["wishChildNum"], "今後、希望する子どもの人数は？", "すでにいる子どもは含めません。"),
    ],
  ]),
  new QuestionCategoryFields("money", "仕事・貯金", questionImage4, (state: AppState) => [
    OwnOrPartner((person, name) =>
      (person === "own" || (person === "partner" && state.blueprint.wishPartner === "married")) &&
      [
        Select(ProfessionNames)([person, "profession"], `${name}の仕事は？`,
          person === "own" ? "生涯収入の推計に使います。副業されている方は、後ほど入力いただけます。" : ""),
        professionHasSalary(state.blueprint.own.profession) && [
          combine(
            InputMoney([person, "monthlySalary"], `${name}の月収の手取りは？`, "毎月変わったり正確に分からない場合は、およその平均額を回答ください。", {
              warning: (state) => {
                if ((state.blueprint[person]!.monthlySalary || 0) > 1500000) {
                  return "年収の値ではないでしょうか？月収として正しければ、このまま進んでください。";
                }
              }
            }),
            InputMoney([person, "bonus"], `${name}のボーナスの手取りは？`, "直近のおよその年額を回答ください。ボーナスがない場合は 0 と記入してください。"),
          ),
        ],
        InputMoney([person, "savings"],
          `${name}名義の預貯金の合計は？`,
          "普通口座・定期口座・現金が対象で、投資用の資産や不動産は除きます。正確に分からない場合は、およその金額を回答ください。",
          {
            comment: (state) => "own" === person && state.blueprint.estimate?.savings ?
              "Zaim の記録から預貯金を自動で推定しました。実際と異なる場合は値を調整してください。" :
              ""
          }),
      ]),
  ]),
  new QuestionCategoryFields("home", "住まい", questionImage2, (state: AppState) => [
    InputTextWithOptions({ pattern: "\\d*", maxLength: 8, placeholder: "1234567" })(["postCode"], "あなたのお住まいの場所は？", "郵便番号をハイフンなしで入力してください。", {
      valid: (value) => /^\d{3}-?\d{4}$/.test(value),
      skippable: true
    }),
    SelectRadio(HouseOwnershipNames)(["houseOwnership"], "今の住まいの形態は？"),
    state.blueprint.houseOwnership === "owned" && [
      SelectRadio(NewOrUsedHouseNames)(["currentHouse", "newOrUsed"], "購入時の住まいの状態は？"),
      SelectRadio(HouseTypeNames)(["currentHouse", "houseType"], "持ち家の種類は？"),
      InputMoney(["currentHouse", "price"], "住まいの購入価格は？", "諸経費なども含めます。不明な場合は大体の金額で回答ください。", { skippable: true }),
      InputMoney(["housingLoanRepayment"], "毎月の住宅ローンは？", "払い終わっている場合は 0 と回答ください。"),
      (state.blueprint.housingLoanRepayment || 0) > 0 &&
      InputYearsFromNow(["housingLoanFullPayment"], "住宅ローンの残り年数は？", "", { skippable: true }),
      state.blueprint.currentHouse?.houseType === "apartment" &&
      InputMoney(["currentHouse", "monthlyManagementCost"], "月の管理費は？", ""),
    ],
    state.blueprint.houseOwnership === "rental" &&
    InputExpense(["rent"], "月の家賃は？"),
    state.blueprint.houseOwnership !== "owned" &&
    SelectRadio(PlanningToBuyHouseNames)(["planningToBuyHouse"], "住まいを購入したいですか？"),
  ]),
  new QuestionCategoryFields("car", "クルマ", questionImage1, (state: AppState) => [
    SelectYesNo(["hasCar"], "クルマを持っていますか？"),
    state.blueprint.hasCar && [
      SelectNumber({ max: 5, unit: "台" })(["numCars"], "クルマは何台持っていますか？"),
    ],
    state.blueprint.cars.map((car, i) => [
      InputAge(["cars", i, "purchaseAge"], "買ったのは何歳の頃ですか？"),
      InputMoney(["cars", i, "price"], "その時のクルマの価格は？", "覚えていない場合は、およその金額で大丈夫です。"),
      SelectYesNo(["cars", i, "useLoan"], "ローンを払っていますか？"),
      state.blueprint.cars[i]?.useLoan && [
        InputExpense(["cars", i, "loanRepayment"], "ローンの毎月の支払額は？"),
        InputYearsFromNow(["cars", i, "loanFullPayment"], "ローンの残りの年数は？"),
      ],
      //InputExpense(["cars", i, "tax"], "自動車税"),
    ]),
  ]),
  new QuestionCategoryFields("other", "その他", questionImage5, (state: AppState) => [
    InputExpense(["monthlyLivingCost"], "月の日常費は？",
      "住まいやクルマや教育費、保険を除外した、毎月かかる生活費のことです。",
      {
        defaultValue: (s) =>
          s.blueprint.defaultLivingCost.value,
        comment: (s) =>
          <EstimatedExpense expense={s.blueprint.defaultLivingCost}
            title={s.blueprint.defaultLivingCost.isAverage ?
              "家族構成をもとに、Zaim の統計から生活費を推定しました。実際と異なる場合は値を調整してください。" :
              "あなたの Zaim の記録によると直近 3 か月の平均は以下の通りです。必要に応じて値を調整してください。"}
          />
      }),
    InputExpense(["annualBasicExpense"], "年間にかかる大型出費は？",
      "旅行や家電の買い替え、趣味など、毎月はかからないが金額が大きい支出の、およその金額を回答してください。",
      {
        defaultValue: (s) =>
          s.blueprint.defaultAnnualBasicExpense.value,
        comment: (s) =>
          <EstimatedExpense expense={s.blueprint.defaultAnnualBasicExpense}
            title={s.blueprint.defaultAnnualBasicExpense.isAverage ?
              "家族構成をもとに年間にかかる大型出費を推定しました。実際と異なる場合は値を調整してください。" :
              "あなたの Zaim の記録によると直近 3 か月の平均は以下の通りです。必要に応じて値を調整してください。"}
          />
      }),
    SelectExistence(["hasDebt"], "借入金はありますか？", "すでに回答いただいた住まいやクルマのローンは含みません。"),
    state.blueprint.hasDebt && [
      InputMoney(["debt", 0, "balance"], "借入金の残りは？", "複数の借入がある場合は、その合計額を回答ください。"),
      InputMoney(["debt", 0, "monthlyPayment"], "借入金の毎月の返済額は？", "複数の借入がある場合は、その返済額の合計を回答ください。"),
    ],
  ]),
];

export const getProgress = (state: AppState) => {
  const blueprint = state.blueprint;
  const categories = blueprint.isStudentMode ? [StudentQuestions] : DefaultQuestionCategories;
  const c = categories.filter(cat =>
    blueprint.completed.includes(cat.category)).length;
  const base = c / categories.length;
  let offset = 0;
  for (const cat of categories) {
    const pages = cat.createPages(state);
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      if (page.path === state.currentPage) {
        offset = i / pages.length / categories.length;
        break;
      }
    }
  }
  return (base + offset) * 100;
};

export const QuestionCategories =
  [...DefaultQuestionCategories, StudentQuestions];

export const QuestionIndex = () => {
  const { state } = React.useContext(Context);
  if (finishedQuestions(state.blueprint)) {
    return <QuestionCompleted />;
  } else {
    return (
      <>
        <div className={styles.section}>
          {DefaultQuestionCategories.map((cat) => {
            return (
              <div key={cat.category}>
                <QuestionCategory
                  category={cat.category}
                  title={cat.categoryTitle}
                  path={cat.createPages(state)[0].path}
                  icon={cat.icon}
                  key={cat.category} />
                { cat.category === "family" &&
                  !_.includes(state.blueprint.completed, "family") &&
                  <Comment>
                    質問への回答は、ざっくりでも大丈夫です。まずは「家族」について教えてください。
                </Comment>
                }
                { cat.category === "money" &&
                  !_.includes(state.blueprint.completed, "money") &&
                  _.includes(state.blueprint.completed, "family") &&
                  <Comment>
                    今の仕事や収入、そのうち今後どれくらいを貯金に回したいかなどを、お聞きます。
                </Comment>
                }
                { cat.category === "home" &&
                  _.includes(state.blueprint.completed, "money") &&
                  !_.includes(state.blueprint.completed, "home") &&
                  <Comment>
                    今の住まいの状況、および持ち家派か賃貸派かなどを伺います。
                </Comment>
                }
                { cat.category === "car" &&
                  _.includes(state.blueprint.completed, "home") &&
                  !_.includes(state.blueprint.completed, "car") &&
                  <Comment>
                    クルマを所有しているか、および今後、購入する可能性があるかを教えてください。
                </Comment>
                }
                { cat.category === "other" &&
                  _.includes(state.blueprint.completed, "car") &&
                  !_.includes(state.blueprint.completed, "other") &&
                  <Comment>
                    最後に今の生活費や、借りているお金がないかを伺います。
                </Comment>
                }
              </div>
            )
          })}
        </div>
      </>
    );
  }
};

export const nextQuestion = (dispatch: (action: Actions) => void, state: AppState, path: string) => {
  saveState(state);
  window.history.pushState(true, "", path);
  dispatch(changePage(path));
}

export const completeQuestionCategory = (dispatch: (action: Actions) => void, state: AppState, question: QuestionCategoryName) => {
  window.history.pushState(true, "", questionIndexPath);
  dispatch(setAttributes({
    completed: [...state.blueprint.completed, question]
  }));
  dispatch(changePage(questionIndexPath, false));
};

export const completeQuestionCategoryHook = (state: AppState, question: QuestionCategoryName) => () => {
  // 完了した段階で saveState を実行する
  if (state.blueprint.completed.includes(question)) {
    saveState(state);
  }
};
