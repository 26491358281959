import React from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import firebase, { functionURL } from '../firebase';
import debug from '../debug';
import GetUser from './GetUser';
import { LoadToken } from './LoadToken';

const GetAccessToken = (props: {code: string, code_verifier: string}) => {
  const [message, setMessage] = React.useState('情報を読み込んでいます…');
  const [token, setToken] = React.useState('');
  React.useEffect(() => {
    axios.get(functionURL('getAccessToken'), {
      params: { ...props, redirect_uri: window.location.origin }
    })
    .then((r: AxiosResponse) => {
      debug('getAccessToken success');
      debug(r.data);
      if (r.data.error) {
        setMessage(r.data.error);
        throw r.data.error;
      } else {
        return r.data;
      }
    })
    .then((data) => {
      debug(data);
      const path = sessionStorage.getItem('redirect_path') ?? '/';
      debug(`redirecting to ${path}`);
      window.history.pushState(true, '', path);
      setToken(data.token);
      //window.location.href = path ?? '/';
      /*
      firebase.auth().signInWithCustomToken(data.token)
      .then((cred) => {
        console.log('sign in success');
        console.log(cred);
        console.log(cred.user?.uid);
      })
      .catch((err) => {
        console.log('failed to sign in');
        console.log(err);
      });
      setMessage(JSON.stringify(data));
      */
    })
    .catch((err) => {
      console.log(err);
    })
  }, [props.code, props.code_verifier]);

  if (token) {
    return <LoadToken token={token} />
  } else {
    return (
      <p className="update">
         {message}
      </p>
    )
  }
}

export default GetAccessToken;