import * as React from "react";
import styles from "./settings.module.scss";
import Link from "../../atoms/Link";
import { Context, saveState, ProfessionNames, BlueprintKeyPath, setAttributes, getValueFromPath, generateAttributesFromPath, AppState, professionHasSalary, SchoolTypeNames, Gender, WishPartnerNames, Properties, HouseOwnershipNames, NewOrUsedHouseNames, HouseTypeNames, PlanningToBuyHouseNames, Blueprint, HasInsuranceNames, PeriodicInvestment, FilteredKeys, WishChildNames, WorkAfterBirthNames, PlanningToBuyCarNames, InitialCar, sessionStartDate, IndependentHousePlanNames, HighschoolTypeNames, PreschoolTypeNames, UniversityTypeNames, GraduateSchoolTypeNames, Investment, nthChildName, InitialPeriodicInvestment, InitialInvestment, ExaminationTypeNames, LessonTypeNames, SideJob, CarSellPlanNames, NewOrUsedCarNames, defaultCarSellPrice, MovingPlan, DEFAULT_HOUSE_REFORM_COST } from "../../Store";
import _ from "lodash";
import { Select, InputMoney, SelectRadio, InputAge, SelectYearMonth, SelectYesNo, SelectNumber, SelectDate, FieldDef, SelectObjectExistence, InputYears, SelectExistence, SelectBoolean, getFieldTitle, SelectRadioValueField, DefineField, InputText, getFieldDefaultValue, InputExpense, InputYear, InputIncome, SelectValueField, InputMonths, InputTextWithOptions, SelectYearFromNow, FixedExpense, FixedIncome, InputNumber, FixedNumber, InputYearsFromNow, InputAltIncome } from "../../templates/fields";
import homeIcon from "./home.svg";
import carIcon from "./car.svg";
import educationIcon from "./education.svg";
import expenseOtherIcon from "./expense_other.svg";
import livingIcon from "./living.svg";
import chartIcon from "./chart.svg";
import workIcon from "./work.svg";
import retirementIcon from "./retirement.svg";
import incomeOtherIcon from "./income_other.svg";
import helpIcon from "./help.svg";
import ResetButton from "../../templates/ResetButton";
import { Age, formatMoneyWithUnit, formatSmallMoneyWithUnit, getAge, Person, Simulator } from "../../simulator";
import SubtotalFooter, { Subtotal } from "../../templates/SubtotalFooter";
import Help from "./Help";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@material-ui/core";
import EditSettings, { SectionComponents } from "../../templates/EditSettings";
import FixedNumberField from "../../molecules/FixedNumberField";
import debug from "../../debug";
import { civilJobCategories, getJobNames, jobCategories } from "../../Store/jobs";
import { UserDetail } from "../../Store/functions";

export const settingsPath = (name: string) => {
  return `/settings/${name}`;
}

//type ValueComponent = (path: BlueprintKeyPath, title: string) => React.FunctionComponent;
const createComponent = (field: FieldDef<any>) =>
  function _settingsComponent(props: { plan: Simulator }) {
    type UpdateQueue = {
      timer: number | null;
      value: any | null;
    };
    const { dispatch, state } = React.useContext(Context);
    const [value, setValue] = React.useState(
      field.path ? getValueFromPath<any>(state.blueprint, field.path) : null);
    const [focused, setFocused] = React.useState(false);
    const [openHelp, setOpenHelp] = React.useState(false);
    const [lastQueue, setLastQueue] = React.useState<UpdateQueue | null>(null);
    const delay = field.delayed ? 500 : 0;
    const onFocusChange = (f: boolean) => { setFocused(f); if (!f) flushQueue(lastQueue); };
    const setAttributesFromValue = (v: any | null) => {
      if (field.path) {
        const attr = generateAttributesFromPath(state.blueprint, field.path, v);
        dispatch(setAttributes(attr));
      }
    }
    const flushQueue = (queue: UpdateQueue | null) => {
      if (queue) {
        if (queue.timer) window.clearTimeout(queue.timer);
        setAttributesFromValue(queue.value);
        setLastQueue(null);
      }
    }
    const queueUpdate = (value: any | null) => {
      // 少し待ってから最新の値で更新する。以前の値がキューに残っている場合はキャンセルする
      if (lastQueue && lastQueue.timer) {
        window.clearTimeout(lastQueue.timer);
      }
      const queue: UpdateQueue = {timer: null, value};
      queue.timer = window.setTimeout(() => flushQueue(queue), delay);
      setLastQueue(queue);
    }
    const updateValue = (v: any | null, delayed = true) => {
      setValue(v);
      if (delayed && delay > 0) {
        // テキストフィールドでキーを入力するたびに値を更新すると処理が追いつかない場合があるので、
        // ある程度待ってから更新する
        queueUpdate(v);
      } else {
        setAttributesFromValue(v);
      }
    }
    React.useEffect(() => {
      // unmount 時に flush する
      return () => flushQueue(lastQueue);
    }, []);
    React.useEffect(() => {
      const defaultValue = getFieldDefaultValue(field, state, props.plan);
      if (defaultValue !== undefined) {
        updateValue(defaultValue, false);
      }
    }, [state, props.plan]);
    // 他のフィールドが変更された影響で自分の値が変更される場合に対応する。
    // クルマの売却時の年齢をリアルタイムに表示するのに必要だが、パフォーマンスが悪ければコメントアウトしてもいいかも
    React.useEffect(() => {
      if (!focused) {
        if (field.path) {
          setValue(getValueFromPath<any>(state.blueprint, field.path))
        }
      }
    }, [state.blueprint]);
    const warning = field.warning ? field.warning(state, props.plan) : null;
    return (
      <>
        <div className={`${styles.fieldContainer} ${field.parent ? styles.parent : ""} ${field.child ? styles.child : ""}`}>
          <div className={styles[`type-${field.fieldType}`]}>
            {field.fieldType && {
              income: "収入",
              expense: "支出",
              config: "設定",
              asset: "資産",
              investment: "投資",
            }[field.fieldType]}
          </div>
          <div className={styles.field}>
            {getFieldTitle(field, state) &&
              <div className={styles.valueTitle}>
                <div>
                  {getFieldTitle(field, state)}
                </div>
                {field.help &&
                  <img src={helpIcon} onClick={() => setOpenHelp(true)} />
                }
              </div>
            }
            {field.subtitle &&
              <div className={styles.valueSubtitle}>
                {field.subtitle}
              </div>
            }
            <div className="settingsField">
              <field.field value={value} setValue={updateValue} name={field.key}
                onFocusChange={onFocusChange} altValue={field.altValue && field.altValue(state, props.plan)} />
            </div>
            {field.comment &&
              <div className={styles.valueComment}>
                {typeof field.comment === "function" ? field.comment(state, props.plan) : field.comment}
              </div>
            }
            {warning &&
              <div className={styles.warning}>
                {warning}
              </div>
            }
          </div>
        </div>
        { field.help &&
          <Dialog open={openHelp} onClose={() => setOpenHelp(false)} className={styles.helpDialog}>
            <DialogTitle>{field.title}</DialogTitle>
            <DialogContent >
              {field.help}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenHelp(false)}>
                閉じる
          </Button>
            </DialogActions>
          </Dialog>
        }
      </>
    );
  }

//const SelectYearMonth = (startYear: number, endYear: number) =>
//  ValueComponent(SelectRa

type FlatSettingsComponentsElement = FieldDef<any> | false | undefined | null;
type SettingsComponentsElement = SettingsComponentsElement[] | FlatSettingsComponentsElement;
type SettingsComponents = SettingsComponentsElement[];

const cachedComponents: { [key: string]: [React.FC<{ plan: Simulator }>, FieldDef<any>] } = {};

export type Section = {
  title: string;
  components: (state: AppState) => SettingsComponents
}

//{[key:string]: ValueComponent}
const SettingsPage = (title: string, sections: Section[] | ((state: AppState) => Section[]), subtotalSection?: Subtotal, elements?: JSX.Element) => {
  const page = function _component() {
    const { state } = React.useContext(Context);
    const [plan, setPlan] = React.useState(null as null | Simulator);
    React.useEffect(() => {
      setPlan(new Simulator(state.blueprint));
    }, [state.blueprint]);
    const theSections =
      typeof sections === "function" ? sections(state) : sections;
    // 新しい function component を毎回作成すると input などの element が毎回作成しなおされてしまうため、
    // 一度作成した function component は使い回すようにする。
    const componentsBySections: SectionComponents[] = theSections.map((section) => {
      const comp =
        _.compact(_.flattenDeep<SettingsComponentsElement>(section.components(state)) as FlatSettingsComponentsElement[]).map((c) => {
          const key = (c as FieldDef<any>).key as string;
          let cache = cachedComponents[key];
          if (!cache) {
            //console.log('new key', key);
            cache = cachedComponents[key] = [createComponent(c), c as FieldDef<any>];
          }
          return { key: key, component: cache[0], field: cache[1] };
        });
      return [section, comp];
    });
    return (
      <>
        { plan &&
          <EditSettings title={title} sections={componentsBySections}
            plan={plan} subtotal={subtotalSection} elements={elements} />
        }
      </>
    );
  }
  if (subtotalSection) {
    page.subtotal = subtotalSection.value;
  }
  page.title = title;
  return page;
}

export type SettingsPageComponent = ReturnType<typeof SettingsPage>;

export type PageDefinition = {
  key: string,
  icon: string,
  page: SettingsPageComponent,
  skip?: (state: AppState) => boolean;
};

// { child: true } を { child } のように表現するため
const child = true, parent = true;

const SelectPeriodicInvestment = (
  DefineField(SelectValueField(
    { yes: "利用している", planned: "これから始めたい", no: "未定・やらない" },
    (value: string): PeriodicInvestment | null =>
      value === "yes" ? { ...InitialPeriodicInvestment, started: true } :
        value === "planned" ? { ...InitialPeriodicInvestment, started: false } : null,
    (value: PeriodicInvestment | null) =>
      value != null ? (value.started ? "yes" : "planned") : "no",
    { allowNull: true },
  ), { fieldType: "config" }));

const SelectSpotInvestment = (
  DefineField(SelectValueField(
    { yes: "している", planned: "これから始めたい", no: "未定・やらない" },
    (value: string): Investment | null =>
      value === "yes" ? { ...InitialInvestment, purchased: true } :
        value === "planned" ? { ...InitialInvestment, purchased: false } : null,
    (value: Investment | null) =>
      value != null ? (value.purchased ? "yes" : "planned") : "no",
    { allowNull: true },
  ), { fieldType: "config" }));

const SelectSideJob = (
  DefineField(SelectValueField(
    { yes: "している", planned: "今後したい", no: "未定・やらない" },
    (value: string): SideJob | null =>
      value === "yes" ? { started: true } :
        value === "planned" ? { started: false } : null,
    (value: SideJob | null) =>
      value != null ? (value.started ? "yes" : "planned") : "no",
    { allowNull: true },
  ), { fieldType: "config" }));

const SelectMovingPlan = (
  DefineField(SelectValueField(
    { yes: "はい", no: "いいえ", auto: "家族構成に応じて住み替える" },
    (value: string): MovingPlan | null =>
      value === "yes" ? { auto: false } :
      value === "auto" ? { auto: true } :
      null,
    (value: MovingPlan | null) =>
      value != null ? (value.auto ? "auto" : "yes") : "no",
    { allowNull: true },
  ), { fieldType: "config" }));

const InvestmentFields = (person: "own" | "partner") => {
  const periodicInvestments: [FilteredKeys<Properties, PeriodicInvestment[]>, string][] =
    [["periodicInvestments", "投資信託の積立"], ["ideco", "iDeCo"], ["nisa", "一般 NISA"], ["tsumitateNisa", "つみたて NISA"], ["dc", "企業型 DC（確定拠出年金）"]];
  return (state: AppState) => [
    InputMoney([person, "savings"], "預貯金", "", {
      fieldType: "asset",
      comment: (state, plan) => state.blueprint.estimate?.savings ?
        `Zaim の家計記録によると、直近の預貯金は ${formatSmallMoneyWithUnit(state.blueprint.estimate.savings)}です` :
        ""
    }),
    periodicInvestments.map(([invName, label]) => {
      return [
        SelectPeriodicInvestment([person, invName, 0], `${label}`, "", { parent, help: Help[invName]?.default }),
        (state.blueprint[person]![invName as keyof Properties] as PeriodicInvestment[]).map((invest, i) => [
          invest.started ?
            InputAge([person, invName, i, "startAge"], "始めた年齢", "", { key: `${person}_${invName}_${i}_startAge#started`, child }) :
            InputAge([person, invName, i, "startAge"], "始める年齢", "", { child }),
          InputAge([person, invName, i, "endAge"], "辞める予定の年齢", "", { child }),
          InputAge([person, invName, i, "sellAge"], "売却する予定の年齢", "", { child }),
          InputMoney([person, invName, i, "monthlyInvestment"], "毎月の積立額", "", { fieldType: "investment", child }),
          SelectNumber({ min: 1, max: 10, unit: "%" })([person, invName, i, "yield"], "平均利回り", "", { child }),
          invName === "periodicInvestments" && i < 5 && SelectPeriodicInvestment([person, invName, i + 1], `さらに他の${label}`, "", { parent }),
        ])
      ];
    }),
    SelectSpotInvestment([person, "investments", 0], "その他の資産運用", "預貯金以外の資産がある場合は「している」としてください。"),
    state.blueprint[person]?.investments.map((invest, i) => [
      InputAge([person, "investments", i, "purchaseAge"], "購入した年齢"),
      InputAge([person, "investments", i, "sellAge"], "売却する予定の年齢"),
      InputMoney([person, "investments", i, "purchasePrice"], "購入時の金額"),
      SelectNumber({ min: 1, max: 10, unit: "%" })([person, "investments", i, "yield"], "平均利回り"),
      i < 5 && SelectSpotInvestment([person, "investments", i + 1], `さらに他の資産運用`),
    ]),
  ];
}

const WorkFields = (person: "own" | "partner") => {
  return (state: AppState) => [
    person === "own" && state.blueprint.isStudentMode &&
      InputAge([person, "startWorkingAge"], "働き始める年齢"),
    Select(ProfessionNames)([person, "profession"], "雇用形態"),
    (state.blueprint[person]!.profession === "regular_employee" ||
     state.blueprint[person]!.profession === "public_employee") &&
      SelectBoolean({ no: "手取りを入力", yes: "職業で選ぶ" })([person, "useJobName"], "収入の試算方法", ""),
     professionHasSalary(state.blueprint[person]?.profession) && [
      state.blueprint[person]!.useJobName ? 
      (state.blueprint[person]!.profession === "public_employee" ?
      [
        Select(civilJobCategories.map(c => c.name))([person, "jobCategory"], "公務員の種類", "", {
          help: Help.jobCategory, key: `${person}_jobCategory_civil` }),
        Select((s: AppState) => getJobNames(s.blueprint[person]!.jobCategory || "") || [])(
          [person, "jobName"], "職種", "", { key: `${person}_jobName_civil`}),
      ] : [
        Select(jobCategories.map(c => c.name))([person, "jobCategory"], "職種", "", { help: Help.jobCategory }),
        Select((s: AppState) => getJobNames(s.blueprint[person]!.jobCategory || "") || [])(
          [person, "jobName"], "職業", ""),
      ]) : [
        InputIncome([person, "monthlySalary"], "月収（手取り）", "", {
          warning: (state) => {
            if ((state.blueprint[person]!.monthlySalary || 0) > 1500000) {
              return "年収の値ではないでしょうか？月収として正しければ、このまま進んでください。";
            }
          }
        }),
        InputIncome([person, "bonus"], "年間ボーナス（手取り）"),
        SelectNumber({ min: 0, max: 5, unit: "%" })([person, "salaryIncreaseRate"], "昇給率", "年間でどれだけ昇給していくかを設定できます。54 歳が年収ピークとします。"),
      ],
      state.blueprint.wishChild === "yes" && [
        Select(WorkAfterBirthNames)([person, "workAfterBirth"], "子どもが生まれた後の働き方", "", { parent, help: Help.workAfterBirth }),
        state.blueprint[person]?.workAfterBirth !== "retire" && [
          state.blueprint[person]?.gender === "female" &&
          state.blueprint[person === "own" ? "partner" : "own"]?.gender === "male" &&
          InputMonths([person, "maternityLeaveMonths"], "産休の期間", "", { child }),
          InputMonths([person, "childcareLeaveMonths"], "育休の期間", "", { child, help: Help.childcareLeaveMonths }),
          SelectYesNo([person, "reduceWorkAfterBirth"], "時短勤務の選択", "", { child }),
          state.blueprint[person]?.reduceWorkAfterBirth && [
            InputYears([person, "reducedWorkYears"], "時短勤務の希望期間", "", { child, help: Help.reducedWorkYears }),
            InputNumber("%")([person, "reducedWorkSalaryPercent"], "時短期間の給与", "", { child, help: Help.reducedWorkSalaryPercent }),
          ],
        ],
      ],
    ],
    Select<Properties["wishJobChange"]>({ yes: "あり", no: "なし", unknown: "分からない" })(
      [person, "wishJobChange"], "今後、転職の希望", "",
      { parent }
    ),
    state.blueprint[person]!.wishJobChange === "yes" && state.blueprint[person]!.jobChanges.map((job, i) => [
      InputAge([person, "jobChanges", i, "age"], "想定する年齢", "", { child }),
      Select(ProfessionNames)([person, "jobChanges", i, "profession"], "希望する雇用形態", "", { child }),
      state.blueprint[person]!.jobChanges[i]?.profession === "regular_employee" &&
        SelectBoolean({ no: "手取りを入力", yes: "職業で選ぶ" })([person, "jobChanges", i, "useJobName"], "収入の試算方法", "", { child }),
      ...professionHasSalary(state.blueprint[person]!.jobChanges[i]?.profession) ? (
        state.blueprint[person]!.jobChanges[i]?.useJobName ? [
          Select(jobCategories.map(c => c.name))([person, "jobChanges", i, "jobCategory"], "職種", "", { child, help: Help.jobCategory }),
          Select((s: AppState) => getJobNames(s.blueprint[person]!.jobChanges[i]?.jobCategory || "") || [])(
            [person, "jobChanges", i, "jobName"], "職業", "", { child }),
        ] : [
          InputIncome([person, "jobChanges", i, "monthlySalary"], "希望する月収（手取り）", "", { child }),
          InputIncome([person, "jobChanges", i, "bonus"], "希望するボーナス（手取り）", "", { child }),
        ]
      ) : [],
      i < 5 && SelectObjectExistence()([person, "jobChanges", i + 1], "さらに転職の希望", "", { parent }),
    ]),
    SelectSideJob([person, "sideJobs", 0], "副業", "", { parent }),
    state.blueprint[person]!.sideJobs.map((job, i) => [
      !job.started && InputAge([person, "sideJobs", i, "startAge"], "始める年齢", "", { child }),
      InputAge([person, "sideJobs", i, "endAge"], "辞める予定の年齢", "", { child }),
      InputIncome([person, "sideJobs", i, "monthlyIncome"], "手取りの月収", "", { child }),
      i < 5 && SelectSideJob([person, "sideJobs", i + 1], `さらに他の副業`, "", { parent }),
    ]),
    InputAge([person, "reemploymentAge"], "再雇用に切り替わる年齢", "個人事業主など再雇用がない場合はリタイア年齢と同じにしてください。", { help: Help.ReemploymentAge }),
    InputAge([person, "retirementAge"], "完全リタイアする年齢", "", { help: Help.retirementAge }),
  ];
};

const selectPensionNumber = (pension: UserDetail["pension"]) => {
  const maskNumber = (input: string) => {
    const lastThreeDigits = input.slice(-3);
    const maskedString = input.slice(0, -3).replace(/\d/g, 'X');
    return maskedString + lastThreeDigits;
  }  
  return Object.fromEntries(
    pension?.map((p) => [p.basicPensionNumber, maskNumber(p.basicPensionNumber)]) ?? []);
}

const RetirementFields = (person: "own" | "partner") => {
  return (state: AppState) => [
    SelectBoolean({ yes: "あり", no: "なし" })([person, "hasSeverancePay"], "一時金型の退職金", "", { parent, help: Help.hasSeverancePay }),
    state.blueprint[person]!.hasSeverancePay && [
      InputAge([person, "severancePayAge"], "一時金を受給する予定の年齢", "", { child }),
      InputMoney([person, "severancePay"], "一時金の予定額", "", { child }),
    ],
    SelectBoolean({ yes: "あり", no: "なし" })([person, "hasCorporatePension"], "年金型の退職金", "", { parent, help: Help.hasCorporatePension }),
    state.blueprint[person]!.hasCorporatePension && [
      InputAge([person, "corporatePensionStartAge"], "年金の受給が始まる年齢", "", { child }),
      InputAge([person, "corporatePensionEndAge"], "年金の受給が終わる年齢", "", { child }),
      InputMoney([person, "corporatePensionBenefit"], "年金の月額", "", { child }),
    ],
    InputAge([person, "pensionBenefitAge"], "公的年金の受給を始める年齢", "", {
      help: Help.pensionBenefitAge,
      warning: (state) => {
        const value = state.blueprint[person]!.pensionBenefitAge || 0;
        if (value < 60 || value > 70) {
          return "60 から 70 の間の年齢を入力してください";
        }
      }
    }),
    (state.blueprint.estimate.pension?.length ?? 0) > 1 &&
      Select(selectPensionNumber(state.blueprint.estimate.pension!))([person, "basicPensionNumber"], "基礎年金番号"),
    InputAltIncome([person, "customMonthlyPension"],
      "年金受給額（月額）", "",
      {
        altValue: (state, plan) => {
          const p = plan![person] as Person;
          if (p) {
            return p.nationalPension() / 12;
          } else {
            return 0;
          }
        },
        comment: (state, plan) => {
          const p = plan![person] as Person;
          if (p && p.nationalPensionFromUserDetail()) {
            return "ねんきんネットから取得した概算値です";
          } else {
            return <>
              <a href="https://zaim.net/websites?type=securities" target="_blank">ねんきんネット</a>と連携すると概算値を取り込みます
            </>
          }
        },
      }),
    InputAge([person, "lifespan"], "想定する亡くなる年齢", "", { parent, help: Help.lifespan }),
  ];
}

const OwnOrPartner = <T extends unknown>(state: AppState, handler: (person: "own" | "partner", name: string) => T): T[] => {
  return _.compact(Object.entries({
    own: "あなた",
    partner: "配偶者"
  }).map((entry) => (
    (entry[0] === "own" || (entry[0] === "partner" && state.blueprint.wishPartner === "married")) &&
    handler(entry[0] as "own" | "partner", entry[1])
  )));
}

const insuredList = (state: AppState) => _.compact(
  [
    "わたし",
    state.blueprint.hasPartner && "配偶者",
    ...Array((state.blueprint.childNum || 0) + (state.blueprint.wishChildNum || 0)).
      fill(null).map((v, n) => nthChildName(n))
  ]
)

const insuranceCompanyList = [
  "アクサ生命",
  "朝日生命",
  "アフラック",
  "FWD 富士生命",
  "オリックス生命",
  "かんぽ生命",
  "ジブラルタ生命",
  "住友生命",
  "ソニー生命",
  "損保ジャパン日本興亜ひまわり生命",
  "第一生命",
  "大同生命",
  "太陽生命",
  "チューリッヒ生命",
  "東京海上日動あんしん生命",
  "日本生命",
  "ネオファースト生命",
  "富国生命",
  "プルデンシャル生命",
  "三井住友海上あいおい生命",
  "三井生命",
  "メットライフ生命",
  "明治安田生命",
  "メディケア生命",
  "ライフネット生命",
  "楽天生命",
  "覚えていない",
  "その他",
]

const specialIncomeOrExpense = (_type: "income" | "expense", name: string, state: AppState) => {
  const [special, monthly, repeated] =
    _type === "income" ?
      ["specialIncome", "monthlyIncome", "repeatedIncome"] as const :
      ["specialExpense", "monthlyExpense", "repeatedExpense"] as const;
  return [
    SelectObjectExistence()([special, 0], `単発の臨時${name}`, "", { parent }),
    state.blueprint[special].map((obj, i) => [
      InputText([special, i, "name"], `${name}の名称`, "", { child }),
      InputAge([special, i, "age"], `想定している年齢`, "", { child }),
      InputMoney([special, i, "amount"], "金額", "", { fieldType: _type, child }),
      i < 5 && SelectObjectExistence()([special, i + 1], `さらなる単発の臨時${name}`, "", { parent }),
    ]),
    SelectObjectExistence()([monthly, 0], `毎月の定期${name}`, "", { parent }),
    state.blueprint[monthly].map((obj, i) => [
      InputText([monthly, i, "name"], `定期${name}の名称`, "", { child }),
      InputAge([monthly, i, "startAge"], `定期${name}が始まる年齢`, "すでに始まっている場合は、今の年齢を回答してください。", { child }),
      InputAge([monthly, i, "endAge"], `定期${name}が終わる予定の年齢`, "", { child }),
      InputMoney([monthly, i, "amount"], "金額", "", { fieldType: _type, child }),
      i < 5 && SelectObjectExistence()([monthly, i + 1], `さらなる毎月の定期${name}`, "", { parent }),
    ]),
    SelectObjectExistence()([repeated, 0], `繰り返しの臨時${name}`, "", { parent }),
    state.blueprint[repeated].map((obj, i) => [
      InputText([repeated, i, "name"], `繰り返し${name}の名称`, "", { child }),
      InputAge([repeated, i, "startAge"], `繰り返し${name}が始まる年齢`, "すでに始まっている場合は、今の年齢を回答してください。", { child }),
      InputAge([repeated, i, "endAge"], `繰り返し${name}が終わる予定の年齢`, "", { child }),
      InputYears([repeated, i, "spanYears"], `何年ごとに繰り返す${name}か`, "", { child }),
      InputMoney([repeated, i, "amount"], "金額", "", { fieldType: _type, child }),
      i < 5 && SelectObjectExistence()([repeated, i + 1], `さらなる繰り返しの臨時${name}`, "", { parent }),
    ]),
  ]
};

export const FamilySettingsPage = SettingsPage("家族構成", [
  {
    title: "あなた",
    components: (state) => [
      Select(Gender)(["own", "gender"], "性別"),
      SelectYearMonth(1920, sessionStartDate().getFullYear())(["own", "birthday"], "生年月"),
    ]
  },
  {
    title: "配偶者",
    components: (state) => [
      Select(WishPartnerNames)(["wishPartner"], "配偶者"),
      state.blueprint.hasPartner && [
        Select(Gender)(["partner", "gender"], "配偶者の性別"),
        SelectYearMonth(1920, sessionStartDate().getFullYear())(["partner", "birthday"], "配偶者の生年月"),
      ],
    ]
  },
  {
    title: "子ども",
    components: (state) => [
      SelectYesNo(["hasChild"], "子ども"),
      state.blueprint.hasChild && [
        SelectNumber({ max: 10, unit: "人" })(["childNum"], "子どもの人数"),
        state.blueprint.currentChildren.map((c, i) =>
          SelectYearMonth(state.blueprint.own.birthday.getFullYear() + 16, sessionStartDate().getFullYear())
            (["currentChildren", i, "birthday"], `${nthChildName(i)}の生年月`)),
      ],
      Select(WishChildNames)(["wishChild"], "子どもの希望", "すでに誕生している子は含みません。"),
      state.blueprint.doesWishChild && [
        SelectNumber({ max: 10, unit: "人" })(["wishChildNum"], "希望人数", "すでに誕生している子は含みません。"),
        state.blueprint.desiredChildren.map((c, i) =>
          SelectYearMonth(state.blueprint.own.birthday.getFullYear() + 16, sessionStartDate().getFullYear() + 20)
            (["desiredChildren", i, "birthday"],
              (state) => `${nthChildName(Math.max(0, (state.blueprint.childNum || 0)) + i)}の生年月`
            )),
      ]
    ]
  },
  {
    title: "同居人",
    components: (state) => [
      SelectYesNo(["hasHousemate"], "他に生活費を負担している同居人", "同居している両親など。生活費を負担していない場合は「なし」とします。"),
      state.blueprint.hasHousemate && state.blueprint.housemates.map((housemate, i) => [
        InputText(["housemates", i, "name"], null, "同居人の続柄（例：母）"),
        InputYear(["housemates", i, "fromYear"], null, "同居が始まった西暦年"),
        InputYear(["housemates", i, "toYear"], null, "同居が終わる想定の西暦年"),
        i < 10 && Select({ yes: "あり", no: "なし" },
          (value: string) => ("yes" === value ? {} : null),
          (value: object | null) => (value != null ? "yes" : "no"))
          (["housemates", i + 1], "他の同居人"),
      ])
    ]
  }
]);

export const BasicSettingsPage = SettingsPage("基本設定", [
  {
    title: "",
    components: (state: AppState) => [
      Select({ "0.0": "0%", "0.1": "0.1%", "0.5": "0.5%", "1.0": "1.0%", "2.0": "2.0%", "3.0": "3.0%", "4.0": "4.0%", "5.0": "5.0%", "6.0": "6.0%", "7.0": "7.0%", "8.0": "8.0%" })(["inflationRateText"], "インフレ率", "", { help: Help.InflationRate }),
    ]
  }
], undefined, <ResetButton />);

const carComponents = (key: "cars" | "carPurchasePlans", i: number) => (state: AppState) => [
  InputAge([key, i, "purchaseAge"], key === "cars" ? "購入した時の年齢" : "購入する予定の年齢"),
  InputMoney([key, i, "price"], "おおよその購入金額"),
  key === "carPurchasePlans" &&
  Select(NewOrUsedCarNames)([key, i, "newOrUsed"], "新車または中古車"),
  SelectYesNo([key, i, "useLoan"], "ローンの利用"),
  state.blueprint[key][i]?.useLoan && [
    InputExpense([key, i, "loanRepayment"], "毎月の支払額"),
    key === "cars" &&
    InputYearsFromNow([key, i, "loanFullPayment"], "残りの年数"),
    key === "carPurchasePlans" &&
    FixedNumber("config", v => `${v}年`)(`${key}_${i}_loanYears`,
      (state, plan) => {
        const car = state.blueprint[key][i];
        if (plan && car.purchaseAge != null) {
          const age = plan.atAge(car.purchaseAge);
          if (age) {
            const index = age.cars.find(c => c.car === car)?.loanHelper()?.fullPaymentIndex();
            if (index != null) {
              return index + 1;
            }
          }
        }
        return null;
      },
      "ローンの年数"),
  ],
  InputExpense([key, i, "tax"], "自動車税の金額", "", { help: Help.tax }),
  InputExpense([key, i, "inspectionFee"], "車検の費用", "", { help: Help.inspectionFee }),
  key === "cars" &&
  SelectYearFromNow(state, 3)([key, i, "nextInspectionYear"], "次回の車検の予定"),
  InputExpense([key, i, "insuranceRepayment"], "自動車保険の年額", "", { help: Help.insuranceRepayment }),
  Select(CarSellPlanNames)([key, i, "sellPlan"], "売却の予定"),
  state.blueprint[key][i]?.sellPlan !== "none" && [
    InputAge([key, i, "sellAge"], "売却する想定の年齢"),
    InputIncome([key, i, "sellPrice"], "売却・下取りの希望価格", "",
      {
        comment: (state) => {
          const car = state.blueprint[key][i];
          const sellPrice = car && defaultCarSellPrice(car);
          return sellPrice ? `未入力の場合、購入額および売却する年より自動算出した ${formatMoneyWithUnit(sellPrice)}を売却価格とみなして計算します` : "";
        }
      }
    ),
    key === "cars" &&
    Select(NewOrUsedCarNames)([key, i, "newOrUsed"], "新車または中古車"),
  ],
];

const housingLoanStartAge = <T extends unknown>(value: (age: Age) => T, offset: number, defaultValue: T) => (state: AppState, plan?: Simulator) => {
  if (plan && state.blueprint.housePurchasePlan &&
    state.blueprint.housePurchasePlan.age != null) {
    const age = plan.atAge(state.blueprint.housePurchasePlan.age + offset);
    if (age) {
      return value(age);
    }
  }
  return defaultValue;
}

export const ExpenseSettingsPages: PageDefinition[] = [
  {
    key: "house",
    icon: homeIcon,
    page: SettingsPage("住まい", [
      {
        title: "現状",
        components: (state: AppState) => [
          Select(HouseOwnershipNames)(["houseOwnership"], "今の住まい", ""),
          InputTextWithOptions({ pattern: "\\d*", maxLength: 8, placeholder: "1234567" })(["postCode"], "郵便番号", "", {
            valid: (value) => /^\d{3}-?\d{4}$/.test(value),
          }),
          state.blueprint.houseOwnership === "owned" && [
            Select(NewOrUsedHouseNames)(["currentHouse", "newOrUsed"], "購入時の状態"),
            Select(HouseTypeNames)(["currentHouse", "houseType"], "持ち家の種類"),
            InputMoney(["currentHouse", "price"], "住まいの購入金額"),
            InputExpense(["housingLoanRepayment"], "月の住宅ローン"),
            (state.blueprint.housingLoanRepayment || 0) > 0 &&
            InputYearsFromNow(["housingLoanFullPayment"], "残りの住宅ローン年数"),
            InputExpense(["currentHouse", "monthlyManagementCost"], "月の管理費"),
            InputYearsFromNow(["mortgageDeductionEndDate"], "住宅ローン控除の残り期間"),
            InputExpense(["mortgageDeductionAmount"], "年間の住宅ローン控除額", "", { help: Help.mortgageDeductionAmount }),
            InputExpense(["currentHouse", "propertyTax"], "年間の固定資産税", "", { help: Help.propertyTax }),
            InputExpense(["currentHouse", "reformCost"], "リフォーム費用", "15 年後・30 年後にリフォームすることを想定します", { 
              comment: (state) => `未入力の場合 ${DEFAULT_HOUSE_REFORM_COST/10000} 万円をリフォーム費用として計算します`}),
          ],
          state.blueprint.houseOwnership === "rental" && [
            InputExpense(["rent"], "月の家賃", "管理費込でお願いします。"),
            InputExpense(["rentRenewalFee"], "家賃の更新料", "", { help: Help.rentRenewalFee }),
            SelectYearFromNow(state, 2)(["nextRentRenewalYear"], "次回の更新時期"),
          ],
          state.blueprint.houseOwnership === "parent" && [
            state.blueprint.wishPartner === "married" ?
              Select(_.omit(IndependentHousePlanNames, ["marriage"]))(["independentHousePlan"], "実家から独立する時期", "", { key: "independentHousePlan#married" }) :
              Select(IndependentHousePlanNames)(["independentHousePlan"], "実家から独立する時期"),
          ],
          state.blueprint.houseOwnership !== "owned" &&
          Select(PlanningToBuyHouseNames)(["planningToBuyHouse"], "住まい購入の希望"),
          state.blueprint.planningToBuyHouse === "yes" && [
            InputAge(["housePurchasePlan", "age"], "イメージする購入時の年齢", "", {
              warning: (state) => {
                const age = state.blueprint.housePurchasePlan?.age;
                if (age && age < getAge(state.blueprint.own.birthday)) {
                  return "現在の年齢以上の値を入力してください";
                }
              }
            }),
            InputExpense(["housePurchasePlan", "price"], "希望する住まいの金額感"),
            InputExpense(["housePurchasePlan", "downPayment"], "頭金", "", {
              comment:
                housingLoanStartAge(age => `未入力の場合、貯金額より自動算出した ${formatMoneyWithUnit(age.defaultHousingLoanDownPayment())}を頭金とみなして計算します`, 0, "")
            }),
            FixedExpense("housePurchasePlan_housingLoan",
              housingLoanStartAge(age => age.housingLoan() / 12, 1, 0),  "毎月の住宅ローン"),
            SelectBoolean({ yes: "100 万円貯まるごとに返済する", no: "しない" })(["housingLoanAdvanceRepayment"], "住宅ローンの前倒し返済", ""),
            InputExpense(["mortgageDeductionAmount"], "年間の住宅ローン控除額", "", { help: Help.mortgageDeductionAmount, key: "plannedMortgageDeductionAmount" }),
            InputExpense(["housePurchasePlan", "movingCost"], "引越費用", "", { help: Help.movingCost }),
            InputExpense(["housePurchasePlan", "initialCost"], "初期費用", "", { 
              comment: (state) => `未入力の場合、住宅の購入費用の 5％の ${formatMoneyWithUnit((state.blueprint.housePurchasePlan?.price ?? 0) * 0.05)}を初期費用として計算します`}),
            InputExpense(["housePurchasePlan", "reformCost"], "リフォーム費用", "購入から 15 年後・30 年後にリフォームすることを想定します", { 
              comment: (state) => `未入力の場合 ${DEFAULT_HOUSE_REFORM_COST/10000} 万円をリフォーム費用として計算します`}),
            Select(HouseTypeNames)(["housePurchasePlan", "houseType"], "希望する住まいの種類"),
            Select(NewOrUsedHouseNames)(["housePurchasePlan", "newOrUsed"], "新築・中古の希望"),
          ],
          _.includes(["yes", "bought"], state.blueprint.planningToBuyHouse) && [
            SelectYesNo(["planningToSellHouse"], "持ち家を売却する希望"),
            state.blueprint.planningToSellHouse && [
              InputAge(["sellHouseAge"], "イメージする売却時の年齢"),
              InputAltIncome(["sellHousePrice"],
                "売却額", "",
                {
                  altValue: (state, plan) => {
                    if (plan && state.blueprint.sellHouseAge) {
                      const index = state.blueprint.sellHouseAge - plan.ages[0].age;
                      const age = plan.ages[index];
                      if (age) {
                        return age.sellHouseProfit();
                      }
                    }
                    return null;
                  }}),
            ],
          ],
        ]
      },
      {
        title: "住み替え",
        components: (state: AppState) => [
          SelectMovingPlan(["movingPlans", 0], "住み替えの希望", "", { parent }),
          state.blueprint.movingPlans.map((plan, i) => 
            plan.auto ? [] :
            [
              InputAge(["movingPlans", i, "age"], "想定する年齢", "", { child }),
              InputExpense(["movingPlans", i, "movingCost"], "引越費用", "", { child, help: Help.movingCost }),
              InputExpense(["movingPlans", i, "rent"], "次の住まいの月の家賃", "", { child }),
              InputExpense(["movingPlans", i, "rentRenewalFee"], "家賃の更新料", "", { child, help: Help.rentRenewalFee }),
              i < 3 && SelectObjectExistence()(["movingPlans", i + 1], "さらなる住み替えの希望", "", { parent }),
            ]),

        ]
      }
    ], { title: "住まいのお金", value: (sim: Simulator) => sim.totalHouseCost() }),
  },
  {
    key: "car",
    icon: carIcon,
    page: SettingsPage("クルマ", (state: AppState) => [
      {
        title: "所有",
        components: (state: AppState) => [
          SelectYesNo(["hasCar"], "クルマの所有"),
          state.blueprint.hasCar && [
            SelectNumber({ max: 5, unit: "台" })(["numCars"], "所有台数"),
            InputExpense(["parkingRent"], "月額の駐車場代"),
          ],
        ],
      },
      ...state.blueprint.cars.map((car, i) => (
        {
          title: `${i + 1} 台目`,
          components: carComponents("cars", i),
        }
      )),
      {
        title: "今後",
        components: (state: AppState) => [
          Select(PlanningToBuyCarNames)(["planningToBuyCar"], "クルマの購入予定"),
        ]
      },
      ...state.blueprint.carPurchasePlans.map((car, i) => (
        {
          title: `${i + 1 + state.blueprint.cars.length} 台目`,
          components: carComponents("carPurchasePlans", i),
        }
      )),
      ...((state.blueprint.hasCar || state.blueprint.planningToBuyCar === "yes") ? [
        {
          title: "終了",
          components: (state: AppState) => [
            InputAge(["maxDrivingAge"], "免許を返納する年齢"),
          ],
        }
      ] : [])
    ], { title: "クルマのお金", value: (sim: Simulator) => sim.totalCarCost() })
  },
  {
    key: "child",
    icon: educationIcon,
    skip: (state) => !state.blueprint.hasOrWishChild,
    page: SettingsPage("子ども", [
      {
        title: "出産後",
        components: (state: AppState) => [
          ...(state.blueprint.wishChild === "yes" ? [
            FixedIncome("childcareLumpSumAllowance", () => 420000, "出産育児一時金"),
            InputExpense(["childcarePolicy", "birthCost"], "出産にかかる費用", "", { help: Help.birthCost }),
            InputIncome(["childcarePolicy", "maternityGift"], "親などからの出産祝金", "", { help: Help.maternityGift }),
          ] : []),
          FixedNumber("income")("childcareAllowance", () => "月 1 万〜1.5 万円", "児童手当"),
        ]
      },
      {
        title: "学校",
        components: (state: AppState) => [
          Select(PreschoolTypeNames)(["childcarePolicy", "schoolTypes", "preschool"], "就学前", "", { help: Help.child.preschool }),
          Select(SchoolTypeNames)(["childcarePolicy", "schoolTypes", "es"], "小学校", "", { help: Help.child.es }),
          Select(SchoolTypeNames)(["childcarePolicy", "schoolTypes", "jhs"], "中学校", "", { help: Help.child.jhs }),
          Select(HighschoolTypeNames)(["childcarePolicy", "schoolTypes", "hs"], "高校", "", { help: Help.child.hs }),
          state.blueprint.childcarePolicy.schoolTypes.hs !== "employment" && [
            Select(UniversityTypeNames)(["childcarePolicy", "schoolTypes", "university"], "大学", "", { help: Help.child.university }),
            state.blueprint.childcarePolicy.schoolTypes.university !== "employment" && [
              Select(GraduateSchoolTypeNames)(["childcarePolicy", "schoolTypes", "graduateSchool"], "大学院（修士）", "", { help: Help.child.graduate }),
            ]
          ]
        ],
      },
      {
        title: "習いごと",
        components: (state: AppState) => [
          Select(LessonTypeNames)(["childcarePolicy", "lessonType"], "習いごとの方針"),
          Select(ExaminationTypeNames)(["childcarePolicy", "examinationTypes", "es"], "小学校の入学"),
          Select(ExaminationTypeNames)(["childcarePolicy", "examinationTypes", "jhs"], "中学校の入学"),
          Select(ExaminationTypeNames)(["childcarePolicy", "examinationTypes", "hs"], "高校の入学"),
          Select(ExaminationTypeNames)(["childcarePolicy", "examinationTypes", "university"], "大学の入学"),
        ],
      },
      {
        title: "費用の工面",
        components: (state: AppState) => [
          SelectBoolean({ yes: "利用する", no: "利用しない" })(["childcarePolicy", "useScholarship"], "奨学金", "", { parent, help: Help.useScholarship }),
          state.blueprint.childcarePolicy.useScholarship &&
          InputMoney(["childcarePolicy", "scholarshipMonthlyAllowance"], null, "毎月受け取る奨学金", { child }),
          SelectObjectExistence({ yes: "利用する", no: "利用しない" })(["childcarePolicy", "studentInsurance"], "学資保険", "", { parent, help: Help.studentInsurance }),
          state.blueprint.childcarePolicy.studentInsurance && [
            InputMoney(["childcarePolicy", "studentInsurance", "monthlyPayment"], null, "子一人あたりの積立月額", { child }),
            InputAge(["childcarePolicy", "studentInsurance", "startAge"], null, "積立を始める子の年齢", { child }),
            InputAge(["childcarePolicy", "studentInsurance", "endAge"], null, "満期になる子の年齢", { child }),
          ]
        ]
      },
      {
        title: "独立",
        components: (state: AppState) => [
          InputAge(["childcarePolicy", "liveAloneAge"], "一人暮らしを始める年齢"),
          SelectYesNo(["childcarePolicy", "sendRemittance"], "仕送り", "", { help: Help.sendRemittance }),
          state.blueprint.childcarePolicy.sendRemittance && [
            InputYears(["childcarePolicy", "remittanceYears"], "仕送りする年数"),
            InputExpense(["childcarePolicy", "monthlyRemittance"], "仕送りの月額"),
          ],
        ],
      },
    ], { title: "子どものお金", value: (sim: Simulator) => sim.totalChildExpense() }),
  },
  {
    key: "living",
    icon: livingIcon,
    page: SettingsPage("生活費", [
      {
        title: "日常費",
        components: (state: AppState) => [
          InputExpense(["monthlyLivingCost"], "月の日常費", "住まいやクルマ、保険、教育、臨時支出以外で日常的にかかるお金です。", {
            help: Help.monthlyLivingCost,
            comment: (state, plan) =>
              state.blueprint.defaultLivingCost.isAverage ? "" :
              `Zaim の家計記録によると、直近の記録から推定した生活費は ${formatSmallMoneyWithUnit(state.blueprint.defaultLivingCost.value)}です`
          }),
          Select({ "0": "0%", "0.5": "0.5%", "1.0": "1.0%", "1.5": "1.5%" })(["livingCostIncreaseRateText"], "毎年の増加率"),
        ]
      },
      {
        title: "保険のお金",
        components: (state: AppState) => [
          Select(HasInsuranceNames)(["hasInsurance"], "保険の加入", "", { parent }),
          _.includes(["yes", "planned"], state.blueprint.hasInsurance) &&
          state.blueprint.insurances.map((insurance, i) => [
            InputText(["insurances", i, "name"], "保険の名称", "例：夫の生命保険・生命保険エース", { child }),
            Select(insuredList(state))(["insurances", i, "insured"], "被保険者", "", { child }),
            Select(insuranceCompanyList)(["insurances", i, "company"], "保険会社", "", { child }),
            state.blueprint.hasInsurance == "yes" ?
              InputAge(["insurances", i, "startAge"], "加入した年齢", "", { key: `${i}_startAge#started`, child }) :
              InputAge(["insurances", i, "startAge"], "加入する年齢", "", { child }),
            InputAge(["insurances", i, "endAge"], "払込が終わる年齢", "", { child }),
            InputExpense(["insurances", i, "monthlyPayment"], "月額の保険料", "", { child }),
            InputAge(["insurances", i, "maturityRepaymentAge"], "満期金を受け取る年齢", "満期金がない場合は空欄のままとしてください。", { child }),
            InputIncome(["insurances", i, "maturityRepayment"], "満期金の総額", "満期金がない場合は空欄のままとしてください。", { child }),
            InputAge(["insurances", i, "pensionRepaymentStartAge"], "個人年金を受け取り始める年齢", "年金の受取がない場合は空欄のままとしてください。", { child }),
            InputAge(["insurances", i, "pensionRepaymentEndAge"], "個人年金の受取が終わる年齢", "年金の受取がない場合は空欄のままとしてください。", { child }),
            InputIncome(["insurances", i, "monthlyPensionRepayment"], "個人年金の受取月額", "年金の受取がない場合は空欄のままとしてください。", { child }),
            i < 49 && SelectObjectExistence({ yes: "はい", no: "いいえ" })(["insurances", i + 1], "さらなる保険の加入", "", { parent }),
          ]),
        ],
      },
      {
        title: "借りているお金",
        components: (state: AppState) => [
          OwnOrPartner(state, (person, name) =>
            state.blueprint.isStudentMode ?
            [
              SelectExistence([person, "hasStudentLoanRepayment"], `${name}が借りている奨学金`, "", { key: `student_${person}_hasStudentLoanRepayment`}),
              state.blueprint[person]?.hasStudentLoanRepayment && [
                InputIncome([person, "studentLoanMonthlyAmount"], "毎月の奨学金の合計金額", "年単位で受け取っている場合は 12 分割した値を入れてください"),
                InputMonths([person, "studentLoanReceivingMonths"], "奨学金を受け取る月数"),
              ],
            ] :
            [
              SelectExistence([person, "hasStudentLoanRepayment"], `${name}が返済中の奨学金`),
              state.blueprint[person]?.hasStudentLoanRepayment && [
                InputExpense([person, "studentLoanRepayment"], `${name}の奨学金の月の返済額`),
                InputYearsFromNow([person, "studentLoanFullPayment"], `${name}の奨学金の返済の残り年数`),
              ],
            ]),
          SelectExistence(["hasDebt"], "その他の借入金", "奨学金や住宅ローン以外で借りているお金があれば回答ください。"),
          state.blueprint.hasDebt && [
            InputMoney(["debt", 0, "balance"], "借入金の残り金額"),
            InputExpense(["debt", 0, "monthlyPayment"], "借入金の月の返済額"),
          ],
        ]
      },
    ], { title: "生活費", value: (sim: Simulator) => sim.totalLivingCost() }),
  },
  {
    key: "otherExpense",
    icon: expenseOtherIcon,
    page: SettingsPage("その他", [
      {
        title: "結婚",
        components: (state: AppState) => state.blueprint.wishPartner === "yes" ? [
          InputAge(["marriageAge"], "結婚の想定年齢"),
          InputExpense(["marriageRingCost"], "婚約・結婚指輪", "", { help: Help.marriageRingCost }),
          InputExpense(["weddingCost"], "結婚式", "", { help: Help.weddingCost }),
          InputExpense(["honeymoonCost"], "新婚旅行", "あなたと配偶者を合わせた総額を教えてください。", { help: Help.honeymoonCost }),
        ] : [],
      },
      {
        title: "老後",
        components: (state: AppState) => [
          InputExpense(["funeralCost"], "お墓・お葬式", "", { help: Help.funeralCost }),
        ],
      },
      {
        title: "その他",
        components: (state: AppState) => [
          InputExpense(["annualBasicExpense"], "年間にかかる出費", "旅行や家電、趣味など日常以外でかかる年間の支出を入力ください。"),
          ...specialIncomeOrExpense("expense", "支出", state)
        ],
      },
    ], { title: "その他", value: (sim: Simulator) => sim.totalOtherExpense() }),
  },
];

export const IncomeSettingsPages: PageDefinition[] = [
  {
    key: "work",
    icon: workIcon,
    page: SettingsPage("仕事", [
      {
        title: "わたし",
        components: WorkFields("own"),
      },
      {
        title: "配偶者",
        components: (state: AppState) =>
          state.blueprint.hasPartner ? WorkFields("partner")(state) : [],
      },
    ], { title: "仕事", value: (sim: Simulator) => sim.totalWorkIncome() }),
  },
  {
    key: "asset",
    icon: chartIcon,
    page: SettingsPage("資産", [
      {
        title: "わたし",
        components: InvestmentFields("own"),
      },
      {
        title: "配偶者",
        components: (state) => state.blueprint.hasPartner ?
          InvestmentFields("partner")(state) : [],
      },
    ], { title: "資産", value: (sim: Simulator) => sim.totalAssetAndInvestmentProfit() }),
  },
  {
    key: "retirement",
    icon: retirementIcon,
    page: SettingsPage("老後", [
      {
        title: "わたし",
        components: RetirementFields("own"),
      },
      {
        title: "配偶者",
        components: (state: AppState) =>
          state.blueprint.hasPartner ? RetirementFields("partner")(state) : [],
      },
    ], { title: "老後のお金", value: (sim: Simulator) => sim.totalPension() }),
  },
  {
    key: "otherIncome",
    icon: incomeOtherIcon,
    page: SettingsPage("その他", [
      {
        title: "相続",
        components: (state: AppState) => [
          OwnOrPartner(state, (person, name) =>
            [
              SelectObjectExistence()([person, "inheritance", 0], `${name}の予定`, "", { parent, help: Help.inheritance }),
              state.blueprint[person]!.inheritance.map((inheritance, i) => [
                InputAge([person, "inheritance", i, "age"], "相続を想定する年齢", "", { child }),
                InputMoney([person, "inheritance", i, "amount"], "相続の想定金額", "", { child }),
              ]),
            ]
          )
        ]
      },
      {
        title: "その他",
        components: (state: AppState) => specialIncomeOrExpense("income", "収入", state),
      },
    ], { title: "その他の収入", value: (sim: Simulator) => sim.totalOtherIncome() })
  }
];


export const SettingsPages: { [key: string]: React.FC } = {
  config: BasicSettingsPage,
  family: FamilySettingsPage,
  ..._.chain(ExpenseSettingsPages).keyBy("key").mapValues("page").value(),
  ..._.chain(IncomeSettingsPages).keyBy("key").mapValues("page").value(),
}
