import axios, { AxiosResponse } from 'axios';
import debug from '../debug';
import { functionURL, AUTH_URL, AUTH_CLIENT_ID } from '../firebase';

const generateRandomString = (length: number) => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

// 以下の実装を利用
// https://gist.github.com/ahmetgeymen/a9dcd656a1527f6c73d9c712ea2d9d7e

// Calculate the SHA256 hash of the input text. 
// Returns a promise that resolves to an ArrayBuffer
async function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str: ArrayBuffer): string {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(str))))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v: string) {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
}

export const redirectForAuthorization = async () => {
  debug('redirecting');
  const verifier = generateRandomString(128);
  sessionStorage.setItem('verifier', verifier);
  const challenge = await pkceChallengeFromVerifier(verifier);
  sessionStorage.setItem("redirect_path", window.location.pathname);
  window.location.href = `${AUTH_URL}/oauth/authorize` +
    `?client_id=${AUTH_CLIENT_ID}` +
    `&redirect_uri=${window.location.origin}` +
    `&response_type=code` +
    `&code_challenge=${challenge}` +
    `&code_challenge_method=S256`;
}
