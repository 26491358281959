import * as React from "react";
import styles from "./intro.module.scss";
import conceptImg from "./concept.svg";
import logoImg from "./logo.svg";
import step01Img from "./step01.svg";
import step02Img from "./step02.svg";
import step03Img from "./step03.svg";
import top01Img from "./top01.svg";
import top02Img from "./top02.svg";
import iconAlert from "./icon-alert.svg";
import iconRefresh from "./icon-refresh.svg";
import iconLifeplan from "./icon-lifeplan.svg";

import { changePage, Context, isPremiumUser } from "../../Store";
import { startPath, startStudentPath } from "../../path";
import firebase, { AUTH_URL } from "../../firebase";
import { redirectForAuthorization } from "../../auth/auth";

export default (props: { authenticated: boolean }) => {
  const { dispatch, state } = React.useContext(Context);
  const start = () => {
    if (props.authenticated) {
      firebase.analytics().logEvent("click_start");
      window.history.pushState(true, "", startPath);
      dispatch(changePage(startPath, false, false));
    }
  }
  const startStudent = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props.authenticated) {
      firebase.analytics().logEvent("click_start_student");
      window.history.pushState(true, "", startStudentPath);
      dispatch(changePage(startStudentPath, false, false));
    }
  }
  return (<div>
    <header className={styles['l-header']}>
      <img className={styles['logo']} alt="Zaim" src={logoImg} />
      <div className={styles['l-toService']}>
        {!props.authenticated &&
          <div className={styles['l-accountButton']}>
            <a className={`${styles['button']} ${styles['secondary']}`} onClick={() => redirectForAuthorization()}>ログイン</a>
            <a className={`${styles['button']} ${styles['primary']}`} href={`${AUTH_URL}/signup?from=web`}>新規登録</a>
          </div>
        }
      </div>
    </header>
    <div className={styles['l-top']}>
      <div className={styles['l-topTitle']}>
        <h1 className={styles['title']}><span className={styles['sub']}>1,000 万件の家計簿を扱う Zaim のライフプラン</span>
          ずっと使える、見直せる、<br />一生“黒字化”ツール
        </h1>
        <div className={styles['spImage']}>
          <img className={styles['img']} alt="毎月 1 万円、投資に回すと老後はプラス" src={top02Img} />
        </div>
        <div className={styles['pcImage']}>
          <img className={styles['img']} alt="毎月 1 万円、投資に回すと老後はプラス" src={top01Img} />
        </div>
        {props.authenticated && isPremiumUser(state) &&
          <div className={styles['attention']}>これは Zaim プレミアム限定の機能です。すでにプレミアムに加入いただいていますので、すぐご利用いただけます。</div>
        }
        {props.authenticated && !isPremiumUser(state) &&
          <div className={styles['attention']}>これは Zaim プレミアム限定の機能です。初めてプレミアムに登録する方には無料期間があり、いつでもキャンセル可能です。</div>
        }
        {!props.authenticated &&
          <div className={styles['attention']}>ご利用には Zaim への登録とプレミアムへの加入が必要です。</div>
        }
      </div>

      <div className={styles['l-topButton']}>
        {props.authenticated && isPremiumUser(state) &&
          <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} onClick={start}>今すぐ使ってみる</a>
        }
        {props.authenticated && !isPremiumUser(state) &&
          <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} href="https://kj4nh.app.goo.gl/FrVh">プレミアム機能について知る</a>
        }
        {!props.authenticated &&
          <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} onClick={() => redirectForAuthorization()}>Zaim にログインして使ってみる</a>
        }
        {props.authenticated && isPremiumUser(state) &&
          <div className={styles['l-topStudentLink']}>
            <a href="#" onClick={startStudent}>24 歳以下の方限定の学生版を始める</a>
          </div>
        }
      </div>
    </div>
    <div className={styles['l-content']}>
      <div className={`${styles['div']} ${styles['l-concept']}`}>
        <div className={styles['l-pointText']}>
          <h2 className={styles['title']}>家計の将来を見える化し、<br />黒字化を自動でサポート</h2>
          <p className={styles['text']}>一生黒字プランでは、あなたの人生に必要な金額が分かります。<br />さまざまな知識を得ながら、お金に困らないライフプランを自身で組み立てられます。</p>
        </div>
        <img className={styles['img']} alt="黒字化を自動でサポート" src={conceptImg} />
      </div>

      <div className={`${styles['div']} ${styles['l-step']}`}>
        <h2 className={`${styles['title']} ${styles['centerOnSP']}`}>一生黒字までの 3 ステップ</h2>
        <div className={styles['step']}>
          <div className={styles['l-stepItem']}>
            <div className={styles['l-stepItemText']}>
              <h3 className={`${styles['title']} ${styles['sizeS']}`}><a className={styles['sub']}>STEP 01</a>一生黒字でいられるか<br />シミュレーション</h3>
              <p className={styles['text']}>今の暮らしや将来像についての質問に答えるだけ。<br />将来の資産グラフや収支年表を自動で作り、あなただけのライフプランを提示します。</p>
              <br className={styles['clear']} />
              <img className={styles['img']} alt="一生黒字でいられるかシミュレーション" src={step01Img} />
            </div>
          </div>
          <div className={styles['l-stepItem']}>
            <div className={styles['l-stepItemText']}>
              <h3 className={`${styles['title']} ${styles['sizeS']}`}><span className={styles['sub']}>STEP 02</span>あなたのための<br />お金の豆知識で家計を黒字に</h3>
              <p className={styles['text']}>「この用語はどういう意味？」「他の人はどうしてる？」などの参考情報も豊富に提示。<br />ライフプランを改善できるだけでなく、最適なお金の選択をするための知識が身につきます。</p>
              <br className={styles['clear']} />
              <img className={styles['img']} alt="あなたのためのお金の豆知識で家計を黒字に" src={step02Img} />
            </div>
          </div>
          <div className={styles['l-stepItem']}>
            <div className={styles['l-stepItemText']}>
              <h3 className={`${styles['title']} ${styles['sizeS']}`}><span className={styles['sub']}>STEP 03</span>定期的に家計簿を反映し<br />見直し方をアドバイス</h3>
              <p className={styles['text']}>暮らしやお金の制度は日々、変化します。それに合わせ、ライフプランの見直しポイントを毎月お知らせ。<br />家計簿の Zaim で記録を付けていると、その内容も反映します。</p>
              <br className={styles['clear']} />
              <img className={styles['img']} alt="定期的に家計簿を反映し見直し方をアドバイス" src={step03Img} />
            </div>
          </div>

        </div>
      </div>


      <div className={`${styles['div']} ${styles['l-letsStart']}`}>
        <h2 className={styles['title']}>一生黒字プランを始めよう</h2>
        <div className={styles['l-pointText']}>
          <p className={styles['text']}>一生黒字プランのご利用には、「Zaim プレミアム」会員への加入が必要です。加入いただくと、すべての機能が使い放題。</p>
        </div>
        <div className={styles['l-premium']}>
          <div className={styles['sub']}>プレミアム会員でできること</div>
          <div className={styles['l-premiumItem']}>
            <img src={iconLifeplan} className={styles['premiumIcon']} />
            <h3 className={`${styles['title']} ${styles['sizeS']}`}>一生黒字プラン</h3>
            <div className={styles['l-stepItemText']}>
              <p className={styles['text']}>家計の将来を見える化し黒字化を自動でサポート</p>
            </div>
          </div>
          <div className={styles['plus']}>+</div>
          <div className={styles['l-premiumItem']}>
            <img src={iconRefresh} className={styles['premiumIcon']} />
            <h3 className={`${styles['title']} ${styles['sizeS']}`}>銀行・カード明細を即取得</h3>
            <div className={styles['l-stepItemText']}>
              <p className={styles['text']}>銀行やカード、電子マネーの明細を家計簿にすぐ反映できるようになります。</p>
            </div>
            <img src={iconAlert} className={styles['premiumIcon']} />
            <h3 className={`${styles['title']} ${styles['sizeS']}`}>使いすぎ防止アラート</h3>
            <div className={styles['l-stepItemText']}>
              <p className={styles['text']}>予定より支出が多くなりそうなら、お知らせします。一生黒字と併用して効果倍増！</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['l-bottomButton']}>
        {(!props.authenticated || !isPremiumUser(state)) &&
          <p className={styles['text']}>プレミアムの加入が初めての方は、無料で試せます！</p>
        }
        <div className={styles['bottomButton']}>
          {props.authenticated && isPremiumUser(state) &&
            <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} onClick={start}>今すぐ使ってみる</a>
          }
          {props.authenticated && isPremiumUser(state) &&
            <div className={styles['l-topStudentLink']}>
              <a href="#" onClick={startStudent}>24 歳以下の方限定の学生版を始める</a>
            </div>
          }
          {props.authenticated && !isPremiumUser(state) &&
            <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} href="https://kj4nh.app.goo.gl/FrVh">プレミアム機能について知る</a>
          }
          {!props.authenticated &&
            <a className={`${styles['l-topStartLink']} ${styles['button']} ${styles['primary']}`} onClick={() => redirectForAuthorization()}>Zaim にログインして使ってみる</a>
          }
        </div>
      </div>
    </div>

    <footer className={`${styles['div']} ${styles['l-footer']} ${styles['grayback']} ${styles['l-footerGrayback']}`}>
      <div className={styles['l-disclaimer']} >
        <div className={styles['title']}>免責事項</div>
        <p className={styles['text']}>
          一生黒字プランは、ご利用者様の入力を元に収支や資産の推移をシミュレーションし参考情報を提供するサービスであり、ご利用者様の家計の継続的な黒字収支等を保証するものではありません。また、シミュレーション結果については万全の注意を払っておりますが、正確性、完全性、信頼性、有用性および適時性を保証するものではありません。ご利用者様は、当サービスの利用にあたっては、ご自身の責任にて判断するものとします。ご利用者様が当サービスを利用されたことにより生じたいかなる結果についても、当社は責任を負いかねますことをあらかじめご了承ください。また、当サービスは事前の通知なしに、内容を変更することがあります。
        </p>
      </div>
      <div className={styles['l-foorerLink']}>
        <a className={styles['l-footerLinkItem']} target="_blank" rel="noopener noreferrer" href="https://content.zaim.net/legal/tos">利用規約</a>
        <a className={styles['l-footerLinkItem']} target="_blank" rel="noopener noreferrer" href="https://content.zaim.net/legal/privacy">プライバシーポリシー</a>
        <a className={styles['l-footerLinkItem']} target="_blank" rel="noopener noreferrer" href="https://content.zaim.net/legal">その他の規約など</a>
        <a className={styles['l-footerLinkItem']} target="_blank" rel="noopener noreferrer" href="https://content.zaim.net/contacts/add">お問合せ</a>
        <a className={styles['l-footerLinkItem']} target="_blank" rel="noopener noreferrer" href="https://zaim.co.jp">運営会社</a>
      </div>
    </footer>
  </div>);
}
