import React from 'react';
import { AppState, Actions, User, Blueprint, InitialChildcarePolicy, Properties, sessionStartDate } from '.'
import { fetchPlan, savePlan } from './database';
import { getUser, getUserDetail, UserDetail } from './functions';
import _ from 'lodash';
import debug from '../debug';

export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE";

export type fetchStateSuccess = {
  types: typeof FETCH_STATE_SUCCESS;
  payload: {
    plan: Blueprint,
    user: User
  }
};

export type fetchUserDetailSuccess = {
  types: typeof FETCH_USER_DETAIL_SUCCESS;
  payload: {
    userDetail: UserDetail
  }
};

export const fetchState = (dispatch: React.Dispatch<any>, uid: string) => {
  Promise.all([fetchPlan(uid), getUser(uid)])
    .then(([plan, user]) => {
      debug("dispatch success");
      dispatch({
        types: FETCH_STATE_SUCCESS,
        payload: { plan, user }
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

export const fetchUserDetail = (dispatch: React.Dispatch<any>) => {
  getUserDetail()
    .then(userDetail => {
      debug("userDetail", userDetail);
      dispatch({
        types: FETCH_USER_DETAIL_SUCCESS,
        payload: { userDetail }
      })
    })
    .catch((err) => {
      console.log(err);
    });
}

export const saveState = (state: AppState) => {
  savePlan(String(state.user.id), state.blueprint);
}

const mapGender = (id: number) => {
  switch (id) {
    case 1: return 'male';
    case 2: return 'female';
    case 3: return 'other';
    default: return undefined;
  }
}

export const reducer = (state: AppState, action: Actions): AppState => {
  switch (action.types) {
    case FETCH_STATE_SUCCESS: {
      const { plan, user } = action.payload;
      const s = {
        ...state,
        user: {
          ...state.user,
          ...user
        }
      }
      // 新規ユーザの場合は savedBlueprint === false になる
      s.savedBlueprint = plan != null;
      s.blueprint = new Blueprint();
      if (plan) {
        if (plan.partner) {
          s.blueprint.partner = new Properties(); // 配偶者の初期値を設定しておく
        }
        _.merge(s.blueprint, plan);
      }
      if (!s.savedBlueprint) {
        if (user.gender_id) {
          s.blueprint.own.gender = mapGender(user.gender_id || 0);
        }
        if (user.birth_date && user.birth_date != '0000-00-00') {
          s.blueprint.own.birthday = new Date(user.birth_date);
        }
      }
      return s;
    }
    case FETCH_USER_DETAIL_SUCCESS: {
      const { userDetail } = action.payload;
      const s = {
        ...state,
        blueprint: new Blueprint({
          ...state.blueprint,
          estimate: {
            ...userDetail,
            updatedDate: sessionStartDate()
          },
        })
      }
      return s;
    }
    default: {
      return state;
    }
  }
};


