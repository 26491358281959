import React, { useEffect } from "react";
import { Context, changePage, setAttributes } from "../../../Store";
import Container from "../../../molecules/Container";
import Button from "../../../atoms/Button";
import { finishPath } from "../../../path";
import robotIcon from "../robot.svg";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";
import _ from "lodash";
import firebase from "../../../firebase";

export default () => {
  const { dispatch, state } = React.useContext(Context);
  const [showButton, setShowButton] = React.useState(false);
  useEffect(() => {
    setTimeout(() => setShowButton(true), 1000);
  }, []);

  const stars = Array(10).fill(null).map(() => {
    const leftOrRight = Math.random() < 0.5 ? "left" : "right";
    return {
      text: _.sample(['◯', '＋', ' △']),
      style: {
        position: "absolute",
        opacity: "0",
        animationDelay: _.random(1, 2, true) + "s",
        [leftOrRight]: _.random(10, 30, true) + "%",
        top: _.random(10, 200, true) + "px",
        color: _.sample([
          "rgba(224, 69, 69, 0.87)",
          "rgba(40, 145, 189, 0.87)",
          "#F3AF17",
          "#E0E0E0"
        ])
      } as const
    }
  });
  return (
    <>
      {stars.map((star) => <div className="QuestionCompletedStar" style={star.style}>{star.text}</div>)}
      <Container>
        <img src={robotIcon} width="72px" height="72px" />
      </Container>
      <Container>
        <p>
          回答、お疲れさまでした！<br />あなたの黒字化の見込みを見てみましょう。
        </p>
      </Container>
      <Container>
        <CSSTransition in={showButton} timeout={5000} classNames="QuestionCompletedButton">
          <div className="QuestionCompletedButton">
            <Button
              onClick={() => {
                firebase.analytics().logEvent("question_completed");//, {app_name: "lifetime", screen_name: "finish"});
                window.history.pushState(true, "", finishPath);
                dispatch(changePage(finishPath, false, false));
              }}>
              結果を見る
          </Button>
          </div>
        </CSSTransition>
      </Container>
    </>);
};
