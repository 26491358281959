import * as React from "react";
import styles from "./ProgressBar.module.scss";

const ProgressBar = (props: { percent: number }) => {
  const stage = 
    props.percent < 40 ? 1 :
    props.percent < 100 ? 2 :
    3;
  return (
    <div className={styles.parent}>
      <div className={styles.progress}>
        <div
          className={styles[`progressBar_p${stage}`]}
          style={{ width: `${props.percent}%` }}
        >
          <div className={styles.progressText}>
           {Math.floor(props.percent)}％
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
