import * as React from "react";
import styles from "./Badges.module.scss";
import { acquireBadge, AppState, BadgeName, Context, saveState } from "../../Store";
import { Simulator } from "../../simulator";
import expenseOtherOnIcon from "./expense_other_on.png";
import expenseOtherOffIcon from "./expense_other_off.png";
import incomeOtherOnIcon from "./income_other_on.png";
import incomeOtherOffIcon from "./income_other_off.png";
import houseOnIcon from "./house_on.png";
import houseOffIcon from "./house_off.png";
import workOnIcon from "./work_on.png";
import workOffIcon from "./work_off.png";
import firstIcon from "./first.png";
import retirementOnIcon from "./retirement_on.png";
import retirementOffIcon from "./retirement_off.png";
import assetOnIcon from "./asset_on.png";
import assetOffIcon from "./asset_off.png";
import surplusOnIcon from "./surplus_on.png";
import surplusOffIcon from "./surplus_off.png";
import carOnIcon from "./car_on.png";
import carOffIcon from "./car_off.png";
import childOnIcon from "./child_on.png";
import childOffIcon from "./child_off.png";
import livingOnIcon from "./living_on.png";
import livingOffIcon from "./living_off.png";
import monthlyOnIcon from "./monthly_on.png";
import monthlyOffIcon from "./monthly_off.png";
import monthly10OnIcon from "./monthly10_on.png";
import monthly10OffIcon from "./monthly10_off.png";
import monthly100OnIcon from "./monthly100_on.png";
import monthly100OffIcon from "./monthly100_off.png";
import surplusDialogImage from "./surplus_dialog.svg";
import _ from "lodash";
import { PRODUCTION } from "../../firebase";
import InfoDialog from "../InfoDialog";

const badges = (state: AppState): Partial<Record<BadgeName, {title: string, onIcon: string, offIcon: string}>> => ({
  first: {
    title: "一生黒字を体験",
    onIcon: firstIcon,
    offIcon: firstIcon,
  },
  surplus: {
    title: "黒字を達成",
    onIcon: surplusOnIcon,
    offIcon: surplusOffIcon,
  },
  work: {
    title: "収入の\n「仕事」を見直し",
    onIcon: workOnIcon,
    offIcon: workOffIcon,
  },
  asset: {
    title: "収入の\n「資産」を見直し",
    onIcon: assetOnIcon,
    offIcon: assetOffIcon,
  },
  retirement: {
    title: "収入の\n「老後」を見直し",
    onIcon: retirementOnIcon,
    offIcon: retirementOffIcon,
  },
  otherIncome: {
    title: "収入の\n「その他」を見直し",
    onIcon: incomeOtherOnIcon,
    offIcon: incomeOtherOffIcon,
  },
  house: {
    title: "支出の\n「住まい」を見直し",
    onIcon: houseOnIcon,
    offIcon: houseOffIcon,
  },
  car: {
    title: "支出の\n「クルマ」を見直し",
    onIcon: carOnIcon,
    offIcon: carOffIcon,
  },
  ...(state.blueprint.hasOrWishChild ? {
    child: {
      title: "支出の\n「子ども」を見直し",
      onIcon: childOnIcon,
      offIcon: childOffIcon,
    }
  } : {}),
  living: {
    title: "支出の\n「生活費」を見直し",
    onIcon: livingOnIcon,
    offIcon: livingOffIcon,
  },
  otherExpense: {
    title: "支出の\n「その他」を見直し",
    onIcon: expenseOtherOnIcon,
    offIcon: expenseOtherOffIcon,
  },
  monthly: {
    title: "毎月の振り返り",
    onIcon: monthlyOnIcon,
    offIcon: monthlyOffIcon,
  },
  monthly10: {
    title: "10 回の振り返り",
    onIcon: monthly10OnIcon,
    offIcon: monthly10OffIcon,
  },
  monthly100: {
    title: "100 回の振り返り",
    onIcon: monthly100OnIcon,
    offIcon: monthly100OffIcon,
  },
});

const DEBUG_SURPLUS_BADGE = !PRODUCTION &&
  (new URL(window.document.location.toString())).searchParams.get("debug_surplus_badge") == "1";

export default (props: { plan: Simulator }) => {
  const { dispatch, state } = React.useContext(Context);
  const [openSurplus, setOpenSurplus] = React.useState(false);
  React.useEffect(() => {
    if (DEBUG_SURPLUS_BADGE ||
      (!state.blueprint.badges.surplus &&
      _.last(props.plan.ages)!.asset() >= 0)) {
      dispatch(acquireBadge("surplus"));
      setOpenSurplus(true);
    }
  }, []);
  React.useEffect(() => {
    // 黒字になったら保存する
    if (openSurplus && state.blueprint.badges.surplus) {
      saveState(state);
    }
  }, [openSurplus, state.blueprint.badges]);
  return (
    <>
    <div className={styles.container}>
      <h2>獲得バッジ</h2>
      <p>一生黒字プランや家計簿を継続していくと、バッジが貯まります。</p>
      <div className={styles.badges}>
        {Object.entries(badges(state)).map(([key, badge]) => {
          const badgeState = state.blueprint.badges[key as BadgeName];
          return ( badge &&
            <div key={key} className={styles.badge}>
              <div className={styles.icon}>
                <img src={badgeState && badgeState.count > 0 ? badge.onIcon : badge.offIcon} width={64} height={64} />
                { badgeState && badgeState.count > 1 &&
                  <div className={styles.badgeCount}>
                    {badgeState.count >= 100 ? "99+" : badgeState.count}
                  </div>
                }
              </div>
              <div className={styles.title}>{badge.title}</div>
            </div>
          );
        })}
      </div>
    </div>
    <InfoDialog open={openSurplus} setOpen={setOpenSurplus} title="一生黒字を達成！"
      image={surplusDialogImage}>
      <div className={styles.surplus}>
      おめでとうございます！<br/>
      生涯の収支がプラスになりました。<br/>
      来月また、お目にかかりましょう。
      </div>
    </InfoDialog>
    </>
);
};
