import * as React from "react";
import styles from "./Comment.module.scss";
import robotIcon from "./robot-orange.svg";

export default (props: {
  containerClassName?: string,
  children: string
}) => {
  return (
    <div className={styles[props.containerClassName ? props.containerClassName : "container"]}>
      <div className={styles.icon}>
        <img src={robotIcon} />
      </div>
      <div className={styles.comment}>
        {props.children}
      </div>
    </div>
  );
};
