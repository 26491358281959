import React from "react";
import firebase, { functions, PRODUCTION } from "../firebase";
import App from "../App";
import debug from "../debug";

// firebase のユーザの token を読み込む
export const LoadToken = (props: { token: string }) => {
  const [error, setError] = React.useState("");
  const [uid, setUid] = React.useState("");
  React.useEffect(() => {
    debug("app", [props.token, uid, error]);
    if (error) {
      // エラ〜メッセージを表示したままにする
      return;
    }
    if (!uid) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
      .then(() => {
        return firebase.auth().signInWithCustomToken(props.token)
        //let user?: firebase.User = null;
      })
      .then((cred) => {
        debug('sign in success');
        debug(cred);
        debug(cred.user?.uid);
        //setUid(cred.user?.uid || "");
        setUid(cred.user!.uid);
      })
      .catch((err) => {
        console.log('failed to sign in');
        console.log(err);
        setError("ログインしています…");
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  }, [props.token, uid, error]);
  return (
    error ?
      (<p className="update">{error}</p>)
    : !uid ? 
      (<p className="update">情報を読み込んでいます…</p>)
    :
      <App uid={uid} />
  );
};

