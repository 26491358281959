import * as React from "react";
import Link from "../../atoms/Link";
import FixedNumberField from "../FixedNumberField";
import NumberUnitField from "../NumberUnitField";
import styles from "./EditableFixedNumberField.module.scss";

export default (props: {
  value: number | null;
  formatter?: (value: number) => string;
  children?: React.ReactElement;
  onChange: (v: number | null) => void;
  placeholder?: string;
  autoFocus: boolean;
  unit: string;
  step?: number;
  onFocusChange?: (focused: boolean) => void;
  fixedValue: number | null;
}) => {
  const [customized, customize] = React.useState(false);
  if (props.value != null || customized) {
    return <div className={styles.container}>
      <NumberUnitField
        autoFocus={false}
        value={props.value != null ? props.value : null}
        onChange={props.onChange}
        placeholder="金額"
        unit="万円"
        onFocusChange={props.onFocusChange}
        step={0.1}/>
      <Link styleName="small" onClick={() => { props.onChange(null); customize(false) }}>自動算出する</Link>
    </div>
  } else {
    return <div className={styles.container}>
        <FixedNumberField
            value={props.fixedValue}
            formatter={props.formatter}/>
          <Link styleName="small" onClick={() => customize(true)}>編集する</Link>
      </div>;
  }
}
