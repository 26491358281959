import Container from "../molecules/Container";
import { setAttributes, Context, changePage, resetBlueprint, saveState } from "../Store";
import React from "react";
import { questionIndexPath, startPath } from "../path";
import Link from "../atoms/Link";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

export default () => {
  const { dispatch, state } = React.useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [resetting, setReset] = React.useState(false);
  const doReset = () => {
    setReset(true);
    dispatch(resetBlueprint());
  };
  React.useEffect(() => {
    // リセット完了
    if (resetting && state.blueprint.completed.length === 0) {
      saveState(state);
      window.history.pushState(true, "", startPath);
      dispatch(changePage(startPath, false));
    }
  }, [state, resetting]);

  return (
    <>
    <Container height="80">
      <Link
        onClick={() => setOpen(true)}>
          始めからやり直す
      </Link>
    </Container>
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">やり直しますか？</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            すべての設定を削除して、最初からやり直します。元には戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => { setOpen(false); doReset() } } color="secondary">
            削除してやり直す
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}