import React from 'react';
import firebase from './firebase';

class ErrorBoundary extends React.Component {
  state: { hasError: boolean, error: any };

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, info: any) {
    console.log("error", error);

    let description: string = String(error);//`${error}:${info.componentStack}`;
    if (error.stack) {
      description = `${description}\n${error.stack}`;
    }
    firebase.analytics().logEvent("exception", {
      fatal: true,
      description: description
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>エラーが発生しました: {String(this.state.error)}</div>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
