import * as React from "react";
import _ from "lodash";
import { Context } from "../../Store";
import AssetChart from "../../templates/AssetChart";
import { Simulator, Age } from "../../simulator";
import styles from "./AllCharts.module.scss";
import Link from "../../atoms/Link";


export default () => {
  const { state } = React.useContext(Context);
  const [plan, setPlan] = React.useState(null as null | Simulator);
  React.useEffect(() => {
    const p = new Simulator(state.blueprint);
    setPlan(p);
  }, []);
  if (plan) {
    const charts:[string, (age: Age) => number][] = [
      ["資産", age => age.asset()],
      /*["貯金", age => age.savings()],*/
      ["支出", age => age.expense()],
      ["収入", age => age.income()],
      ["投資の評価額", age => age.investmentValuation()],
      ["投資", age => age.investment()],
      ["生活費", age => age.livingCost()],
    ];
    return (
      <>
        <div className={styles.header}>
          <Link onClick={
            () => {
              window.history.back();
            }
          }>
            戻る
          </Link>
        </div>
      {charts.map(([name, getValue]) => 
        <>
          <div>{name}</div>
          <div className={styles.container}>
            <AssetChart plan={plan} getValue={getValue} name={name} key={name} />
          </div>
        </>
      )}
      </>
    );
  } else {
    return <p className="update">読み込んでいます…このままお待ち下さい</p>
  }
};
