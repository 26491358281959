import * as React from "react";
import { BlueprintKeyPath, AppState, Context, sessionStartDate } from "../Store";
import _ from "lodash";
import NumberUnitField from "../molecules/NumberUnitField";
import SelectField from "../atoms/SelectField";
import RadioField from "../molecules/RadioField";
import DateField from "../molecules/DateField";
import TextField from "../atoms/TextField";
import { formatMoneyWithUnit, formatSmallMoneyWithUnit, getAge, Simulator, yearsSince } from "../simulator";
import FixedNumberField from "../molecules/FixedNumberField";
import { version } from "process";
import Button from "../atoms/Button";
import Link from "../atoms/Link";
import EditableFixedNumberField from "../molecules/EditableFixedNumberField";

export interface FieldProps<T> {
  value: T | null | undefined;
  altValue?: T | null;
  setValue: (v: T | null) => (void);
  name?: string; // ラジオボタンの場合は name を指定して他のグループと混ざらないようにする必要がある
  onFocusChange?: (focused: boolean) => (void);
};

export const MoneyField = (props: FieldProps<number>) =>
    <NumberUnitField
      autoFocus={false}
      value={props.value != null ? props.value / 10000 : null}
      onChange={(n) => {
        if (n != null) {
          props.setValue(n * 10000);
        } else {
          props.setValue(null);
        }
      }}
      onFocusChange={props.onFocusChange}
      placeholder="金額"
      unit="万円" 
      step={0.1}
      />;

export const MoneyAltField = (props: FieldProps<number>) => 
{
  const { dispatch, state } = React.useContext(Context);
  return <EditableFixedNumberField 
    value={props.value != null ? props.value / 10000 : null}
    onChange={(n) => {
      if (n != null) {
        props.setValue(n * 10000);
      } else {
        props.setValue(null);
      }
    }}
    autoFocus={false}
    placeholder="金額"
    unit="万円" 
    step={0.1}
    formatter={formatSmallMoneyWithUnit}
    fixedValue={props.altValue ?? null}
    />;
}

export const NumberValueField = (unit: string, placeholder?: string) => (props: FieldProps<number>) =>
  <NumberUnitField
    autoFocus={false}
    value={props.value}
    onChange={(n) => props.setValue(n)}
    onFocusChange={props.onFocusChange}
    placeholder={placeholder || ""}
    unit={unit}
    />;

export const  YearsFromNowField = (props: FieldProps<Date>) =>
  <NumberUnitField
    autoFocus={false}
    value={props.value ? _.clamp(-yearsSince(props.value, sessionStartDate()), 0, 100) : null}
    onChange={v => {
      if (v != null) {
        const date = new Date(sessionStartDate());
        date.setFullYear(date.getFullYear() + v);
        props.setValue(date);
      } else {
        props.setValue(null);
      }
    }}
    onFocusChange={props.onFocusChange}
    placeholder=""
    unit="年"
    />;

export type TextValueFieldOptions = {
  maxLength?: number;
  type?: "text" | "number";
  pattern?: string;
  placeholder?: string;
}

export const TextValueField = (options?: TextValueFieldOptions) => (props: FieldProps<string>) =>
  <TextField
    autoFocus={false}
    value={props.value}
    onChange={(n) => props.setValue(n)}
    onFocusChange={props.onFocusChange}
    maxLength={options?.maxLength}
    pattern={options?.pattern}
    type={options?.type}
    inputMode={options?.pattern === "\\d*" ? "numeric" : undefined}
    placeholder={options?.placeholder}
    />;

export const SelectValueField = <K extends string | undefined, T = string>(
  master: Record<NonNullable<K>, string> | K[] | ((state: AppState) => Record<NonNullable<K>, string> | K[]),
  parser: ValueParser<T> = (value) => value as unknown as T,
  encoder: ValueEncoder<T> = (value) => value != null ? String(value) : "",
  options?: {unit?: string, allowNull?: boolean}) => function Component(props: FieldProps<T>) {
    const setValue = (v: string) => {
      if (v !== "-") {
        props.setValue(parser(v));
      } else {
        props.setValue(null);
      }
    };
    const { dispatch, state } = React.useContext(Context);
    const _master = typeof master === "function" ?
        master(state) : master;
    const theMaster = Array.isArray(_master) ?
        Object.fromEntries(_master.map(value => [value, value])) :
        master;
    return (
      <SelectField
        value={(options?.allowNull || props.value != null) ? encoder(props.value !== undefined ? props.value : null) : "-"}
        placeholder="選択してください"
        master={theMaster}
        onChange={(v) => setValue(v)}
        unit={options?.unit}
      />
    )}

type ValueParser<T> = (value: string) => (T | null);
type ValueEncoder<T> = (value: T | null) => (string);

export const SelectRadioValueField = <K extends string | undefined, T = string>(
  master: Record<NonNullable<K>, string>,
  parser: ValueParser<T> = (value) => value as unknown as T,
  encoder: ValueEncoder<T> = (value) => value != null ? String(value) : "") => 
  function Component(props: FieldProps<T>) {
    const setValue = (v: string) => {
      props.setValue(parser(v));
    };
    return (
      <>
        <RadioField
          name={props.name}
          value={encoder(props.value != null ? props.value : null)}
          master={master}
          onChange={(v) => setValue(v)}
        />
      </>
    )}

export const DateValueField = (startYear: number, endYear: number, withoutDate: boolean = false) => 
    (props: FieldProps<Date>) =>
  <DateField startYear={startYear}
    endYear={endYear}
    withoutDate={withoutDate}
    value={props.value || sessionStartDate()}
    onChange={(v) => {
      props.setValue(v);
    }}
  />

export type FieldTitleType = string | null | ((state: AppState) => string);
export type FieldDef<T> = {
  field: ValueField<T>,
  path?: BlueprintKeyPath,
  title: FieldTitleType,
  subtitle?: string,
  comment?: React.ReactChild | ((state: AppState, plan?: Simulator) => React.ReactChild),
  key: string,
  skippable?: boolean,
  fieldType?: "income" | "expense" | "config" | "investment" | "asset",
  help?: JSX.Element,
  defaultValue?: T | ((state: AppState, plan?: Simulator) => T | undefined),
  altValue?: ((state: AppState, plan?: Simulator) => T | null | undefined),
  valid?: (value: T) => boolean,
  warning?: ((state: AppState, plan?: Simulator) => string | undefined | null),
  parent?: boolean,
  child?: boolean,
  delayed?: boolean,
}

export const getFieldTitle = (field: FieldDef<any>, state: AppState) => 
  typeof field.title === "function" ? field.title(state) : field.title;
export const getFieldDefaultValue = <T extends unknown>(field: FieldDef<T>, state: AppState, plan?: Simulator) => 
  typeof field.defaultValue === "function" ? 
    (field.defaultValue as ((state: AppState, plan?: Simulator) => T | undefined))(state, plan) : field.defaultValue;

export type ValueField<T> = React.FC<FieldProps<T>>;

export const DefineField = <T extends unknown>(field: ValueField<T>, defaultOptions: Partial<FieldDef<T>> = {}) => 
  (path: BlueprintKeyPath, title: FieldTitleType, subtitle?: string, options: Partial<FieldDef<T>> = {}) => {
  return _.merge({field, path, title, subtitle, key: path.join("_")}, defaultOptions, options);
}

export const FixedNumber = (fieldType: FieldDef<any>["fieldType"], formatter?: (value: number) => string) => (
  key: string, 
  value: ((state: AppState, plan?: Simulator) => string | number | null),
  title: string,
  options: Partial<FieldDef<string | number | null>> = {}): FieldDef<string | number | null> => _.merge({
key, title, field: (props: FieldProps<number>) => 
  <FixedNumberField value={props.value != null ? props.value : null} formatter={formatter} />, 
defaultValue: value,
fieldType
}, options);

export const FixedMoney = (fieldType: "expense" | "income") =>
  FixedNumber(fieldType, formatSmallMoneyWithUnit);
export const FixedExpense = FixedMoney("expense");
export const FixedIncome = FixedMoney("income");
export const InputNumber = (unit: string) => DefineField(NumberValueField(unit), {fieldType: "config", delayed: true});
export const InputMoney = DefineField(MoneyField, {fieldType: "config", delayed: true});
export const InputIncome = DefineField(MoneyField, {fieldType: "income", delayed: true});
export const InputAltIncome = DefineField(MoneyAltField, {fieldType: "income", delayed: true});
export const InputExpense = DefineField(MoneyField, {fieldType: "expense", delayed: true});
export const InputDays = DefineField(NumberValueField("日"), {fieldType: "config", delayed: true});
export const InputMonths = DefineField(NumberValueField("ヶ月"), {fieldType: "config", delayed: true});
export const InputYears = DefineField(NumberValueField("年"), {fieldType: "config", delayed: true});
export const InputYearsFromNow = DefineField(YearsFromNowField, {fieldType: "config", delayed: true});
export const InputYear = DefineField(NumberValueField("年"), {fieldType: "config", delayed: true});
export const InputAge = DefineField(NumberValueField("歳", "年齢"), {fieldType: "config", delayed: true});
export const InputText = DefineField(TextValueField(), {fieldType: "config", delayed: true});
export const InputTextWithOptions = (options: TextValueFieldOptions) => DefineField(TextValueField(options), {fieldType: "config", delayed: true});
export const Select = <K extends string | undefined, T = string>(
  master: Record<NonNullable<K>, string> | K[] | ((state: AppState) => Record<NonNullable<K>, string> | K[]),
  parser?: ValueParser<T>, encoder?: ValueEncoder<T>) => 
  DefineField(SelectValueField(master, parser, encoder), {fieldType: "config", delayed: false});
export const SelectRadio = <K extends string | undefined, T = string>(
  master: Record<NonNullable<K>, string>,
  parser?: ValueParser<T>, encoder?: ValueEncoder<T>) => 
  DefineField(SelectRadioValueField(master, parser, encoder), {fieldType: "config"});
export const SelectObjectExistence = (master: {"yes": string, "no": string} = {"yes": "あり", "no": "なし"}) => 
  DefineField(SelectValueField(master, 
    (value: string) => value === "yes" ? {} : null,
    (value: object | null) => value != null ? "yes" : "no",
    {allowNull: true}
    ), {fieldType: "config"});

export const SelectExistenceRadio = 
  DefineField(SelectRadioValueField({"yes": "あり", "no": "なし"},
    (value: string) => value === "yes" ? true : value === "no" ? false : null,
    (value: boolean | null) => value != null ? (value ? "yes" : "no") : ""
    ), {fieldType: "config"});
export const SelectExistence = 
  DefineField(SelectValueField({"yes": "あり", "no": "なし"},
    (value: string) => value === "yes" ? true : value === "no" ? false : null,
    (value: boolean | null) => value != null ? (value ? "yes" : "no") : ""
    ), {fieldType: "config"});

export const SelectYesNoRadio = 
  DefineField(SelectRadioValueField({"yes": "はい", "no": "いいえ"},
    (value: string) => value === "yes" ? true : value === "no" ? false : null,
    (value: boolean | null) => value != null ? (value ? "yes" : "no") : ""
    ), {fieldType: "config"});
export const SelectYesNo = 
  DefineField(SelectValueField({"yes": "はい", "no": "いいえ"},
    (value: string) => value === "yes" ? true : value === "no" ? false : null,
    (value: boolean | null) => value != null ? (value ? "yes" : "no") : ""
    ), {fieldType: "config"});

export const SelectBoolean = (master: {"yes": string, "no": string}) => 
DefineField(SelectValueField(master,
  (value: string) => value === "yes" ? true : value === "no" ? false : null,
  (value: boolean | null) => (value != null ? (value ? "yes" : "no") : "-"),
  ), {fieldType: "config"});

type SelectNumberOptions = {
  min?: number;
  max: number;
  unit?: string;
}
export const SelectNumber = (options: SelectNumberOptions) => {
  const min = options.min ?? 1;
  const max = options.max;
  const master = _.fromPairs(Array.from(Array(max - min + 1), (_, i) => [String(i + min), String(i + min)]))
  return DefineField(SelectValueField<string, number>(master, 
    (value: string) => parseInt(value),
    (value: number | null) => String(value || 0),
    {unit: options.unit}), {fieldType: "config"});
}
export const SelectYearFromNow = (state: AppState, years: number) =>
  SelectNumber({
    min: (state.blueprint.createdDate || sessionStartDate()).getFullYear(),
    max: sessionStartDate().getFullYear() + years,
    unit: "年"});

export const SelectYearMonth = (startYear: number, endYear: number) =>
  DefineField(DateValueField(startYear, endYear, true), {fieldType: "config"});
export const SelectDate = (startYear: number, endYear: number) =>
  DefineField(DateValueField(startYear, endYear, false), {fieldType: "config"});
